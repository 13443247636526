/* eslint-disable react-hooks/exhaustive-deps */
import TimeTrackerFormik from "./TimeTrackerFormik";
import CustomHeader from "../../shared/custom/CustomHeader";
import BoxContainer from "../../shared/BoxContainer";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import moment from "moment";
import { useEffect } from "react";
import { useLazyCheckTimeTrackerSubmittedQuery } from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { Alert } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { useParams } from "react-router";
import CustomTimeTrackerAlert from "./CustomTimeTrackerAlert";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../utils/enums/user-role.enum";

const TimeTracker = () => {
  const [timeTrackerSubmitted, result] =
    useLazyCheckTimeTrackerSubmittedQuery();
  const user = useSelector(selectLoggedUser);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const params = useParams();

  useEffect(() => {
    if (user) {
      if (params.id && params.date) {
        timeTrackerSubmitted({
          date: params.date,
          userId: params.id,
        })
          .unwrap()
          .catch(console.log);
      } else {
        timeTrackerSubmitted({
          date: moment().format("YYYY-MM-DD"),
          userId: user?.userId,
        })
          .unwrap()
          .catch(console.log);
      }
    }
  }, []);

  const TTResult: any = result?.data;

  return (
    <BoxContainer>
      <CustomHeader
        title={t("TIME_TRACKER.TITLE")}
        subtitle={t("TIME_TRACKER.SUBTITLE")}
      >
        {params?.id && (
          <CustomNavigationButton
            customStyle={{
              backgroundColor: BLUE_COLOR_ACCENT,
              width: "10%",
              display: "flex",
              gap: 1,
              alignSelf: "center",
              fontSize: "12px",
              ":hover": {
                bgcolor: BLUE_HOVER_COLOR_ACCENT,
                color: "white",
              },
            }}
            navigateTo={"/manage-time-trackers"}
          >
            <KeyboardArrowLeftIcon /> {isMobile ? "" : t("GO_BACK")}
          </CustomNavigationButton>
        )}
      </CustomHeader>
      {!result?.isUninitialized && !result?.isFetching ? (
        TTResult?.onHoliday ? (
          <>
            <TimeTrackerFormik userId={params?.id} userDate={params?.date} />
            <CustomTimeTrackerAlert
              title={t("TIME_TRACKER.USER.ALERT.HOLIDAY")}
              description={t("TIME_TRACKER.USER.ALERT.HOLIDAY_DESCRIPTION")}
              openModal={true}
            />
          </>
        ) : TTResult?.onWeekend ? (
          <>
            <TimeTrackerFormik userId={params?.id} userDate={params?.date} />
            <CustomTimeTrackerAlert
              title={t("TIME_TRACKER.USER.ALERT.WEEKEND")}
              description={t("TIME_TRACKER.USER.ALERT.WEEKEND_DESCRIPTION")}
              openModal={true}
            />
          </>
        ) : TTResult?.completed ? (
          user.role === UserRole.EMPLOYEE ? (
            <Alert
              severity="info"
              sx={{
                width: isMobile || isTablet ? "100%" : "60%",
                borderRadius: "8px",
                marginTop: "2vh",
                fontSize: "16px",
              }}
            >
              {t("TIME_TRACKER.USER.SUBMITTED_TIME_TRACKER")}
              <br />
              {t("TIME_TRACKER.USER.SUBMITTED_TIME_TRACKER_SECOND_LINE")}
            </Alert>
          ) : (
            <Alert
              severity="info"
              sx={{
                width: isMobile || isTablet ? "100%" : "60%",
                borderRadius: "8px",
                marginTop: "2vh",
                fontSize: "16px",
              }}
            >
              {t("TIME_TRACKER.ADMIN.SUBMITTED_TIME_TRACKER")}
            </Alert>
          )
        ) : TTResult?.onLeave ? (
          user?.role === UserRole.EMPLOYEE ? (
            <Alert
              severity="info"
              sx={{
                width: isMobile || isTablet ? "100%" : "60%",
                borderRadius: "8px",
                marginTop: "2vh",
                fontSize: "16px",
              }}
            >
              {t("TIME_TRACKER.USER.SUBMITTED_LEAVE")}
            </Alert>
          ) : (
            <Alert
              severity="info"
              sx={{
                width: isMobile || isTablet ? "100%" : "60%",
                borderRadius: "8px",
                marginTop: "2vh",
                fontSize: "16px",
              }}
            >
              {t("TIME_TRACKER.ADMIN.SUBMITTED_LEAVE")}
            </Alert>
          )
        ) : (
          <>
            <TimeTrackerFormik userId={params?.id} userDate={params?.date} />
          </>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default TimeTracker;
