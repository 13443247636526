import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CustomButton from "../custom/CustomButton";
import { Box, DialogContent, DialogContentText, useTheme } from "@mui/material";
import AlertActionButton from "./AlertActionButton";
import { tokens } from "../../core/AppStyles";
import { useTranslation } from "react-i18next";

export const AlertDialog = ({
  buttonTitle,
  openModal,
  setOpenModal,
  handleSubmitDialog,
  children,
  width,
  handleShowAlertDialog,
  color,
  colorHover,
  dialogTitle,
  disabled,
  mt,
  ButtonComponent = undefined,
  isConfirm = true,
  hasAction = true,

}: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleClose = () => {
    setOpenModal(false);
  };
  const { t } = useTranslation();
  return (
    <>
      {ButtonComponent === undefined ? (
        <CustomButton
          type="submit"
          onClick={handleShowAlertDialog}
          width={width || "100%"}
          title={buttonTitle}
          color={color}
          colorHover={colorHover}
          disabled={disabled}
          mt={mt}
        />
      ) : (
        <ButtonComponent />
      )}
      <Dialog
        fullWidth
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ zIndex: 2000 }}
      >
        {isConfirm ? (
          <Box sx={{ backgroundColor: colors.component }}>
            <DialogTitle
              sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="alert-dialog-title"
            >
              {t(dialogTitle) || t("CONFIRMATION")}
            </DialogTitle>
            <DialogContent
              sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 0 }}
            >
              <DialogContentText id="alert-dialog-description">
                {children}
              </DialogContentText>
            </DialogContent>
            {hasAction && (
              <DialogActions
                sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}
              >
                <AlertActionButton
                  title={t("CANCEL")}
                  variant="outlined"
                  onClick={handleClose}
                />
                <AlertActionButton
                  title={t("CONFIRM")}
                  variant="contained"
                  onClick={handleSubmitDialog}
                />
              </DialogActions>
            )}
          </Box>
        ) : (
          children
        )}
      </Dialog>
    </>
  );
};

export default AlertDialog;
