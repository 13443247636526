import { useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomTable from "../../shared/custom/CustomTable";
import {
  Box,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import CustomDatePicker from "../../shared/CustomDatePicker";
import moment from "moment";
import {
  useLazyGetHolidaysQuery,
  useLazyGetMultipleTimeTrackersByIntervalQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import ViewTimeTrackersTableRow from "./ViewTimeTrackersTableRow";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomViewTimeTrackerTableMobile from "./CustomViewTimeTrackerTableMobile";
import SearchBar from "../../shared/custom/SearchBar";
import CustomSelector from "../../shared/custom/CustomSelector";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { CSVLink } from "react-csv";
import { tokens } from "../../core/AppStyles";
import NoDataComponent from "../../shared/NoDataComponent";
import ManageTimeTrackersModal from "./ManageTimeTrackerModal";
import { TimeTrackerStructure } from "../../data/models/time-tracker-structure.interface";
import { useTranslation } from "react-i18next";

const ViewTimeTrackers = () => {
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const [getHolidays, holidayresult] = useLazyGetHolidaysQuery();
  const { t } = useTranslation();

  const tableTitles: string[] = [
    `${t("FULL_NAME")}`,
    `${t("DATE")}`,
    `${t("START_TIME")}`,
    `${t("END_TIME")}`,
    `${t("MY_WORK_TIME.TABLE_TITLES.WORKED_HOURS")}`,
    `${t("LEAVES")}`,
    `${t("OVERTIME")}`,
    `${t("EDIT")}`,
    `${t("DELETE")}`,
  ];

  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [limit, setLimit] = useState<any>(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);
  const [error, setError] = useState<{ message: string; hasError: boolean }>({
    hasError: false,
    message: "",
  });

  const openModalManageTimeTracker = (employee: any, date: any) => {
    setIsModalOpen(true);
    setSelectedDate(date);
    setSelectedEmployee(employee);
  };

  const [selectedTimeTracker, setSelectedTimeTracker] = useState<
    TimeTrackerStructure | undefined | null
  >(undefined);

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const [getMultipleTimeTrackers, result] =
    useLazyGetMultipleTimeTrackersByIntervalQuery();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (result?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
  }, [result?.data]);

  useEffect(() => {
    const startDateMoment = moment(selectedStartDate);
    const endDateMoment = moment(selectedEndDate);
    const startDate = moment(selectedStartDate).format("YYYY-MM-DD");
    const endDate = moment(selectedEndDate).format("YYYY-MM-DD");
    const endDateSmaller = endDateMoment.isBefore(startDateMoment);

    getHolidays({ startDate, endDate });

    if (!endDateSmaller && startDateMoment?.isValid() && endDateMoment?.isValid()) {
      getMultipleTimeTrackers({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        currentPage: page,
        limitTo: limit,
        q,
      });
      setError({
        hasError: false,
        message: "",
      });
    } else {
      setError({
        hasError: true,
        message: t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate, q, limit, page]);

  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (result?.data?.data) {
      result?.data?.data?.map((info: any, index: number) => {

        return data.push({
          user: info?.user?.firstName + " " + info?.user?.lastName,
          date: info?.date,
          startTime: moment(info?.firstStartTime)?.format("HH:mm"),
          endDate: moment(info?.lastStartTime)?.format("HH:mm"),
          workedHours: info?.work_time / 60,
        });
      });
    }
    return data;
  }, [result?.data?.data]);

  const headers = [
    { label: `${t("FULL_NAME")}`, key: "user" },
    { label: `${t("DATE")}`, key: "date" },
    { label: `${t("START_TIME")}`, key: "startTime" },
    { label: `${t("END_TIME")}`, key: "endDate" },
    { label: `${t("WORKED_HOURS")}`, key: "workedHours" },
  ];

  const timeTrackersBodyRenderer = useMemo(() => {
    const timeTrackersDataRenderer: JSX.Element[] = [];
    result?.data?.data?.map((row: any, index: number) => {
      return timeTrackersDataRenderer.push(
        <ViewTimeTrackersTableRow
          openModalManageTimeTracker={openModalManageTimeTracker}
          key={index}
          employee={row?.user}
          timeTracker={row}
        />
      );
    });
    return timeTrackersDataRenderer;
  }, [result?.data?.data]);

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.TITLE")}
          subtitle={t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",
              height: "200px",
              marginTop: "1vh",
            }}
          >
            <SearchBar setPage={setPage} setSearchEvent={setQ} />
            <CustomDatePicker
              views={["day"]}
              openTo="day"
              label={t("START_DATE")}
              value={selectedStartDate}
              onChange={(value: any) =>
                setSelectedStartDate(value ? value.format("YYYY-MM-DD") : '')
              }
              fullWidth={true}
            />
            <CustomDatePicker
              views={["day"]}
              openTo="day"
              label={t("END_DATE")}
              value={selectedEndDate}
              onChange={(value) =>
                setSelectedEndDate(value ? value.format("YYYY-MM-DD") : '')
              }
              fullWidth={true}
              touched={true}
              error={error?.message}
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <>
          <CustomHeader
            title={t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.TITLE")}
            subtitle={t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.SUBTITLE")}
          />
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            gap={isMobile || isTablet ? 2 : 5}
            mt={isMobile || isTablet ? 1 : 4}
            mb={isMobile || isTablet ? 2 : 0}
          >
            <Grid item xs={isMobile || isTablet ? 10 : 5.7}>
              <CustomDatePicker
                views={["day"]}
                openTo="day"
                label={t("START_DATE")}
                value={selectedStartDate}
                onChange={(value) =>
                  setSelectedStartDate(value ? value.format("YYYY-MM-DD") : '')
                }
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={isMobile || isTablet ? 10 : 5.85}>
              <CustomDatePicker
                views={["day"]}
                openTo="day"
                label={t("END_DATE")}
                value={selectedEndDate}
                onChange={(value) =>
                  setSelectedEndDate(value ? value.format("YYYY-MM-DD") : '')
                }
                fullWidth={true}
                touched={true}
                error={error?.message}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <SearchBar setPage={setPage} setSearchEvent={setQ} />
            </Box>
            <Grid item xs={isMobile || isTablet ? 10 : 5.8}></Grid>
          </Grid>
        </>
      )}

      {result?.data?.data?.length > 0 ? (
        isMobile || isTablet ? (
          <CustomViewTimeTrackerTableMobile
            openModalManageTimeTracker={openModalManageTimeTracker}
            page={page}
            handleChangePage={handleChangePage}
            column1Name={t("DETAILS")}
            column2Name={t("SIDENAV.TIME_TRACKER")}
            isPaginated={result?.data?.totalRecords > 10 ? true : false}
            mt={2}
            data={result?.data}
            rowsPerPage={limit}
            handleChangeRowsPerPage={handleRowPerPageChange}
            count={result?.data?.totalRecords}
          />
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  gap: '10px'
                }}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      background: color.weekend,
                      marginRight: '5px'
                    }}
                  />
                  <small>{t("MY_WORK_TIME.WEEKEND")}</small>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      background: color.holiday,
                      marginRight: '5px'
                    }}
                  ></Box>
                  <small>{t("MY_WORK_TIME.HOLIDAY")}</small>
                </Box>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      background: color.overWorked,
                      marginRight: '5px'
                    }}
                  ></Box>
                  <small>{t("MY_WORK_TIME.TABLE_TITLES.OVERTIME_WORKED")}</small>
                </Box>
              </Box>
              <CSVLink
                data={dataValues}
                headers={headers}
                filename={t("MY_WORK_TIME.COMPLETED_TIME_TRACKERS")}
                style={{
                  background: "#43b685",
                  color: "white",
                  fontWeight: "500",
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignSelf: "end",
                  alignItems: "center",
                  textDecoration: "none",
                  padding: "0.4% 0.8%",
                  borderRadius: "5px",
                  float: "right",
                }}
              >
                {t("EXPORT_TO_EXCEL")}
              </CSVLink>
            </Box>
            <CustomTable
              page={page}
              handleChangePage={handleChangePage}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              tableBodyRenderer={timeTrackersBodyRenderer}
              tableTitles={tableTitles}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              mt={1}
              count={result?.data?.totalRecords}
              style={{
                borderBottomLeftRadius:
                  result?.data?.totalRecords > 9 ? "0px" : "10px",
                borderBottomRightRadius:
                  result?.data?.totalRecords > 9 ? "0px" : "10px",
              }}
            />
          </>
        )
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: isMobile ? "35vh" : "50vh",
          }}
        >
          <NoDataComponent
            title={t(
              "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.NO_TIME_TRACKER_COMPLETED"
            )}
          />
        </Box>
      )}
      <ManageTimeTrackersModal
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
          setSelectedEmployee(null);
          setSelectedDate(null);
          setSelectedTimeTracker(null);
        }}
        selectedUser={selectedEmployee}
        selectedDate={selectedDate}
        selectedTimeTracker={selectedTimeTracker}
        setSelectedTimeTracker={setSelectedTimeTracker}
      />
    </BoxContainer>
  );
};

export default ViewTimeTrackers;
