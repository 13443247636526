import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface CustomSnackBarProps {
  isError: boolean;
  snackBarOpen: boolean;
  setSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  snackBarMessage: string;
}

const CustomSnackBar = ({
  isError,
  snackBarOpen,
  setSnackBarOpen,
  snackBarMessage,
}: CustomSnackBarProps) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackBarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        sx={{ width: "100%" }}
        severity={isError ? "error" : "success"}
      >
        {snackBarMessage}
      </Alert>
    </Snackbar>
  );
};
export default CustomSnackBar;
