import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import { BLUE_COLOR_ACCENT } from "../../../../utils/constants/colors";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export interface Idata {
  userId: string;
  fullName: string;
  incompleteDates: string[];
}

interface IUncompletedTT {
  data: Idata[];
  nrDays: number;
}

const UsersNotCompletedTimeTracker = ({ data, nrDays }: IUncompletedTT) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const firstName = data?.[0]?.fullName;

  const incompleteTTMessage = () => {
    let message = "";
    if (data?.length === 0) {
      message = t("DASHBOARD.ADMIN.NO_UNCOMPLETED_TIME_TRACKERS");
    } else if (data?.length === 1) {
      message = t("DASHBOARD.ADMIN.EMPLOYEE_UNCOMPLETED_TIME_TRACKERS", {
        firstName: firstName,
      });
    } else if (data?.length > 1) {
      message = t(
        "DASHBOARD.ADMIN.DIFFERENT_EMPLOYEES_UNCOMPLETED_TIME_TRACKERS",
        { firstName: firstName, data: data?.length - 1 }
      );
    } else {
      message = t("DASHBOARD.ADMIN.UNREADBLE_DATA");
    }
    return message;
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        gap: 2,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "2vh 2vh",
        background: colors.component,
        borderRadius: "8px",
      }}
    >
      <p
        style={{
          color: "orange",
          border: "1px solid orange",
          padding: 4,
          borderRadius: 8,
        }}
      >
        {t("DASHBOARD.ADMIN.REMINDER")}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: "0.9rem",
          color: colors.grey[300],
        }}
      >
        {incompleteTTMessage()}
      </p>
      {data?.length > 0 && (
        <button
          style={{
            position: "relative",
            alignSelf: "flex-end",
            border: "none",
            background: "none",
            padding: isMobile ? "2% 4%" : "1%",
            backgroundColor: BLUE_COLOR_ACCENT,
            color: colors.black,
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/uncomplete-time-trackers")}
        >
          {t("DASHBOARD.ADMIN.CHECK_THE_LIST")}
        </button>
      )}
    </Box>
  );
};

export default UsersNotCompletedTimeTracker;
