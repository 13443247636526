export enum OutOfOffice {
  ILLNESS = "Illness",
  VACATION = "Vacation",
  MATERNITY_PATERNITY = "Maternity/Paternity Leave",
  MARRIAGE = "Marriage",
  MISSFORTUNE = "Missfortune",
  PAID_ABSENCES = "Paid Absences",
  // HALF_DAY = "Half Day",
  COMPENSATION = "Compensation"
}

export enum LeaveRequestStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}
