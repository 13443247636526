import {
    PickersDay,
    pickersDayClasses,
    PickersDayProps,
} from "@mui/x-date-pickers";
import { RED_COLOR_ACCENT } from "../../utils/constants/colors";
import Badge from '@mui/material/Badge';
import { Dayjs } from "dayjs";

const CustomCalendarPicker = (
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const isSelected =
        !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
    return (
        <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={isSelected ? '🌚' : undefined}
        >
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} sx={{
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: RED_COLOR_ACCENT,
                },
            }} />

        </Badge>
    );
};


export default CustomCalendarPicker;