import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import moment from "moment";
import { User } from "../../data/models/user.interface";
import { Link } from "react-router-dom";
import { tokens } from "../../core/AppStyles";
import { useTheme } from "@mui/material";
import {
  RED_COLOR_ACCENT,
  GREEN_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { memo } from "react";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import { useTranslation } from "react-i18next";

interface CustomTableRowProps {
  row: User;
}

const EmployeeTableRow = ({ row }: CustomTableRowProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const colors = tokens(theme.palette.mode);
  return (
    <TableRow
      key={row.userId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.firstName}
      </TableCell>
      <TableCell align="left">{row.lastName}</TableCell>
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="left">
        {row?.totalVacations.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell align="left">
        {(row?.totalOvertimes / 60).toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell align="left">
        {t(`ROLES.${row?.role?.toUpperCase()}`)}
      </TableCell>
      <TableCell align="left">
        {row.hiringDate ? moment(row.hiringDate).format("DD MMM YYYY") : "-"}
      </TableCell>
      <TableCell align="left">
        {row.endOfContract
          ? moment(row.endOfContract).format("DD MMM YYYY")
          : "-"}
      </TableCell>
      <TableCell
        sx={{
          color:
            row.status === "ACTIVE"
              ? `${GREEN_COLOR_ACCENT}`
              : `${RED_COLOR_ACCENT}`,
          fontWeight: "600",
          letterSpacing: 0.5,
        }}
        align="left"
      >
        {t(`STATUSES.${row.status}`)}
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="left">
        {
          <Link
            style={{ color: colors.primary[100] }}
            to={`/edit-user/${row.userId}`}
          >
            <IconEdit fill={colors.invert} />
          </Link>
        }
      </TableCell>
      <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="left">
        {
          <Link
            style={{ color: colors.primary[100] }}
            to={`/view-user/${row.userId}`}
          >
            <RemoveRedEyeOutlinedIcon />
          </Link>
        }
      </TableCell>
    </TableRow>
  );
};

export default memo(EmployeeTableRow);
