import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { useState } from "react";
import { Box } from "@mui/material";
import "./styles.css";
import useIsMobile from "../../shared/hooks/useIsMobile";

interface Props {
  pdfUrl: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfViewer = ({ pdfUrl }: Props) => {
  const [numPages, setNumPages] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const isMobile = useIsMobile();
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };
  const previousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <>
      <Document
        className="doc-container"
        file={{ url: pdfUrl }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          className="page-container"
          width={isMobile ? 250 : undefined}
          scale={1}
        />
        {numPages > 1 && (
          <Box className="hover-overlay">
            <button
              className="prev-button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              style={{ opacity: pageNumber <= 1 ? "0.1" : "1" }}
            >
              {"<"}
            </button>
            <p style={{ color: "black" }}>
              {pageNumber} of {numPages}
            </p>
            <button
              className="next-button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              style={{ opacity: pageNumber >= numPages ? "0.1" : "1" }}
            >
              {">"}
            </button>
          </Box>
        )}
      </Document>
    </>
  );
};

export default PdfViewer;
