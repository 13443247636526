import { useParams } from "react-router";
import { useLazyGetAllFilesByUserIdQuery } from "../../data/endpoints/app.endpoints";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import File from "./File";
import { Box, useTheme } from "@mui/material";
import NoDataComponent from "../../shared/NoDataComponent";
import { FormatListBulleted, GridView, Search } from "@mui/icons-material";
import CustomFileManagerHeader from "./CustomFileManagerHeader";
import CustomFMActionButton from "./CustomFMActionButton";
import { tokens } from "../../core/AppStyles";
import FileListVew from "./FileListVew";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomFileUploadDialog from "./CustomFileUploadDialogModal";

const FilesContainer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const { type, userId } = useParams();
  const user = useSelector(selectLoggedUser);
  const [getFilebyUserId, filesByUserIdResult] =
    useLazyGetAllFilesByUserIdQuery();
  const [viewFileMode, setFileViewMode] = useState<string>();
  const [fileSearch, setFileSearch] = useState<string | undefined>("");

  useEffect(() => {
    if (user?.role === "Admin") {
      getFilebyUserId({
        userId: userId,
        currentPage: 1,
        limitTo: 100,
        q: fileSearch,
        type: type === "ALL" ? "" : type,
      });
    } else if (user?.role === "Employee") {
      getFilebyUserId({
        userId: user?.userId,
        currentPage: 1,
        limitTo: 100,
        q: fileSearch,
        type: type === "ALL" ? "" : type,
      });
    }
  }, [type, fileSearch, userId]);

  const handleFileViewMode = () => {
    const viewMode = localStorage.getItem("view-mode");
    if (viewMode && viewMode === "Grid") {
      setFileViewMode("List");
      localStorage?.setItem("view-mode", "List");
    } else if (viewMode && viewMode === "List") {
      setFileViewMode("Grid");
      localStorage?.setItem("view-mode", "Grid");
    } else {
      localStorage?.setItem("view-mode", "Grid");
    }
  };

  const handleFileChange = (e: any) => {
    setFileSearch(e?.target?.value);
  };

  const AllFile = filesByUserIdResult && filesByUserIdResult?.data?.data;

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomFileManagerHeader
        children={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  width: isMobile ? "130px" : "200px",
                  height: "30px",
                  border: "0.5px solid lightgray",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  gap: 1,
                  padding: "5px",
                }}
              >
                <input
                  type="text"
                  id="fileSearch"
                  style={{
                    border: "none",
                    outline: 0,
                    background: "none",
                    height: "100%",
                    color: colors.invert,
                  }}
                  onChange={handleFileChange}
                  value={fileSearch}
                  placeholder="search ..."
                />
                {!isMobile && (
                  <Search fontSize="small" sx={{ color: colors.invert }} />
                )}
              </Box>
              <CustomFMActionButton
                title={viewFileMode ? viewFileMode : "Grid"}
                icon={
                  viewFileMode === "Grid" ? (
                    <GridView fontSize="small" sx={{ color: colors.invert }} />
                  ) : viewFileMode === "List" ? (
                    <FormatListBulleted
                      fontSize="small"
                      sx={{ color: colors.invert }}
                    />
                  ) : (
                    <GridView fontSize="small" sx={{ color: colors.invert }} />
                  )
                }
                onAction={() => handleFileViewMode()}
              />
            </Box>
            <CustomFileUploadDialog
              type={type && type}
              user={userId && userId}
            />
          </Box>
        }
      />

      <Box
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: "70vh",
          display: "flex",
          flexDirection: "row",
          gap: 1,
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "2vh",
          overflow: "scroll",
        }}
      >
        {AllFile?.length > 0 ? (
          AllFile?.map((file: any, index: number) =>
            viewFileMode === "List" ? (
              <FileListVew file={file} key={index} />
            ) : (
              <File file={file} key={index} />
            )
          )
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDataComponent title="No files to show!" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FilesContainer;
