import { createSlice, createSelector } from "@reduxjs/toolkit";
import { HomeState } from "../models/states/home-state.model";
import { RootState } from "./store";

const initialState: HomeState = {};

const selectSelf = (state: RootState) => state.home;

export const getHomeData = createSelector(selectSelf, (state) => state);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetHomeState: () => initialState,
  },
  extraReducers: (builder) => builder,
});

export const { resetHomeState } = homeSlice.actions;
