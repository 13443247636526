import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { User } from "../models/user.interface";
import { appEndpoints } from "../endpoints/app.endpoints";
import { PaginationState } from "../models/states/pagination-state.interface";
import { UserRole } from "../../utils/enums/user-role.enum";

const initialState: any = {
  data: [],
  isLoading: true,
  error: undefined,
};

const selectSelf: any = (state: RootState) => state.employee;

export const selectEmployeeSliceState = createSelector(
  selectSelf,
  (state) => state as PaginationState<User>
);
export const selectAllUsers = createSelector(
  selectEmployeeSliceState,
  (state) => state.data
);

export const selectAllEmployees = createSelector(selectAllUsers, (users) =>
  users.filter((user) => user.role === UserRole.EMPLOYEE)
);

export const selectEmployeeById = (id: string | undefined) =>
  createSelector(
    selectAllUsers,
    (state) => state.find((employee) => employee.userId === id) as User
  );

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    resetEmployeeState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getAllUsers.matchFulfilled,
        (state, { payload }) => {
          state.error = undefined;
          state.isLoading = false;
          state.data = payload;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getAllUsers.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload, isLoading: true };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.createUser.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload };
        }
      ),
});

export const { resetEmployeeState } = employeeSlice.actions;
