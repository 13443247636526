import { memo } from "react";
import Sidebar from "../core/sidebar/Sidebar";
import LoadingSpinner from "./LoadingSpinner";
import { Navigate } from "react-router";

interface ProtectedRouteProps {
  isUserLoggedIn: boolean;
  loadingSpinnerCondition: boolean;
  Component: any;
}

const ProtectedRoute = ({
  isUserLoggedIn,
  loadingSpinnerCondition,
  Component,
}: ProtectedRouteProps) => {
  return isUserLoggedIn ? (
    <Sidebar>
      {loadingSpinnerCondition ? <LoadingSpinner /> : <Component />}
    </Sidebar>
  ) : (
    <Navigate to="/login" />
  );
};

export default memo(ProtectedRoute);
