import { User } from "../../data/models/user.interface";
import { Box, TableCell, TableRow, useTheme } from "@mui/material";
import moment from "moment";
import { tokens } from "../../core/AppStyles";
import { Visibility } from "@mui/icons-material";

interface ViewTimeTrackersTableRowProps {
  employee: User;
  timeTracker: any;
  openModalManageTimeTracker: any;
}

const ViewTimeTrackersTableRowUser = ({
  employee,
  timeTracker,
  openModalManageTimeTracker,
}: ViewTimeTrackersTableRowProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <TableRow
      key={employee.userId}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {moment(timeTracker.date).format("dddd DD MMM YYYY")}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(timeTracker.firstStartTime).format("HH:mm")}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(timeTracker.lastStartTime).format("HH:mm")}
      </TableCell>
      <TableCell component="th" scope="row">
        {(timeTracker?.work_time / 60).toFixed(2).replace(/(\.0+|0+)$/, "") ||
          0}
      </TableCell>
      <TableCell component="th" scope="row">
        {timeTracker?.leaveHours?.toFixed(2).replace(/(\.0+|0+)$/, "") || 0}
      </TableCell>
      <TableCell component="th" scope="row">
        {timeTracker?.overtime?.toFixed(2).replace(/(\.0+|0+)$/, "") || 0}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ alignItems: "center", paddingTop: 0, paddingBottom: 0 }}
      >
        <Visibility
          fill={colors.invert}
          onClick={() =>
            openModalManageTimeTracker(employee, timeTracker?.date)
          }
        />

        <Box sx={{ width: "40%" }} />
      </TableCell>
    </TableRow>
  );
};

export default ViewTimeTrackersTableRowUser;
