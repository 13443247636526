import { QuestionMark } from "@mui/icons-material";
import { Box, colors, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useNavigate } from "react-router";
import { tokens } from "../../core/AppStyles";
import { PROGRESSBAR_ORANGE } from "../../utils/constants/colors";
import { useTranslation } from "react-i18next";

interface ITTAlert {
  title: string;
  description: string;
  openModal: boolean;
}

const CustomTimeTrackerAlert = ({
  title,
  description,
  openModal,
}: ITTAlert) => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(openModal);
  const navigate = useNavigate();
  const theme = useTheme();
  const customColors = tokens(theme.palette.mode);
  const handleClose = () => {
    setOpen(false);
    setModal(false);
  };
  const { t } = useTranslation();

  const handleHome = () => {
    navigate("/");
  };

  return (
    <Box>
      <Dialog
        open={modal || open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { background: customColors.component } }}
      >
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                marginBottom: "1vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: PROGRESSBAR_ORANGE,
              }}
            >
              <QuestionMark
                fontSize="large"
                sx={{ color: customColors.invert }}
              />
            </Box>
            <h2
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {title}
            </h2>
            <br />
            <p
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              {description}
            </p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleHome}
            style={{
              color: colors.blue[600],
            }}
          >
            {t("TIME_TRACKER.USER.ALERT.HOME")}
          </Button>
          <Button
            onClick={handleClose}
            style={{
              background: colors.blue[600],
              color: "white",
            }}
          >
            {t("TIME_TRACKER.USER.ALERT.PROCEED")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default CustomTimeTrackerAlert;
