import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import TimeIntervalFormik from "./TimeIntervalFormik";
import { TimeTrackerType } from "../../utils/enums/timetracker-type.enum";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";

const ManageFieldArray = ({
  colors,
  timeTrackerArray,
  title,
  refetch,
  renderMenuItems,
  type,
  date,
  userId,
  selectedTimeTracker,
}: any) => {
  const [additionalTimeTracker, setAdditionalTimeTracker] = useState<any>([]);
  const [additionalOptionalBreak, setAdditionalOptionalBreak] = useState<any>(
    []
  );

  return (
    <Grid container alignItems="center">
      <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
        {title}
      </Typography>
      <CustomButton
        margin="0px 0px 15px 10px"
        width="1%"
        title="+"
        color={BLUE_COLOR_ACCENT}
        colorHover={BLUE_HOVER_COLOR_ACCENT}
        onClick={() => {
          if (type === TimeTrackerType.WORK) {
            setAdditionalTimeTracker([
              ...additionalTimeTracker,
              {
                startTime: "",
                endTime: "",
                description: "",
                vehicleId: "",
                type: TimeTrackerType.WORK,
                date: timeTrackerArray[0].date,
                userId: timeTrackerArray[0].userId,
              },
            ]);
          } else {
            setAdditionalOptionalBreak([
              ...additionalOptionalBreak,
              {
                startTime: "",
                endTime: "",
                type: TimeTrackerType.BREAK_TIME,
                date: date,
                userId: userId,
              },
            ]);
          }
        }}
      />
      {timeTrackerArray?.map((timeTracker: any, index: number) => (
        <TimeIntervalFormik
          isOnlyInterval={!(timeTracker.type === TimeTrackerType.WORK)}
          key={index}
          colors={colors}
          lunchTimeState={timeTracker}
          hasTitle={false}
          index={index}
          renderMenuItems={renderMenuItems}
          refetch={refetch}
          selectedTimeTracker={selectedTimeTracker}
        />
      ))}
      {additionalTimeTracker?.map((timeTracker: any, index: number) => {
        return (
          <TimeIntervalFormik
            isOnlyInterval={false}
            key={index * 1000}
            colors={colors}
            lunchTimeState={timeTracker}
            hasTitle={false}
            isCreate={true}
            index={index}
            additionalTimeTracker={additionalTimeTracker}
            setAdditionalTimeTracker={setAdditionalTimeTracker}
            renderMenuItems={renderMenuItems}
            refetch={refetch}
            selectedTimeTracker={selectedTimeTracker}
          />
        );
      })}
      {additionalOptionalBreak?.map((timeTracker: any, index: number) => {
        return (
          <TimeIntervalFormik
            isOnlyInterval={true}
            key={index * 1000}
            colors={colors}
            lunchTimeState={timeTracker}
            hasTitle={false}
            isCreate={true}
            index={index}
            additionalTimeTracker={additionalOptionalBreak}
            setAdditionalTimeTracker={setAdditionalOptionalBreak}
            renderMenuItems={renderMenuItems}
            refetch={refetch}
            selectedTimeTracker={selectedTimeTracker}
          />
        );
      })}
    </Grid>
  );
};

export default ManageFieldArray;
