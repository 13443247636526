import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
interface CustomTextFieldProps {
  value: any;
  name?: string;
  placeholder?: string;
  variant?: "outlined" | "standard" | "filled" | undefined;
  onChange?: any;
  errors?: any;
  touched?: any;
  type?: string;
  label?: string;
  disabled?: boolean;
  width?: any;
}
const CustomTextField = ({
  value,
  name,
  variant = "outlined",
  placeholder,
  errors,
  touched,
  onChange,
  type = "text",
  label,
  disabled,
  width,
}: CustomTextFieldProps) => {
  const [textDisable, setTextDisable] = useState<boolean>(false);
  useEffect(() => {
    value?.length > 240 ? setTextDisable(true) : setTextDisable(false);
  }, [value]);
  return (
    <TextField
      variant={variant}
      inputProps={{ style: { fontSize: "16px" } }}
      placeholder={placeholder}
      fullWidth={width ? false : true}
      sx={{ width: width }}
      label={label}
      rows={3}
      value={value}
      name={name}
      error={!!touched && !!errors}
      helperText={touched && errors}
      onChange={onChange}
      color="info"
      type={type}
      size="small"
      disabled={disabled || textDisable}
    />
  );
};

export default CustomTextField;
