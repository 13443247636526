import { useContext, useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "./styles.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  Box,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import CustomSelector from "../../shared/custom/CustomSelector";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  RED_COLOR_ACCENT,
  RED_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomHeader from "../../shared/custom/CustomHeader";
import { useDeleteFilePondFilesMutation } from "../../data/endpoints/app.endpoints";
import { RootContext } from "../../context/RootContext";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomSearchSelector from "../../shared/custom/CustomSearchSelector";
import { selectAllEmployees } from "../../data/store/employeeSlice";
import { useTranslation } from "react-i18next";
import { tokens } from "../../core/AppStyles";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { User } from "../../data/models/user.interface";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

interface IFileUpload {
  isModal?: boolean;
  type?: string;
  selectedUser?: string;
}

const FileUploadForm = ({ isModal, type, selectedUser }: IFileUpload) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [files, setFiles] = useState<any>([]);
  const [showUploadFile, setShowUploadFile] = useState<boolean>(false);
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "api"
      : process.env.REACT_APP_BASE_URL;
  const allUserResult = useSelector(selectAllEmployees);
  const loggedUser = useSelector(selectLoggedUser);
  const { t } = useTranslation();

  const userSelectedById = allUserResult?.find(
    (user: User) => user?.userId === selectedUser
  );

  const [selectUser, setSelectUser] = useState<any>(
    selectedUser ? selectedUser : ""
  );

  const [selectType, setSelectType] = useState<string>(type ? type : "");
  const [errors, setErrors] = useState<{
    selectUser?: string;
    filesError?: string;
    selectType?: string;
  }>({});

  const userInfo = allUserResult;
  const {
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);
  let pond: any = null;
  const [deleteFile] = useDeleteFilePondFilesMutation();

  const handleSelectUserSelector = (event: any, value: any) => {
    setSelectUser(value);
    if (files.length && pond) {
      pond?.removeFiles();
    }
  };

  const handleSelectTypeSelector = (event: SelectChangeEvent) => {
    setSelectType(event.target.value as any);
  };

  useEffect(() => {
    loggedUser?.role === "Employee"
      ? setSelectUser({
          label: loggedUser.firstName + " " + loggedUser.lastName,
          id: loggedUser.userId,
        })
      : setSelectUser("");
  }, [loggedUser?.role]);

  useEffect(() => {
    if (selectUser && errors) {
      setErrors({ ...errors, selectUser: "" });
    }
  }, [selectUser]);

  useEffect(() => {
    if (selectType && errors) {
      setErrors({ ...errors, selectType: "" });
    }
  }, [selectType]);

  useEffect(() => {
    if (files.length && errors && errors?.filesError?.length) {
      setErrors({ ...errors, filesError: "" });
    }
  }, [files]);

  const validate = (files: []) => {
    if (!selectUser && !selectedUser) {
      setErrors({
        ...errors,
        selectUser: `${t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}`,
      });
    }
    if (files.length === 0) {
      setErrors({
        ...errors,
        filesError: `${t("FILE_MANAGER.FILE_UPLOAD.NO_FILES_TO_UPLOAD")}`,
      });
    }
    if (!selectType) {
      setErrors({
        ...errors,
        selectType: `${t("FILE_MANAGER.FILE_UPLOAD.SELECT_A_FOLDER")}`,
      });
    }
    if (!selectUser && !selectedUser && files.length === 0) {
      setErrors({
        ...errors,
        selectUser: `${t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}`,
        filesError: `${t("FILE_MANAGER.FILE_UPLOAD.NO_FILES_TO_UPLOAD")}`,
      });
    }

    if (!selectUser && !selectedUser && !selectType) {
      setErrors({
        ...errors,
        selectUser: `${t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}`,
        selectType: `${t("FILE_MANAGER.FILE_UPLOAD.SELECT_A_FOLDER")}`,
      });
    }
    if (!selectType && files.length === 0) {
      setErrors({
        ...errors,
        selectType: `${t("FILE_MANAGER.FILE_UPLOAD.SELECT_A_FOLDER")}`,
        filesError: `${t("FILE_MANAGER.FILE_UPLOAD.NO_FILES_TO_UPLOAD")}`,
      });
    }
    if (!selectType && files.length === 0 && !selectUser && !selectedUser) {
      setErrors({
        selectUser: `${t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}`,
        selectType: `${t("FILE_MANAGER.FILE_UPLOAD.SELECT_A_FOLDER")}`,
        filesError: `${t("FILE_MANAGER.FILE_UPLOAD.NO_FILES_TO_UPLOAD")}`,
      });
    }

    return errors;
  };

  const onSubmit = async () => {
    const files = pond?.getFiles();
    setFiles(files);
    validate(files);
    if (selectUser && files && files.length > 0 && selectType) {
      pond
        ?.processFiles()
        .then((res: any) => {
          showSnackbar(`${t("FILE_MANAGER.FILE_UPLOAD.SUCCESS")}`, false, true);
        })
        .catch((error: unknown) => {
          showSnackbar(`${t("FILE_MANAGER.FILE_UPLOAD.ERROR")}`, true, true);
        });
    } else if (selectedUser && files && files.length > 0 && selectType) {
      pond
        ?.processFiles()
        .then((res: any) => {
          showSnackbar(`${t("FILE_MANAGER.FILE_UPLOAD.SUCCESS")}`, false, true);
        })
        .catch((error: unknown) => {
          showSnackbar(`${t("FILE_MANAGER.FILE_UPLOAD.ERROR")}`, true, true);
        });
    }
  };
  return (
    <Box
      className="App"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {isModal ? (
        ""
      ) : (
        <CustomHeader
          title={t("FILE_MANAGER.FILE_UPLOAD.TITLE")}
          subtitle={t("FILE_MANAGER.FILE_UPLOAD.SUBTITLE")}
        >
          <CustomNavigationButton
            navigateTo="/file-manager"
            children={<KeyboardArrowLeft />}
          />
        </CustomHeader>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
          gap: 2,
        }}
      >
        <Box
          sx={{
            width: `${isMobile || isTablet ? "100%" : "15%"}`,
            display: "flex",
            flexDirection: "column",
            gap: isMobile || isTablet ? 2 : 5,
          }}
        >
          <Box>
            {loggedUser && loggedUser?.role === "Admin" && selectedUser ? (
              <p
                style={{
                  width: "100%",
                  minHeight: "40px",
                  maxHeight: "auto",
                  border: "1px solid lightgray",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: isMobile ? "2vw" : "0.7vw",
                  cursor: "not-allowed",
                }}
              >
                {userSelectedById
                  ? userSelectedById?.firstName +
                    " " +
                    userSelectedById?.lastName
                  : "Can't upload files to yourself at the moment!"}
              </p>
            ) : (
              <CustomSearchSelector
                label={t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}
                width="auto"
                value={selectedUser || selectUser}
                onSelectChange={handleSelectUserSelector}
                datas={userInfo}
              />
            )}

            {errors && errors?.selectUser && errors?.selectUser.length ? (
              <Typography color="error.main" variant="subtitle2">
                {errors?.selectUser}
              </Typography>
            ) : null}
          </Box>
          <Box>
            {loggedUser && loggedUser?.role === "Admin" ? (
              <CustomSelector
                size="small"
                label={t("FILE_MANAGER.FILE_UPLOAD.SELECT_TYPE")}
                value={selectType}
                onSelectChange={handleSelectTypeSelector}
                width="100%"
              >
                <MenuItem value={"Expenses"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.EXPENSES")}
                </MenuItem>
                <MenuItem value={"Reports"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.REPORTS")}
                </MenuItem>
                <MenuItem value={"Other"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.OTHER")}
                </MenuItem>
                <MenuItem value={"Salaries"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.SALARIES")}
                </MenuItem>
              </CustomSelector>
            ) : (
              <CustomSelector
                size="small"
                label={t("FILE_MANAGER.FILE_UPLOAD.SELECT_TYPE")}
                value={type || selectType}
                onSelectChange={handleSelectTypeSelector}
                width="100%"
              >
                <MenuItem value={"Expenses"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.EXPENSES")}
                </MenuItem>
                <MenuItem value={"Reports"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.REPORTS")}
                </MenuItem>
                <MenuItem value={"Other"}>
                  {t("FILE_MANAGER.FILE_UPLOAD.OTHER")}
                </MenuItem>
              </CustomSelector>
            )}

            {errors && errors?.selectType && errors?.selectType.length ? (
              <Typography color="error.main" variant="subtitle2">
                {errors?.selectType}
              </Typography>
            ) : null}
          </Box>
          {!isModal && (
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "center" }}
              control={
                <Switch
                  color="secondary"
                  checked={showUploadFile}
                  onChange={() => {
                    setShowUploadFile(!showUploadFile);
                  }}
                  name={t("FILE_MANAGER.FILE_UPLOAD.ENABLE_INSTANT_UPLOAD")}
                />
              }
              label={
                !showUploadFile
                  ? `${t("FILE_MANAGER.FILE_UPLOAD.ENABLE_INSTANT_UPLOAD")}`
                  : `${t("FILE_MANAGER.FILE_UPLOAD.DISABLE_INSTANT_UPLOAD")}`
              }
            />
          )}
        </Box>
        <Box
          sx={{
            width: isMobile || isTablet ? "100%" : "90%",
            "& .custom-filepond": {
              minHeight: "200px !important",
              background: colors.filepond,
              overflowX: "hidden",
              scrollBehavior: "smooth",
              overflowY: "scroll",
              maxHeight: "66vh",
              borderRadius: "8px",
              "& .filepond--drop-label": {
                background: colors.filepond,
              },
              "& .filepond--panel-root": {
                background: colors.filepond,
              },
              "& .filepond--label-action": {
                color: colors.invert,
              },
            },
          }}
        >
          <FilePond
            ref={(ref) => {
              pond = ref;
            }}
            credits={false}
            allowProcess={true}
            files={files}
            acceptedFileTypes={[
              "application/pdf",
              "application/msword",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ".xlsx",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "image/*",
            ]}
            labelFileTypeNotAllowed={t("FILE_MANAGER.FILE_UPLOAD.ERROR")}
            allowFileSizeValidation={true}
            onaddfile={(error, file) => {
              if (error) {
                file?.abortLoad();
                //@ts-ignore
                showSnackbar(`${error?.main}`, true, true);
                return;
              }
            }}
            minFileSize={null}
            maxFileSize={"5000KB"}
            maxTotalFileSize={"15000KB"}
            labelMaxFileSize={t("FILE_MANAGER.FILE_UPLOAD.MAX_FILE_SIZE")}
            labelMaxTotalFileSize={t("FILE_MANAGER.FILE_UPLOAD.TOTAL_NAX_FILE")}
            //@ts-ignore
            fileValidateTypeDetectType={(source, type) =>
              new Promise((resolve, reject) => {
                resolve(type);
              })
            }
            //@ts-ignore
            allowImageCrop={true}
            allowImageTransform={true}
            imagePreviewHeight={200}
            imageCropAspectRatio={"1:1"}
            imageResizeTargetWidth={500}
            imageResizeTargetHeight={500}
            imageTransformAfterCreateBlob={(blob: any) =>
              new Promise((resolve) => {
                resolve(blob);
              })
            }
            onremovefile={(errors, file) => {}}
            dropOnElement={true}
            dropOnPage={true}
            allowImageResize={true}
            imageResizeMode={"cover"}
            imageTransformOutputQuality={100}
            imageTransformOutputQualityMode="optional"
            onupdatefiles={setFiles}
            instantUpload={showUploadFile}
            allowMultiple={true}
            server={{
              process: {
                url: `${baseUrl}/upload`,
                method: "POST",
                ondata: (formdata) => {
                  formdata.append(
                    "userId",
                    !selectedUser ? selectUser.id : selectedUser
                  );
                  formdata.append("type", selectType);
                  return formdata;
                },
              },
              revert: (props) => {
                const resData = JSON.parse(props) as {
                  fileId: string;
                };
                deleteFile({
                  fileId: resData?.fileId,
                }).then((res: any) => {
                  if (res?.data) {
                    showSnackbar(
                      `${t("FILE_MANAGER.FILE_UPLOAD.DELETED_SUCCESSFULLY")}`,
                      false,
                      true
                    );
                  } else if (res?.error) {
                    showSnackbar(res?.error?.data?.error?.message, true, true);
                  }
                });
              },
            }}
            name="file"
            className="custom-filepond"
            labelIdle={`<span class="filepond--label-action">${t(
              "FILE_MANAGER.FILE_UPLOAD.DRAG_DROP_FILES"
            )}</span>`}
            allowReorder={true}
            checkValidity={true}
            allowRevert={true}
          />

          {errors && errors?.filesError && errors?.filesError.length ? (
            <Typography color="error.main" variant="subtitle2">
              {errors?.filesError}
            </Typography>
          ) : null}
          <CustomButton
            title={t("FILE_MANAGER.FILE_UPLOAD.UPLOAD")}
            color={BLUE_COLOR_ACCENT}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
            onClick={onSubmit}
            type="submit"
            width="100%"
            mt="1vh"
          />

          <CustomButton
            title={t("FILE_MANAGER.FILE_UPLOAD.CLEAN_FILES")}
            color={RED_COLOR_ACCENT}
            colorHover={RED_HOVER_COLOR_ACCENT}
            onClick={() => {
              pond?.removeFiles();
            }}
            type="submit"
            width="100%"
            mt={"10px"}
            disabled={!files.length ? true : false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadForm;
