import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./ErrorPage";

export default function ReactErrorBoundary(props: any) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        console.log("Error caught!");
        console.error(error);
        console.error(errorInfo);
      }}
      onReset={() => {
        console.log("reloading the page...");
        window.location.href = "/";
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
