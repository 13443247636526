import TextField from "@mui/material/TextField/TextField";

interface CustomSelectorProps {
  label?: string;
  value: any;
  onSelectChange: any;
  children?: any;
  width?: string | number;
  size?: "small" | "medium" | undefined;
  isStatusSelector?: boolean;
  name?: string;
  errors?: any;
  touched?: any;
  SelectProps?: any;
  showError?: boolean;
  disabled?: boolean;
  defaultValue?: any;
}

const CustomSelector = ({
  label,
  value,
  onSelectChange,
  children,
  name,
  width,
  size,
  isStatusSelector = false,
  errors,
  touched,
  SelectProps,
  showError = true,
  disabled,
  defaultValue,
}: CustomSelectorProps) => {
  const showHelperText = showError ? { helperText: touched && errors } : {};

  return (
    <TextField
      variant="outlined"
      inputProps={{ style: { fontSize: "16px" } }}
      size="small"
      select
      name={name}
      onChange={onSelectChange}
      label={label}
      color="info"
      value={value}
      sx={
        isStatusSelector
          ? {
              width: "15%",
              display: "flex",
              alignSelf: "flex-end",
              m: 1.5,
            }
          : { width }
      }
      SelectProps={SelectProps}
      error={!!touched && !!errors}
      {...showHelperText}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {children}
    </TextField>
  );
};

export default CustomSelector;
