import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import PDF from "../../assets/listPdf.png";
import WORD from "../../assets/listWord.png";
import EXCEL from "../../assets/listExcel.png";
import CSV from "../../assets/listCsv.png";
import IMAGE from "../../assets/listPicture.png";
import { useState } from "react";
import {
  useDeleteFilePondFilesMutation,
  useLazyDownloadFileByUserIdQuery,
} from "../../data/endpoints/app.endpoints";
import FilePreviewModal from "./FilePreviewModal";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@mui/icons-material";
import AlertDialog from "../../shared/modal/AlertDialog";
import useIsMobile from "../../shared/hooks/useIsMobile";

interface IFileProperties {
  fileId: string;
  fileName: string;
  fileSize?: string;
  fileDate?: string;
  fileUrl?: string;
}

interface IFile {
  file: IFileProperties;
}

const FileListVew = ({ file }: IFile) => {
  const isMobile = useIsMobile();
  const [fileUrl, setFileUrl] = useState("");
  const [downloadFile, result] = useLazyDownloadFileByUserIdQuery();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteFile] = useDeleteFilePondFilesMutation();
  const open = Boolean(anchorEl);
  const loggedUser = useSelector(selectLoggedUser);
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const fileType: string | undefined = file.fileName
    ?.split(".")
    ?.pop()
    ?.toLowerCase();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDownload = async () => {
    try {
      const response = await downloadFile({
        fileId: file?.fileId,
        fileName: file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = file?.fileName;
          link.click();
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const handleDelete = () => {
    deleteFile({
      fileId: file?.fileId,
    });
    setOpenModal(false);
    handleClose();
  };

  const handleViewFile = async () => {
    try {
      const response = await downloadFile({
        fileId: file?.fileId,
        fileName: file?.fileName,
      });

      if ("data" in response) {
        const { downloadUrl } = response.data;
        if (downloadUrl) {
          setFileUrl(downloadUrl);
          setPreviewOpen(true);
        }
      }
    } catch (error) {
      alert("Error downloading file");
    }
  };
  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const closePreviewModal = () => {
    setPreviewOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "10px 10px 10px 0px",
          gap: 1,
          overflow: "hidden",
          cursor: "pointer",
        }}
        key={file?.fileId}
      >
        <img
          src={
            fileType === "pdf"
              ? PDF
              : fileType === "docx"
              ? WORD
              : fileType === "xlsx"
              ? EXCEL
              : fileType === "csv"
              ? CSV
              : IMAGE
          }
          alt="file logo"
          width="26px"
        />
        {isMobile ? (
          <span onClick={() => handleViewFile()}>
            {file?.fileName?.slice(0, 30)}
          </span>
        ) : (
          <span onDoubleClick={() => handleViewFile()}>
            {file?.fileName?.slice(0, 40)}
          </span>
        )}

        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert sx={{ width: "15px", height: "15px" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleDownload();
              handleClose();
            }}
          >
            Download
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleViewFile();
              handleClose();
            }}
          >
            View
          </MenuItem>
          {loggedUser?.role === "Admin" && (
            <AlertDialog
              openModal={openModal}
              setOpenModal={setOpenModal}
              buttonTitle=""
              handleSubmitDialog={handleDelete}
              ButtonComponent={(props: any) => (
                <MenuItem onClick={handleModalOpen}>Delete</MenuItem>
              )}
              mt="20px"
            >
              {t("FILE_MANAGER.FILE_DOWNLOAD.MODAL_DELETE_CONFIRMATION")}
            </AlertDialog>
          )}
        </Menu>
      </Box>
      <FilePreviewModal
        fileName={file?.fileName?.toLowerCase()}
        fileUrl={fileUrl}
        open={previewOpen}
        handleClose={closePreviewModal}
      />
    </>
  );
};

export default FileListVew;
