import useAdminRoutes from "./useAdminRoutes";
import useEmployeeRoutes from "./useEmployeeRoutes";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { UserRole } from "../../utils/enums/user-role.enum";
import { Route } from "react-router";
import { AdminRoutes } from "../admin";

interface UseValidatedRoutesProps {
  refreshToken: string;
}

const useValidatedRoutes = ({ refreshToken }: UseValidatedRoutesProps) => {
  const user = useSelector(selectLoggedUser);

  const adminRoutes = useAdminRoutes({ refreshToken });
  const employeeRoutes = useEmployeeRoutes({ refreshToken });

  if (!refreshToken) {
    return (
      <Route
        path={window.location.hash.split("#")[1]}
        element={<AdminRoutes refreshToken={refreshToken} />}
      />
    );
  }

  if (user?.role === UserRole.ADMIN) {
    return adminRoutes;
  }

  if (user?.role === UserRole.EMPLOYEE) {
    return employeeRoutes;
  }

  return <></>;
};

export default useValidatedRoutes;
