export enum LeaveStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export enum LeaveDays {
  ONE_DAY = "One Day",
  HALF_DAY = "Half Day",
  MANY_DAYS = "Many Days"
}
