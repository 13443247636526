import { InputAdornment, TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface PasswordTestFieldProps {
  isVisible?: boolean;
  toggleVisibility?: () => void;
  value?: any;
  error?: any;
  handleChange?: any;
  variableName?: string;
  label?: string;
  hasMargin?: boolean;
  touched?: any;
}

const PasswordTestField = ({
  isVisible,
  toggleVisibility,
  value,
  error,
  handleChange,
  touched,
  variableName,
  label,
  hasMargin = false,
}: PasswordTestFieldProps) => {

  return (
    <TextField
      margin={hasMargin ? "normal" : "none"}
      variant="outlined"
      inputProps={{
        style: { fontSize: "16px" }, form: {
          autocomplete: 'off',
        },
      }}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment sx={{ color: "#aaadb3" }} position="end">
            {isVisible ? (
              <VisibilityIcon
                sx={{ cursor: "pointer" }}
                onClick={toggleVisibility}
              />
            ) : (
              <VisibilityOffIcon
                sx={{ cursor: "pointer" }}
                onClick={toggleVisibility}
              />
            )}
          </InputAdornment>
        ),
      }}
      fullWidth
      id={variableName}
      label={label}
      name={variableName}
      autoComplete={variableName}
      value={value}
      color="info"
      onChange={handleChange}
      type={isVisible ? "text" : "password"}
      error={!!touched && !!error}
      helperText={touched && error}
    />
  );
};

export default PasswordTestField;
