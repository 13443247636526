import {
  Folder,
  Dashboard,
  Groups,
  TableChartOutlined,
  ManageHistory,
  History,
  Celebration,
  AirplaneTicket,
  CloudUpload,
  CloudDownload,
  MoreTime,
  LogoutOutlined,
  AccessTime,
  PersonPin,
  Work,
  Badge,
  EventBusy,
  LocalShipping,
  CarRepair,
  Insights,
} from "@mui/icons-material";
import { Box, Drawer, useTheme } from "@mui/material";
import { MouseEvent, useContext, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  MenuItemStyles,
} from "react-pro-sidebar";
import { tokens } from "./AppStyles";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useLazyLogoutQuery } from "../data/endpoints/app.endpoints";
import { selectLoggedUser } from "../data/store/authSlice";
import { RootContext } from "../context/RootContext";
import { useDispatch } from "react-redux";
import { UserRole } from "../utils/enums/user-role.enum";
import AlertDialog from "../shared/modal/AlertDialog";
import { SidebarHeader } from "./sidebar/SidenavHeader";
import { useTranslation } from "react-i18next";

interface IMobileSideNavProps {
  collapsed: any;
  setCollapsed: any;
}

export const MobileSideNav = ({
  collapsed,
  setCollapsed,
}: IMobileSideNavProps) => {
  const { pathname } = useLocation();
  const navigation = useNavigate();

  const [selected, setSelected] = useState(pathname);
  const [openLogOutModal, setLogOutModal] = useState(false);
  const user = useSelector(selectLoggedUser);
  const [logoutUser] = useLazyLogoutQuery();
  const { logoutAndClearState } = useContext(RootContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState<any>(
    getLastOpenSubMenuFromSession()
  );

  const handleModalOpen = () => {
    setLogOutModal(true);
  };

  const handleItemClick = (to: any) => {
    setSelected(to);
    navigation(to);
  };

  function getLastOpenSubMenuFromSession() {
    return sessionStorage.getItem("lastOpenSubMenu") || null;
  }

  function setLastOpenSubMenuToSession(submenu: any) {
    sessionStorage.setItem("lastOpenSubMenu", submenu);
  }

  const handleSubMenuClick = (name: any) => {
    setOpenSubMenu(name === openSubMenu ? null : name);
    setLastOpenSubMenuToSession(name === openSubMenu ? null : name);
  };
  const toggleDrawer = (open: boolean) => (event: MouseEvent) => {
    setCollapsed({ ...collapsed, left: open });
  };

  const menuItemStyles: MenuItemStyles = {
    subMenuContent: {
      backgroundColor: colors.component,
    },
    button: ({ active }) => {
      if (active) {
        return {
          backgroundColor: colors.sideNav,
          borderRadius: "0 10px 10px 0",
          transition: "ease-in-out 0.2s",
          "&:hover": {
            backgroundColor: "#b7dbff80",
          },
        };
      }
      return {
        borderRadius: "0 10px 10px 0",
        "&:hover": {
          backgroundColor: "rgba(183, 219, 255, 0.2)",
          transition: "ease-in-out 0.2s",
        },
      };
    },
    root: {
      margin: "0 0 5px 0",
      borderRadius: "0 10px 10px 0",
      width: "90%",
      fontSize: "15px",
      fontWeight: 400,
      backgroundColor: colors.component,
      border: "none",
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div>
      <Drawer
        sx={{ zIndex: 1999 }}
        PaperProps={{
          sx: {
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
        anchor="left"
        open={collapsed.left}
        onClose={toggleDrawer(false)}
      >
        <Box
          id="mobile-sidebar"
          sx={{
            display: "flex",
            height: "100%",
            boxShadow: `${colors.shadow} 0px 6px 24px 0px, ${colors.shadow} 0px 0px 0px 1px`,
            backgroundColor: colors.component,
          }}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <Sidebar
              style={{
                border: "none",
                backgroundColor: colors.component,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100vh",
                  backgroundColor: colors.component,
                }}
                id="mobile-sidebar"
              >
                <SidebarHeader
                  handleItemClick={handleItemClick}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                <div
                  style={{
                    flex: 1,
                    marginBottom: "32px",
                    marginTop: "25px",
                    backgroundColor: colors.component,
                  }}
                >
                  <Menu menuItemStyles={menuItemStyles}>
                    <MenuItem
                      active={selected === "/dashboard"}
                      onClick={() => {
                        handleItemClick("/dashboard");
                        setCollapsed({ left: false });
                      }}
                      icon={<Dashboard />}
                    >
                      {t("SIDENAV.DASHBOARD")}
                    </MenuItem>
                    {user?.role === UserRole.ADMIN ? (
                      <>
                        <MenuItem
                          active={selected === "/employees"}
                          onClick={() => {
                            handleItemClick("/employees");
                            setCollapsed({ left: false });
                          }}
                          icon={<Groups />}
                        >
                          {t("SIDENAV.USERS")}
                        </MenuItem>
                        <SubMenu
                          onClick={() => handleSubMenuClick("Vehicle")}
                          open={openSubMenu === "Vehicle"}
                          label={t("SIDENAV.VEHICLE")}
                          icon={<LocalShipping />}
                        >
                          <MenuItem
                            active={selected === "/vehicles"}
                            onClick={() => {
                              handleItemClick("/vehicles");
                              setCollapsed({ left: false });
                            }}
                            icon={<CarRepair />}
                          >
                            {t("SIDENAV.VEHICLES")}
                          </MenuItem>
                          <MenuItem
                            active={selected === "/vehicle-insights"}
                            onClick={() => {
                              handleItemClick("/vehicle-insights");
                              setCollapsed({ left: false });
                            }}
                            icon={<Insights />}
                          >
                            {t("SIDENAV.VEHICLE_INSIGHTS")}
                          </MenuItem>
                        </SubMenu>
                        <MenuItem
                          active={selected === "/activity"}
                          onClick={() => {
                            handleItemClick("/activity");
                            setCollapsed({ left: false });
                          }}
                          icon={<TableChartOutlined />}
                        >
                          {t("SIDENAV.ACTIVITY")}
                        </MenuItem>
                        <SubMenu
                          onClick={() => handleSubMenuClick("Timetracker")}
                          open={openSubMenu === "Timetracker"}
                          label="Time Tracker"
                          icon={<AccessTime />}
                        >
                          <MenuItem
                            active={selected === "/manage-time-trackers"}
                            onClick={() => {
                              handleItemClick("/manage-time-trackers");
                              setCollapsed({ left: false });
                            }}
                            icon={<ManageHistory />}
                          >
                            {t("SIDENAV.MANAGE_TIME_TRACKERS")}
                          </MenuItem>
                          <MenuItem
                            active={selected === "/view-time-trackers"}
                            onClick={() => {
                              handleItemClick("/view-time-trackers");
                              setCollapsed({ left: false });
                            }}
                            icon={<History />}
                          >
                            {t("SIDENAV.VIEW_TIME_TRACKERS")}
                          </MenuItem>
                        </SubMenu>
                        <SubMenu
                          onClick={() => handleSubMenuClick("Timeoff")}
                          open={openSubMenu === "Timeoff"}
                          label={t("SIDENAV.ABSENCE_MANAGEMENT")}
                          icon={<Badge />}
                        >
                          <MenuItem
                            active={selected === "/leave-requests"}
                            onClick={() => {
                              handleItemClick("/leave-requests");
                              setCollapsed({ left: false });
                            }}
                            icon={<AirplaneTicket />}
                          >
                            {t("SIDENAV.LEAVE_REQUEST")}
                          </MenuItem>
                          <MenuItem
                            active={selected === "/absents"}
                            onClick={() => {
                              handleItemClick("/absents");
                              setCollapsed({ left: false });
                            }}
                            icon={<EventBusy />}
                          >
                            {t("SIDENAV.ABSENCE_TODAY")}
                          </MenuItem>
                          <MenuItem
                            active={selected === "/holidays"}
                            onClick={() => {
                              handleItemClick("/holidays");
                              setCollapsed({ left: false });
                            }}
                            icon={<Celebration />}
                          >
                            {t("SIDENAV.NATIONAL_HOLIDAYS")}
                          </MenuItem>
                        </SubMenu>
                        <MenuItem
                          active={selected === "/file-manager"}
                          onClick={() => {
                            handleItemClick("/file-manager");
                            setCollapsed({ left: false });
                          }}
                          icon={<Folder />}
                        >
                          {t("SIDENAV.FILE_MANAGER")}
                        </MenuItem>
                      </>
                    ) : user?.role === UserRole.EMPLOYEE ? (
                      <>
                        <MenuItem
                          active={selected === "/time-tracker"}
                          onClick={() => {
                            handleItemClick("/time-tracker");
                            setCollapsed({ left: false });
                          }}
                          icon={<MoreTime />}
                        >
                          {t("SIDENAV.TIME_TRACKER")}
                        </MenuItem>
                        <MenuItem
                          active={selected === "/my-work-time"}
                          onClick={() => {
                            handleItemClick("/my-work-time");
                            setCollapsed({ left: false });
                          }}
                          icon={<Work />}
                        >
                          {t("SIDENAV.MY_WORK_TIME")}
                        </MenuItem>
                        <MenuItem
                          active={selected === "/my-profile"}
                          onClick={() => {
                            handleItemClick("/my-profile");
                            setCollapsed({ left: false });
                          }}
                          icon={<PersonPin />}
                        >
                          {t("SIDENAV.MY_PROFILE")}
                        </MenuItem>
                        <MenuItem
                          active={selected === "/leave-request"}
                          onClick={() => {
                            handleItemClick("/leave-request");
                            setCollapsed({ left: false });
                          }}
                          icon={<AirplaneTicket />}
                        >
                          {t("SIDENAV.LEAVE_REQUEST")}
                        </MenuItem>
                        <MenuItem
                          active={selected === "/file-manager"}
                          onClick={() => {
                            handleItemClick("/file-manager");
                            setCollapsed({ left: false });
                          }}
                          icon={<Folder />}
                        >
                          {t("SIDENAV.FILE_MANAGER")}
                        </MenuItem>
                      </>
                    ) : null}
                    <AlertDialog
                      openModal={openLogOutModal}
                      setOpenModal={setLogOutModal}
                      buttonTitle=""
                      handleSubmitDialog={() => {
                        logoutUser(undefined).then(() => {
                          logoutAndClearState(navigation, dispatch);
                        });
                      }}
                      ButtonComponent={(props: any) => (
                        <MenuItem
                          onClick={handleModalOpen}
                          icon={<LogoutOutlined />}
                        >
                          {t("SIDENAV.LOGOUT")}
                        </MenuItem>
                      )}
                      mt="20px"
                    >
                      {t("LOGOUT_MODAL_TEXT")}
                    </AlertDialog>
                  </Menu>
                </div>
              </div>
            </Sidebar>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};
