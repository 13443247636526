import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";
import { LeaveRequest } from "../models/leave-request.interface";

const initialState: {
  leaves: LeaveRequest[];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  leaves: [],
  isError: false,
  isSuccess: false,
  isLoading: true,
};

const selectSelf = (state: RootState) => state.leaves;

export const selectLeavesState = createSelector(selectSelf, (state) => state);

export const selectAllLeaves = createSelector(
  selectLeavesState,
  (state) => state.leaves
);

export const selectLeaveById = (leaveId: any) =>
  createSelector(
    selectAllLeaves,
    (state) => state.find((leave) => leave.leaveId === leaveId) as LeaveRequest
  );

export const leavesSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    resetLeavesState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getAllLeaves.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.leaves = payload?.data;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getAllLeaves.matchPending,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = true;
          state.leaves = [];
          state.isSuccess = false;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getAllLeaves.matchRejected,
        (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.leaves = [];
          state.isSuccess = false;
        }
      ),
});

export const { resetLeavesState } = leavesSlice.actions;
