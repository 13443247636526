import { Box, Typography, useTheme } from "@mui/material";
import PageNotFoundIcon from "../../assets/404PageNotFound.svg";
import WestIcon from "@mui/icons-material/West";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { tokens } from "../AppStyles";
import { useTranslation } from "react-i18next";
const PageNotFound = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        height: "100vh",
        width: "100vw",
        background: colors.white,
      }}
    >
      <Box
        sx={{
          height: "30%",
          width: "50%",
        }}
      >
        <img
          src={PageNotFoundIcon}
          alt="404 icon "
          width={"100%"}
          height={"100%"}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography
          variant="h1"
          color={colors.pageNotFound}
          sx={{
            fontWeight: 700,
            fontFamily: "sans-serif",
          }}
          textAlign={"center"}
        >
          Page not found !
        </Typography>
        <Typography
          variant="h3"
          color={colors.pageNotFound}
          sx={{
            fontFamily: "sans-serif",
          }}
        >
          Sorry, the page you are looking for cannot be found.
        </Typography>
        <CustomNavigationButton
          customStyle={{
            backgroundColor: "#007FFC",
            width: "22%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            ":hover": {
              bgcolor: "#006BD5",
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <WestIcon /> {t("GO_BACK")}
        </CustomNavigationButton>
      </Box>
    </Box>
  );
};

export default PageNotFound;
