import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./data/store/store";
import { Provider } from "react-redux";
import { RootProvider } from "./context/RootContext";
import ErrorBoundary from "./core/Errors/ErrorBoundary";

import "./localization";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <RootProvider>
        <App />
      </RootProvider>
    </Provider>
  </ErrorBoundary>
);
