import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { appEndpoints } from "../endpoints/app.endpoints";
import { PaginationState } from "../models/states/pagination-state.interface";
import { Vehicle } from "../models/vehicle.interface";
import { VehicleStatus } from "../../utils/enums/vehicle-status.enum";

const initialState: any = {
  data: [],
  isLoading: true,
  error: undefined,
};

const selectSelf: any = (state: RootState) => state.vehicles;

export const selectVehicleSliceState = createSelector(
  selectSelf,
  (state) => state as PaginationState<Vehicle>
);
export const selectAllVehicles = createSelector(
  selectVehicleSliceState,
  (state) => state.data
);

export const selectInUseVehicles = createSelector(selectAllVehicles,
  (state) => state.filter(vehicle => vehicle.status === VehicleStatus.IN_USE))

export const selectVehicleById = (id: string | undefined) =>
  createSelector(
    selectAllVehicles,
    (state) => state.find((vehicle) => vehicle.vehicleId === id) as Vehicle
  );

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    resetVehicleState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getAllVehicles.matchFulfilled,
        (state, { payload }) => {
          state.error = undefined;
          state.isLoading = false;
          state.data = payload;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getAllVehicles.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload, isLoading: true };
        }
      )
      .addMatcher(
        appEndpoints.endpoints.createVehicle.matchRejected,
        (state, { payload }: any) => {
          return { ...state, error: payload };
        }
      ),
});

export const { resetVehicleState } = vehicleSlice.actions;
