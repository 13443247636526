import { useEffect, useMemo } from "react";
import { useLazyGetDailyTimeTrackerQuery } from "../../data/endpoints/app.endpoints";
import moment from "moment";
import { Box, Grid, Modal, Skeleton, useTheme } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TimeTrackerType } from "../../utils/enums/timetracker-type.enum";
import { tokens } from "../../core/AppStyles";
import TimeIntervalFormik from "./TimeIntervalFormik";
import ManageFieldArray from "./ManageFieldArray";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAllVehicles } from "../../data/store/vehicleSlice";

const ManageTimeTrackersModal = ({
  isOpen,
  handleClose,
  selectedUser,
  selectedDate,
  selectedTimeTracker,
  setSelectedTimeTracker,
}: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const vehicles = useSelector(selectAllVehicles);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [getDailyTimeTracker, result] = useLazyGetDailyTimeTrackerQuery();
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedUser && selectedDate) {
      fetchData().then((res: any) => {
        if (res?.data && res?.data?.worked) {
          setSelectedTimeTracker(res?.data);
        } else if (res?.data?.worked?.length === 0) {
          setSelectedTimeTracker(null);
        } else {
          setSelectedTimeTracker(null);
        }
      });
    }
  }, [selectedUser, selectedDate, result?.data]);

  const renderMenuItems = useMemo(() => {
    const menuItems: JSX.Element[] = [];

    vehicles?.map((vehicle) => {
      menuItems.push(
        <MenuItem value={vehicle?.vehicleId}>
          {vehicle?.model} - {vehicle?.plate}
        </MenuItem>
      );
    });

    return menuItems;
  }, [vehicles]);

  const fetchData = () => {
    return getDailyTimeTracker({
      date: selectedDate,
      userId: selectedUser?.userId,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "60%",
          height: isMobile ? "70%" : "50%",
          bgcolor: colors.component,
          boxShadow: 24,
          overflowY: "hidden",
        }}
      >
        <Box
          sx={{
            p: 3,
            height: "13%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: colors.shadow,
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 0px 10px 0px, rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;",
          }}
        >
          <Box sx={{ fontSize: isMobile ? 13 : 22, letterSpacing: 1 }}>
            {selectedUser ? (
              (
                selectedUser?.firstName +
                " " +
                selectedUser?.lastName
              ).toUpperCase()
            ) : (
              <Skeleton />
            )}
          </Box>
          <Box sx={{ fontSize: isMobile ? 13 : 22, letterSpacing: 1 }}>
            {selectedDate ? (
              moment(selectedDate).format("dddd, DD-MMM-YYYY")
            ) : (
              <Skeleton />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "87%",
            overflowY: "scroll",
            p: 3,
          }}
        >
          <Grid container>
            {selectedTimeTracker ? (
              <>
                <TimeIntervalFormik
                  colors={colors}
                  lunchTimeState={selectedTimeTracker.lunchTime}
                  hasTitle={true}
                  selectedTimeTracker={selectedTimeTracker}
                />
                <TimeIntervalFormik
                  colors={colors}
                  isOnlyInterval={false}
                  title={t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.WORKED")}
                  lunchTimeState={selectedTimeTracker.worked}
                  hasTitle={true}
                  renderMenuItems={renderMenuItems}
                  selectedTimeTracker={selectedTimeTracker}
                />
                <ManageFieldArray
                  type={TimeTrackerType.BREAK_TIME}
                  date={selectedTimeTracker?.lunchTime?.date}
                  userId={selectedTimeTracker?.lunchTime?.userId}
                  colors={colors}
                  timeTrackerArray={selectedTimeTracker.optionalBreak}
                  title={t(
                    "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.OPTIONAL_BREAK"
                  )}
                  setSelectedTimeTracker={setSelectedTimeTracker}
                  // renderMenuItems={renderMenuItems}
                  refetch={fetchData}
                  selectedTimeTracker={selectedTimeTracker}
                />
              </>
            ) : (
              <LoadingSpinner height="40vh" />
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ManageTimeTrackersModal;
