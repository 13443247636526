import { TableCell, TableRow } from "@mui/material";
import {
  GREEN_COLOR_ACCENT,
  RED_COLOR_ACCENT,
  STATUS_PENDING_COLOR,
} from "../../utils/constants/colors";
import moment from "moment";
import { memo } from "react";
import CustomTooltip from "../../shared/custom/CustomDescriptionModal";
import { useTranslation } from "react-i18next";
interface UserLeaveRequestsTableRowProps {
  row: any;
}

const AllLeaveRequestsTableRow = ({ row }: UserLeaveRequestsTableRowProps) => {
  const {
    startDate,
    endDate,
    type,
    description,
    status,
    daysRequired,
    user: { firstName, lastName, email, totalVacations },
  } = row;
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {moment(startDate).format("DD MMM YYYY")}
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(endDate).format("DD MMM YYYY")}
        </TableCell>
        <TableCell component="th" scope="row">
          {type}
        </TableCell>
        <TableCell component="th" scope="row">
          {daysRequired}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            color:
              status === "Pending"
                ? STATUS_PENDING_COLOR
                : status === "Approved"
                ? GREEN_COLOR_ACCENT
                : RED_COLOR_ACCENT,
            fontWeight: "500",
          }}
        >
          {status}
        </TableCell>
        <TableCell component="th" scope="row">
          <CustomTooltip descriptionText={description} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default memo(AllLeaveRequestsTableRow);
