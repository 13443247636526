import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { useState } from "react";
import { PrevIcon, NextIcon } from "../../shared/custom/CustomEditIcons";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { Visibility } from "@mui/icons-material";

interface ICustomTableMobile {
  column1Name?: string;
  column2Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  openModalManageTimeTracker: any;
}

export default function UserPersonalCompletedTTtableMobile({
  column1Name,
  column2Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
  openModalManageTimeTracker,
}: ICustomTableMobile) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();
  const loggedUser = useSelector(selectLoggedUser);

  const { t } = useTranslation();

  const handleDetailsModal = (id: string, date: string) => {
    const _rowDetails = data?.filter(
      (info: any) => info?.user?.userId === id && info?.date === date
    );

    const rowEmployeeData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowEmployeeData.push({
        data: [
          {
            key: `${t("DATE")}`,
            value: moment(info?.date)?.format("DD MMM YYYY"),
          },
          {
            key: `${t("START_TIME")}`,
            value: moment(info?.firstStartTime).format("HH:mm"),
          },
          {
            key: `${t("END_TIME")}`,
            value: moment(info?.lastStartTime).format("HH:mm"),
          },
          {
            key: `${t("WORKED_HOURS")}`,
            value: (info?.work_time / 60).toFixed(2).replace(/(\.0+|0+)$/, ""),
          },
          {
            key: `${t("LEAVES")}`,
            value: (info?.leaveHours).toFixed(2).replace(/(\.0+|0+)$/, ""),
          },
          {
            key: `${t("OVERTIME")}`,
            value: (
              info?.work_time / 60 +
                info?.leaveHours -
                loggedUser?.workingHours || 0
            )
              .toFixed(2)
              .replace(/(\.0+|0+)$/, ""),
          },
        ],
        actions: [
          {
            key: (
              <Visibility
                fill={colors.invert}
                onClick={() => {
                  openModalManageTimeTracker(info?.user, info?.date);
                }}
              />
            ),
          },
        ],
      });
    });

    setRowDetails(rowEmployeeData);
    setHeader(rowEmployeeData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 350, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() =>
                    handleDetailsModal(row?.user?.userId, row?.date)
                  }
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{moment(row?.date)?.format("DD MMM YYYY")}</b> <br />{" "}
                  {row?.work_time / 60}
                  {" h"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>ALL</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
}
