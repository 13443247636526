import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  GREEN_COLOR_ACCENT,
} from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { selectLoggedUser } from "../../data/store/authSlice";
import { useLazyGetLeavesByUserIdQuery } from "../../data/endpoints/app.endpoints";
import LoadingSpinner from "../../shared/LoadingSpinner";
import CustomTable from "../../shared/custom/CustomTable";
import UserLeaveRequestsTableRow from "./UserLeaveRequestsTableRow.tsx";
import AddIcon from "@mui/icons-material/Add";
import CustomUserLeavesTableMobile from "./CustomUserLeavesTableMobile";
import CustomSelector from "../../shared/custom/CustomSelector";
import { OutOfOffice } from "../../utils/enums/out-of-office.enum";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import { useTranslation } from "react-i18next";
import NoDataComponent from "../../shared/NoDataComponent";

const UserLeaveRequest = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const user = useSelector(selectLoggedUser);
  const [getLeavesByUserId, result] = useLazyGetLeavesByUserIdQuery();
  const { data, isSuccess } = result;
  const AllLeaveRequestsTableTitles = [
    t("START_DATE"),
    t("END_DATE"),
    t("TYPE"),
    t("LEAVE_REQUESTS.DAYS_REQUIRED"),
    t("STATUS"),
    t("DESCRIPTION"),
  ];

  const [status, setStatus] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);

  const [page, setPage] = useState(1);
  const [type, setType] = useState("ALL");

  const handleRowPerPageChange = (event: any) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };
  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (user) {
      getLeavesByUserId({
        id: user?.userId,
        currentPage: page,
        limitTo: limit,
        status: status === "ALL" ? "" : status,
        type: type === "ALL" ? "" : type,
      });
    } else {
    }
  }, [user, page, status, limit, type]);

  const leaveRequestsBodyRenderer = useMemo(() => {
    const leaveRequestsDataRenderer: JSX.Element[] = [];

    data?.data?.map((row: any, index: number) => {
      leaveRequestsDataRenderer.push(
        <UserLeaveRequestsTableRow key={index} row={row} />
      );
    });
    return leaveRequestsDataRenderer;
  }, [data]);

  const totalVacations = user.totalVacations
    .toFixed(2)
    .replace(/(\.0+|0+)$/, "");

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("LEAVE_REQUEST")}
          subtitle={t("LEAVE_REQUESTS.USER.VIEW_LEAVE_REQUESTS")}
          btnTitle="+"
          btnNavLink="/add-leave"
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              marginTop: "2vh",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                padding: "8px",
                borderRadius: "5px",
                fontSize: "12px",
                color: GREEN_COLOR_ACCENT,
                width: "100%",
                fontWeight: 700,
                border: "1px solid",
                borderColor: GREEN_COLOR_ACCENT,
              }}
            >
              {t("TOTAL_VACATIONS").toUpperCase()}: {totalVacations}
            </Box>
            <br />
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>
                {t("LEAVE_REQUESTS.STATUS.ALL")}
              </MenuItem>
              <MenuItem value={"Pending"}>
                {t("LEAVE_REQUESTS.STATUS.PENDING")}
              </MenuItem>
              <MenuItem value={"Rejected"}>
                {t("LEAVE_REQUESTS.STATUS.REJECTED")}
              </MenuItem>
              <MenuItem value={"Approved"}>
                {t("LEAVE_REQUESTS.STATUS.APPROVED")}
              </MenuItem>
            </CustomSelector>
            <br />
            <CustomSelector
              label={t("TYPE")}
              value={type}
              onSelectChange={handleTypeChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>{t("ALL")}</MenuItem>
              {Object.values(OutOfOffice).map((value, index: number) => {
                return (
                  <MenuItem key={index} value={value}>
                    {t(`LEAVE_REQUESTS.OUT_OF_OFFICE.${value?.replace(" ", "_").replace("/", "_").toUpperCase()}`)}
                  </MenuItem>
                );
              })}
            </CustomSelector>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("LEAVE_REQUEST")}
          subtitle={t("LEAVE_REQUESTS.USER.VIEW_LEAVE_REQUESTS")}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>
                {t("LEAVE_REQUESTS.STATUS.ALL")}
              </MenuItem>
              <MenuItem value={"Pending"}>
                {t("LEAVE_REQUESTS.STATUS.PENDING")}
              </MenuItem>
              <MenuItem value={"Rejected"}>
                {t("LEAVE_REQUESTS.STATUS.REJECTED")}
              </MenuItem>
              <MenuItem value={"Approved"}>
                {t("LEAVE_REQUESTS.STATUS.APPROVED")}
              </MenuItem>
            </CustomSelector>
            <CustomSelector
              label={t("TYPE")}
              value={type}
              onSelectChange={handleTypeChange}
              size="small"
              width="100%"
            >
              <MenuItem value={"ALL"}>{t("ALL")}</MenuItem>
              {Object.values(OutOfOffice).map((value, index: number) => {
                return (
                  <MenuItem key={index} value={value}>
                    {t(`LEAVE_REQUESTS.OUT_OF_OFFICE.${value?.replace(" ", "_").replace("/", "_").toUpperCase()}`)}
                  </MenuItem>
                );
              })}
            </CustomSelector>
            <Box
              sx={{
                textAlign: "center",
                padding: "8px",
                borderRadius: "5px",
                fontSize: "12px",
                color: GREEN_COLOR_ACCENT,
                width: "70%",
                fontWeight: 700,
                border: "1px solid",
                borderColor: GREEN_COLOR_ACCENT,
              }}
            >
              {t("TOTAL_VACATIONS").toUpperCase()}: {totalVacations}
            </Box>
            <CustomNavigationButton
              customStyle={{
                backgroundColor: BLUE_COLOR_ACCENT,
                width: "50%",
                display: "flex",
                gap: 1,
                alignSelf: "center",
                fontSize: "12px",
                ":hover": {
                  bgcolor: BLUE_HOVER_COLOR_ACCENT,
                  color: "white",
                },
              }}
              navigateTo="/add-leave"
            >
              {isMobile ? <AddIcon /> : t("ADD")}
            </CustomNavigationButton>
          </Box>
        </CustomHeader>
      )}
      {isSuccess ? (
        data?.data?.length > 0 ? (
          isMobile ? (
            <CustomUserLeavesTableMobile
              column1Name={t("DETAILS")}
              column2Name={t("LEAVES")}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              data={data?.data}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleRowPerPageChange}
              rowsPerPage={limit}
              count={result?.data?.totalRecords}
              page={page}
            />
          ) : (
            <CustomTable
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              tableBodyRenderer={leaveRequestsBodyRenderer}
              tableTitles={AllLeaveRequestsTableTitles}
              handleChangePage={handleChangePage}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              count={result?.data?.totalRecords}
              mt={2}
              style={{
                borderBottomLeftRadius:
                  result?.data?.totalRecords > 9 ? "0px" : "10px",
                borderBottomRightRadius:
                  result?.data?.totalRecords > 9 ? "0px" : "10px",
              }}
            />
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "50vh" : "75vh",
            }}
          >
            <NoDataComponent title={t("LEAVE_REQUESTS.NO_LEAVES")} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default UserLeaveRequest;
