import { Box, useTheme } from "@mui/material";
import { tokens } from "../core/AppStyles";

interface Props {
  height?: any;
}

const LoadingSpinner = ({ height }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: height || "70vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="loader-pulse">
        <img src={colors.anachitaImage["loading"]} alt="logo" />
      </div>
    </Box>
  );
};

export default LoadingSpinner;
