import { Box } from "@mui/material";

interface BoxContainerType {
  children: any;
  height?: string
}

function BoxContainer({ children, height = "100% auto" }: BoxContainerType) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: height,
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none"
        },
        margin: 0.5,
      }}
    >
      {children}
    </Box>
  );
}

export default BoxContainer;
