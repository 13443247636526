/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Route } from "react-router";
import ProtectedRoute from "../../shared/ProtectedRoute";
import Dashboard from "../../features/dashboard/Dashboard";
import TimeTracker from "../../features/time-tracker/TimeTracker";
import UserLeaveRequest from "../../features/leave-request/UserLeaveRequest";
import UserProfile from "../../features/employees/UserProfile";
import LeaveRequest from "../../features/leave-request/LeaveRequest";
import FileUploadForm from "../../features/file-manager/UploadFile";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { store } from "../../data/store/store";
import { appEndpoints } from "../../data/endpoints/app.endpoints";
import { UserRole } from "../../utils/enums/user-role.enum";
import PageNotFound from "../Errors/PageNotFound";
import UserWorkTimeActivity from "../../features/employees/UserWorkTimeActivity";
import FileSystem from "../../features/file-manager/FileSystem";

interface UseEmployeeRoutesProps {
  refreshToken: string;
}

const useEmployeeRoutes = ({ refreshToken }: UseEmployeeRoutesProps) => {
  const user = useSelector(selectLoggedUser);

  useEffect(() => {
    if (user?.userId && user?.role !== UserRole.ADMIN) {
      store.dispatch(
        appEndpoints.endpoints.getUserDashboard.initiate(user?.userId)
      );
    }
  }, [user?.userId]);

  return (
    <Route>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user?.userId}
            Component={Dashboard}
          />
        }
      />
      <Route
        path="/time-tracker"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={TimeTracker}
          />
        }
      />
      <Route
        path="/leave-request"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={UserLeaveRequest}
          />
        }
      />
      <Route
        path="/my-profile"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={UserProfile}
          />
        }
      />
      <Route
        path="/add-leave"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={LeaveRequest}
          />
        }
      />
      <Route
        path="/file-manager/upload"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={FileUploadForm}
          />
        }
      />
      <Route
        path="/file-manager/*"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={FileSystem}
          />
        }
      />
      <Route
        path="/my-work-time"
        element={
          <ProtectedRoute
            isUserLoggedIn={!!refreshToken}
            loadingSpinnerCondition={!user}
            Component={UserWorkTimeActivity}
          />
        }
      />
      <Route path="/*" element={<PageNotFound />} />
    </Route>
  );
};

export default useEmployeeRoutes;
