import { Box, useTheme } from "@mui/material";
import CustomFMActionButton from "./CustomFMActionButton";
import { Home, KeyboardBackspace, Sync } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";

interface ICustomFileManagerHeader {
  children?: any;
}

const CustomFileManagerHeader = ({ children }: ICustomFileManagerHeader) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: isMobile ? 1 : 2,
      }}
    >
      <CustomFMActionButton
        title={isMobile ? "" : "Home"}
        icon={<Home fontSize="small" sx={{ color: colors.invert }} />}
        onAction={() => navigate("/file-manager")}
      />
      <CustomFMActionButton
        icon={
          <KeyboardBackspace fontSize="small" sx={{ color: colors.invert }} />
        }
        onAction={() => navigate(-1)}
      />
      {!isMobile && (
        <CustomFMActionButton
          icon={<Sync fontSize="small" sx={{ color: colors.invert }} />}
          onAction={() => window?.location?.reload()}
        />
      )}

      {children}
    </Box>
  );
};

export default CustomFileManagerHeader;
