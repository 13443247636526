import {
  DialogContent,
  Dialog,
  DialogProps,
  Box,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { AddCircleOutlined } from "@mui/icons-material";
import { tokens } from "../../core/AppStyles";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/de";
interface ICustomTableMobileModal {
  dataInfo: any;
  header: string | undefined;
}

const TableDetailsMobileModal = ({ dataInfo }: ICustomTableMobileModal) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { i18n } = useTranslation();

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  });

  return (
    <div>
      <AddCircleOutlined
        onClick={handleClickOpen("paper")}
        sx={{
          color: colors.blueAccent[400],
          backgroundColor: colors.black,
          borderRadius: "50%",
        }}
      />

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent
          dividers={scroll === "paper"}
          sx={{
            width: "80vw",
            maxHeight: "65vh",
            display: "flex",
            flexDirection: "column",
            padding: "2vh 0 4vh 0",
            backgroundColor: colors.component,
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "5px",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              right: "10px",
              boxShadow: " rgba(0, 0, 0, 0.2) 1px 1px 5px 1px",
              fontWeight: 500,
            }}
            onClick={handleClose}
          >
            X
          </Box>
          <Box
            style={{
              width: "80%",
              height: "80px",
              padding: "5%",
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "1.7rem",
                fontWeight: "500",
                color: colors.invert,
              }}
            >
              {t("DETAILS")}
            </p>
          </Box>

          {dataInfo?.[0]?.data?.map((info: any, index: number) => {
            return (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  padding: "0 7% 0 7%",
                  height: "45px",
                  borderBottom: `1px solid ${colors.timeline}`,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: colors.invert,
                  fontSize: "15px",
                  gap: 2,
                }}
              >
                <span
                  style={{
                    width: "40%",
                    fontSize: `${info?.key?.length >= 10 ? "12px" : "15px"}`,
                    overflow: "hidden",
                  }}
                >
                  {info?.key ||
                    dayjs()
                      .locale(i18n.language)
                      .month(info?.month - 1)
                      .format("MMMM")}
                </span>
                <span
                  style={{
                    width: "50%",
                    fontSize: `${info?.value?.length >= 12 ? "12px" : "15px"}`,
                    overflow: "hidden",
                  }}
                >
                  {info?.value ||
                    info?.total_hours?.toFixed(2).replace(/(\.0+|0+)$/, "")}
                </span>
              </Box>
            );
          })}
          {dataInfo?.[0]?.actions && (
            <Box
              sx={{
                width: "100%",
                padding: "0 7% 0 7%",
                height: "45px",
                borderBottom: `1px solid ${colors.timeline}`,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                color: colors.invert,
                fontSize: "15px",
              }}
            >
              <p
                style={{
                  width: "35%",
                  margin: 0,
                }}
              >
                {t("ACTIONS")}
              </p>
              <Box
                sx={{
                  width: "40%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {dataInfo?.[0]?.actions?.map((action: any, index: number) => (
                  <span key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => navigate(action?.path)}
                    >
                      {action?.key}
                    </Box>
                  </span>
                ))}
                {dataInfo?.[0]?.functions &&
                  dataInfo?.[0]?.functions?.map((f: any, index: number) => (
                    <span key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginLeft: "20px",
                        }}
                        onClick={() => {
                          f?._date
                            ? f && f?.func(f?.id, f?._date)
                            : f.userId
                            ? f && f.func(f?.id, f?.userId)
                            : f && f?.func(f?.id);

                          setOpen(false);
                        }}
                      >
                        {f && f?.key}
                      </Box>
                    </span>
                  ))}
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TableDetailsMobileModal;
