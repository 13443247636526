import { Box, LinearProgress, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeader from "../../shared/custom/CustomHeader";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import ExampleBar from "./testDashboard/DashboardComponents/BarPlot-dashboard";
import SingleCardDashboard from "./testDashboard/DashboardComponents/SingleCard-Dashboard";
import FollowingVacationComp from "./testDashboard/DashboardComponents/FollowingVacationComp-Dashbaord";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router";
import {
  useLazyCheckTimeTrackerSubmittedQuery,
  useLazyGetUserDashboardQuery,
} from "../../data/endpoints/app.endpoints";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import ModalDashboardLegend from "./testDashboard/DashboardComponents/ModalDashboardLegend";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import UncompletTimeTrackerUser from "./testDashboard/DashboardComponents/UncompletTimeTrackerUser";
import { useTranslation } from "react-i18next";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { CheckCircleOutlineOutlined, ErrorOutline } from "@mui/icons-material";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();
  const loggedUser = useSelector(selectLoggedUser);
  const [timeTrackerSubmitted, result] =
    useLazyCheckTimeTrackerSubmittedQuery();
  const [getUserDashboardData, UserDashboardDataResut] =
    useLazyGetUserDashboardQuery();

  const [ShowUncompletedTT, setShowUncompletedTT] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    const lastDismissed: any = localStorage.getItem("lastDismissed");
    if (!lastDismissed || Date.now() - lastDismissed > 16 * 60 * 60 * 100) {
      setShowUncompletedTT(true);
    }
  }, []);

  const handleCloseUnCompletedTT = () => {
    setShowUncompletedTT(false);
    localStorage.setItem("lastDismissed", Date.now() as any);
  };

  useEffect(() => {
    getUserDashboardData(loggedUser?.userId);
  }, []);

  const TTresult: any = result?.data;

  useEffect(() => {
    if (loggedUser?.userId)
      timeTrackerSubmitted({
        date: moment().format("YYYY-MM-DD"),
        userId: loggedUser?.userId,
      });
  }, [loggedUser?.userId]);

  const navigateToTimeTracker = (e: any) => {
    e.preventDefault();
    navigate("/time-tracker");
  };
  let col = "";
  let backCol = "";
  loggedUser?.role === "Admin"
    ? (col = "black") && (backCol = "#EE9322")
    : (col = "#78D6C6") && (backCol = "#78D6C6");

  const handleUserRoleKeyTranslation = () => {
    if (loggedUser?.role === "Employee") return t("ROLES.EMPLOYEE");
  };
  return (
    <>
      {ShowUncompletedTT && (
        <UncompletTimeTrackerUser onClose={handleCloseUnCompletedTT} />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: `${isMobile || isTablet ? "100%" : "auto"}`,
        }}
      >
        <CustomHeader
          title={t("DASHBOARD.TITLE")}
          subtitle={`${t("DASHBOARD.SUBTITLE")}, ${
            UserDashboardDataResut?.data?.firstName
          }
          `}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "auto",
            padding: "1%",
            mt: "2%",
            width: `${isMobile || isTablet ? "80px" : "8vw"}`,
          }}
        >
          <p
            style={{
              padding: "2% 4%",
              borderRadius: "10px",
              margin: 0,
              color: `${col}`,
              border: `1px solid ${backCol}`,
              fontSize: isLargeScreen ? "1.1rem" : "auto",
            }}
          >
            {handleUserRoleKeyTranslation()}
          </p>
          {isMobile || isTablet ? "" : <ModalDashboardLegend />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "auto",
          gap: isMobile || isTablet ? 0 : 7,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
            justifyContent: "space-between",
            width: "100%",
            marginLeft: "0%",
            height: "auto",
          }}
        >
          <Box
            style={{
              width: `${isMobile || isTablet ? "100%" : "30%"}`,
              height: `${isMobile || isTablet ? "100%" : "21vh"}`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              // padding: "0 0 0 1%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "auto",
                padding: 1,
                background:
                  isMobile || isTablet ? colors.component : "transparent",
                borderRadius: "8px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: "5px 0 5px 0",
                  fontSize: isLargeScreen ? "1.2rem" : "auto",
                }}
              >
                {t("DASHBOARD.USER.MONTH_WORKED")}{" "}
                <b>
                  {UserDashboardDataResut.data?.paidHours?.toFixed(2) || 0} /
                  {UserDashboardDataResut.data?.expectedWorkingHours || 0}
                </b>{" "}
                {t("DASHBOARD.USER.MONTHLY_TOTAL_HOURS")}
              </p>
              <LinearProgress
                variant="determinate"
                value={
                  (UserDashboardDataResut.data?.paidHours /
                    UserDashboardDataResut.data?.expectedWorkingHours) *
                  100
                }
                color="inherit"
                sx={{
                  width: "100%",
                  height: `${isLargeScreen ? "12px" : "8px"}`,
                  borderRadius: "8px",
                  marginBottom: "1vh",
                  zIndex: 2,
                }}
              />
            </Box>
            {result.status === QueryStatus.fulfilled ? (
              <Box
                sx={{
                  width: "100%",
                  height: `${isMobile ? "32%" : isTablet ? "45%" : "32%"}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  background: colors.component,
                  boxShadow: " rgba(0, 0, 0, 0.04) 1px 1px 5px 1px",
                  padding: "4%",
                  cursor: "pointer",
                  marginTop: isMobile || isTablet ? "2vh" : "0vh",
                }}
              >
                <Box
                  sx={{
                    fontSize: isLargeScreen ? "1.1rem" : "auto",
                    width: "100%",
                  }}
                >
                  {TTresult?.completed ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1.5,
                      }}
                    >
                      <CheckCircleOutlineOutlined
                        color="success"
                        sx={{ fontSize: "23px" }}
                      />
                      <Box> {t("DASHBOARD.USER.TIME_TRACKER_COMPLETED")}</Box>
                    </Box>
                  ) : TTresult?.onLeave ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 1.5,
                      }}
                    >
                      <ErrorOutline color="warning" sx={{ fontSize: "23px" }} />
                      <Box>{t("DASHBOARD.USER.LEAVE_REQUEST_EXISTS")}</Box>
                    </Box>
                  ) : (
                    <Box
                      onClick={navigateToTimeTracker}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: 1.5,
                          alignItems: "center",
                        }}
                      >
                        <ErrorOutline
                          color="warning"
                          sx={{ fontSize: "23px" }}
                        />
                        <Box>
                          {t("DASHBOARD.USER.TIME_TRACKER_NOT_COMPLETED")}
                        </Box>
                      </Box>
                      <ArrowForwardIosIcon color="inherit" />
                    </Box>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: `${isMobile ? "32%" : isTablet ? "45%" : "34%"}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                  background: colors.component,
                  boxShadow: " rgba(0, 0, 0, 0.04) 1px 1px 5px 1px",
                  margin: "2% 0",
                  padding: "4%",
                  cursor: "pointer",
                  marginTop: isMobile ? 1 : 0,
                }}
              ></Box>
            )}
          </Box>

          <Box
            sx={{
              width: `${isMobile || isTablet ? "100%" : "32%"}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: isMobile ? 2 : 0,
              height: `${isMobile || isTablet ? "auto" : "21vh"}`,
              marginTop: `${isMobile || isTablet ? "2vh" : "0vh"}`,
            }}
          >
            {/* <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : "22"}
                  height={isLargeScreen ? "35" : "22"}
                  fill="#FF8042"
                  className="bi bi-clock"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
              }
              number={
                0 ||
                UserDashboardDataResut.data?.dailyworkingHours?.hours
                  ?.toFixed(2)
                  .replace(/(\.0+|0+)$/, "")
              }
              smallString={t("DASHBOARD.HOURS")}
              title={t("DASHBOARD.USER.WORKED_TODAY")}
            /> */}
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : "22"}
                  height={isLargeScreen ? "35" : "22"}
                  fill="#00C49F"
                  className="bi bi-stack"
                  viewBox="0 0 16 16"
                >
                  <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z" />
                  <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z" />
                </svg>
              }
              number={
                (loggedUser?.totalOvertimes / 60)
                  ?.toFixed(2)
                  .replace(".00", "") || "0"
              }
              smallString={t("DASHBOARD.HOURS")}
              title={t("DASHBOARD.USER.TOTAL_OVERTIME")}
            />
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : "22"}
                  height={isLargeScreen ? "35" : "22"}
                  fill="#0088FE"
                  className="bi bi-airplane"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Zm.894.448C7.111 2.02 7 2.569 7 3v4a.5.5 0 0 1-.276.447l-5.448 2.724a.5.5 0 0 0-.276.447v.792l5.418-.903a.5.5 0 0 1 .575.41l.5 3a.5.5 0 0 1-.14.437L6.708 15h2.586l-.647-.646a.5.5 0 0 1-.14-.436l.5-3a.5.5 0 0 1 .576-.411L15 11.41v-.792a.5.5 0 0 0-.276-.447L9.276 7.447A.5.5 0 0 1 9 7V3c0-.432-.11-.979-.322-1.401C8.458 1.159 8.213 1 8 1c-.213 0-.458.158-.678.599Z" />
                </svg>
              }
              number={
                UserDashboardDataResut.data?.totalLeavesDaysRemaining
                  .toFixed(2)
                  .replace(".00", "") || 0
              }
              smallString={t("DASHBOARD.DAYS")}
              title={t("DASHBOARD.USER.VACATIONS_LEFT")}
            />
          </Box>

          <FollowingVacationComp
            data={UserDashboardDataResut.data?.upcomingHolidays}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
            justifyContent: "space-between",
            alignItems: isLargeScreen
              ? "flex-start"
              : isDesktop
              ? "flex-start"
              : "center",
            width: "100%",
            height: "auto",
          }}
        >
          <Box
            sx={{
              width: `${isMobile || isTablet ? "100%" : "30%"}`,

              height: "42vh",
              overflow: "hidden",
              marginLeft: 0,
              background: colors.component,
              boxShadow: " rgba(0, 0, 0, 0.08) 1px 1px 5px 1px",
              padding: "1%",
              borderRadius: "10px",
              marginTop: `${isMobile || isTablet ? "2vh" : "0"}`,
            }}
          >
            <p
              style={{
                margin: "1vh 0 0 1vh",
              }}
            >
              <b style={{ fontSize: `${isLargeScreen ? "1.1rem" : "16px"}` }}>
                {t("DASHBOARD.USER.WORK_INTERVAL_TEXT")}
              </b>
            </p>

            <ExampleBar userId={loggedUser?.userId} />
          </Box>

          <Box
            sx={{
              width: "30%",
              height: "42vh",
              display: `${isMobile || isTablet ? "none" : "flex"}`,
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
