import moment from "moment";

export const checkTimeIntervalToDisplayInvalid = (
  startTime: string,
  endTime: string
) => {
  const startTimeMoment = moment(startTime);
  const endTimeMoment = moment(endTime);

  return startTime === "" ||
    endTime === "" ||
    !(startTimeMoment.isValid() && endTimeMoment.isValid()) ||
    !startTimeMoment.isBefore(endTimeMoment)
    ? "-:-"
    : moment(endTimeMoment.diff(startTimeMoment)).utc().format("HH:mm");
};

export function loopThroughDates(startDate: Date, endDate: Date) {
  const dates: string[] = [];
  const currentDate = moment(startDate);
  const lastDate = moment(endDate);

  while (currentDate.isSameOrBefore(lastDate)) {
    dates.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return dates;
}
