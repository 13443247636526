/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  useLazyGetAllFilesByUserIdQuery,
  useLazyGetAllFilesQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import { selectLoggedUser } from "../../data/store/authSlice";
import MainFolders from "./MainFolders";
import { Route, Routes } from "react-router";
import FilesContainer from "./FilesContainer";
import { tokens } from "../../core/AppStyles";
import UsersFolders from "./UsersFolders";

const FileSystem = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [getAllFiles, allFilesResult] = useLazyGetAllFilesQuery();
  const user = useSelector(selectLoggedUser);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [type, setType] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);

  const [getFilebyUserId, filesByUserIdResult] =
    useLazyGetAllFilesByUserIdQuery();

  useEffect(() => {
    if (allFilesResult?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }

    if (filesByUserIdResult?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
  }, [allFilesResult?.data, filesByUserIdResult?.data]);

  useEffect(() => {
    if (user?.role === "Admin") {
      getAllFiles({
        currentPage: page,
        limitTo: limit,
        q,
        type: type === "ALL" ? "" : type,
      });
    } else if (user?.role === "Employee") {
      getFilebyUserId({
        userId: user?.userId,
        currentPage: page,
        limitTo: limit,
        q,
        type: type === "ALL" ? "" : type,
      });
    }
  }, [q, page, limit, type]);

  return (
    <div>
      <CustomHeader title="File Manager" subtitle="Store and manage files" />
      <Box
        sx={{
          width: "100%",
          height: "75vh",
          overflow: "hidden",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "1vh",
        }}
      >
        {user && user?.role === "Employee" ? (
          <Routes>
            <Route path="" Component={MainFolders} />
            <Route path=":type" Component={FilesContainer} />
          </Routes>
        ) : (
          <Routes>
            <Route path="" Component={MainFolders} />
            <Route path=":type" Component={UsersFolders} />
            <Route path=":type/:userId" Component={FilesContainer} />
          </Routes>
        )}
      </Box>
    </div>
  );
};

export default FileSystem;
