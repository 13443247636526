import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../core/AppStyles";
import { useNavigate } from "react-router";
import useIsMobile from "../../../../shared/hooks/useIsMobile";
import { themeSettings } from "../../../../core/AppStyles";
import { PROGRESSBAR_ORANGE } from "../../../../utils/constants/colors";
import useIsLargeScreen from "../../../../shared/hooks/useIsLargeScreen";
import { useTranslation } from "react-i18next";
import moment from "moment";

const LeaveRequestNotifyComponent = ({ leavesPending }: any) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const color = themeSettings(theme.palette.mode);
  const isMobile = useIsMobile();
  const IsLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pendingLeaves = leavesPending?.length;

  const navigateToLeaves = () => {
    navigate("/leave-requests/Pending");
  };

  return (
    <>
      <Box
        style={{
          width: "100%",
          height: "100%",
          boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
          background: colors.component,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: `${isMobile ? "4%" : "1%"}`,
          marginTop: `${isMobile ? "2vh" : "0"}`,
          borderRadius: "10px",
        }}
      >
        {pendingLeaves > 0 ? (
          <>
            <b
              style={{
                fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1.1rem" : "auto"
                  }`,
                width: "100%",
                textAlign: "start",
                padding: "1vh 1vw",
              }}
            >
              <i>{pendingLeaves}</i> {t("DASHBOARD.ADMIN.PENDING_LEAVES")}
            </b>
            {isMobile ? <br /> : " "}
            <TableContainer
              sx={{
                borderRadius: "8px",
                scrollbarWidth: "thin",
                scrollbarColor: `#0d1321 transparent`,
              }}
            >
              <Table aria-label="simple table">
                <TableHead
                  sx={{
                    backgroundColor: color.palette.background.default,
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        border: "none",
                        fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                          }`,
                      }}
                      align="left"
                    >
                      {t("NAME")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                          }`,
                        border: "none",
                      }}
                    >
                      {t("STATUS")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                          }`,
                        border: "none",
                      }}
                    >
                      {t("START_DATE")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                          }`,
                        border: "none",
                      }}
                    >
                      {t("END_DATE")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leavesPending?.map((row: any, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ " &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                            }`,
                        }}
                      >
                        {isMobile
                          ? row?.user?.firstName + "." + row?.user.lastName[0]
                          : row?.user?.firstName + " " + row?.user?.lastName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                            }`,
                        }}
                      >
                        <p
                          style={{
                            padding: 4,
                            width: "max-content",
                            color: PROGRESSBAR_ORANGE,
                            backgroundColor: colors.orangeBackground,
                            borderRadius: "6px",
                          }}
                        >
                          {t(
                            `LEAVE_REQUESTS.STATUS.${row?.status?.toUpperCase()}`
                          )}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                            }`,
                        }}
                      >
                        {moment(row.startDate).format("DD-MMM-YYYY")}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                            }`,
                        }}
                      >
                        {moment(row.endDate).format("DD-MMM-YYYY")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <small
              style={{
                width: "100%",
                textAlign: "end",
                color: colors.blueAccent[400],
                cursor: "pointer",
                marginTop: "1vh",
                fontSize: `${isMobile ? "9px" : IsLargeScreen ? "1rem" : "auto"
                  }`,
              }}
              onClick={navigateToLeaves}
            >
              {t("DASHBOARD.ADMIN.VIEW_ALL_LEAVES")}
            </small>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: isMobile ? 12 : 15,
            }}
          >
            {t("LEAVE_REQUESTS.NO_PENDING_LEAVES")}
          </Box>
        )}
      </Box>
    </>
  );
};

export default LeaveRequestNotifyComponent;
