import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { AuthTokens } from "../models/auth-tokens.interface";
import { LoginData } from "../models/login-data.interface";
import { User } from "../models/user.interface";
import { RootState } from "../store/store";
import { Mutex } from "async-mutex";
import { resetAuthState, setTokens } from "../store/authSlice";
import { resetHomeState } from "../store/homeSlice";
import { resetEmployeeState } from "../store/employeeSlice";
import { PaginatedResponse } from "../models/paginated-response.interface";
import { ActivityResponsePaginated } from "../models/activity-response-paginated.interface";
import { Holiday } from "../models/holiday.interface";
import { LeaveRequest } from "../models/leave-request.interface";
import { TimeTracker } from "../models/time-tracker.interface";
import { resetHolidaysState } from "../store/holidaysSlice";
import { UserStatus } from "../../utils/enums/user-status.enum";
import { UserRole } from "../../utils/enums/user-role.enum";
import { Vehicle } from "../models/vehicle.interface";

import { VehicleStatus } from "../../utils/enums/vehicle-status.enum";

const mutex = new Mutex();

interface CheckTimeTrackerRequest {
  date: string;
  userId: string;
}

interface GetTimeTrackersRequest {
  startDate: string;
  endDate: string;
  userId: string;
  limitTo?: number;
  q?: any;
}

interface GetEmployeeTimeTrackersRequest {
  startDate: string;
  endDate: string;
  currentPage: number;
  limitTo?: number;
  q?: any;
}

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "api/"
    : process.env.REACT_APP_BASE_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.tokens?.accessToken;

    if (accessToken && headers.get("authorization") === null) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 403) {
    // try to get a new token
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshToken = (api.getState() as RootState).auth.tokens
          ?.refreshToken;
        const refreshResult = await baseQuery(
          {
            url: "/refresh-token",

            headers: { Authorization: `Bearer ${refreshToken}` },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          api.dispatch(setTokens(refreshResult.data));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(appEndpoints.util.resetApiState());
          api.dispatch(resetAuthState());
          api.dispatch(resetHomeState());
          api.dispatch(resetEmployeeState());
          api.dispatch(resetHolidaysState());
          localStorage.removeItem("anachita-ems.refresh-token");
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const appEndpoints = createApi({
  reducerPath: "appEndpoints",
  tagTypes: [
    "User",
    "LoggedUser",
    "Token",
    "Vehicle",
    "Tracker",
    "Leaves",
    "Holidays",
    "UserDashboard",
    "UserWeeklyHours",
    "AdminDashboard",
    "FileManager",
    "FileManagerUpload",
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPaginatedUsers: builder.query<
      PaginatedResponse<User>,
      {
        limitTo: number;
        currentPage: number;
        q?: string;
        status?: UserStatus;
        role?: UserRole;
      }
    >({
      query: (options) => ({
        url: "/users",
        params: options,
      }),
      providesTags: ["User"],
    }),
    getAllUsers: builder.query<any, any>({
      query: () => "/all-users",
      providesTags: ["User"],
    }),
    getUserById: builder.query<User, string>({
      query: (id: string) => `/user/${id}`,
      providesTags: ["LoggedUser"],
    }),
    login: builder.query<AuthTokens, LoginData>({
      query: (loginData: LoginData) => ({
        url: "/login",
        method: "POST",
        body: loginData,
      }),
      providesTags: ["Token"],
    }),
    refreshToken: builder.query<AuthTokens, string>({
      query: (refreshToken: string) => ({
        url: "/refresh-token",
        method: "GET",
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }),
      providesTags: ["Token"],
    }),
    createUser: builder.mutation<User, Partial<User>>({
      query: (user: Partial<User>) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation<User, User>({
      query: (user: User) => ({
        url: `/user/update/${user.userId}`,
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),
    changeUserStatus: builder.mutation({
      query: ({ id, endOfContract, fullName }) => ({
        url: `/user/status/${id}`,
        method: "PATCH",
        body: { endOfContract, fullName },
      }),
      invalidatesTags: ["User"],
    }),
    changeUserPassword: builder.mutation<any, any>({
      query: ({ id, password, fullName }) => ({
        url: `/admin/password/${id}`,
        method: "PATCH",
        body: { password, fullName },
      }),
      invalidatesTags: ["User"],
    }),
    getUserLogs: builder.query<any, any>({
      query: (id) => ({
        url: `/logs/user/${id}`,
      }),
      providesTags: ["User"],
    }),
    userCompensation: builder.mutation<any, any>({
      query: (options) => ({
        url: `/logs`,
        method: "POST",
        body: options,
      }),
      invalidatesTags: ["User"],
    }),

    checkUsersWithUncomletedTT: builder.query<any, any>({
      query: (options) => ({
        url: `/timetracker/users/check-incomplete-admin`,
        params: { startDate: options?.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    checkUncompleatedTTById: builder.query<any, any>({
      query: (options) => ({
        url: `/timetracker/users/check-incomplete/${options.userId}`,
        params: { startDate: options?.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),

    logout: builder.query({
      query: () => ({
        url: "/logout",
        method: "DELETE",
      }),
    }),
    addTime: builder.mutation<any, any>({
      query: (Timetracker: any) => ({
        url: "/timetracker",
        method: "POST",
        body: Timetracker,
      }),
      invalidatesTags: ["Tracker", "User"],
    }),
    createSingleTimeTracker: builder.mutation<TimeTracker, any>({
      query: (timeTrackerData: { userId: string; timeTracker: any }) => ({
        url: "/single/time-tracker",
        method: "POST",
        body: timeTrackerData,
      }),
      invalidatesTags: ["Tracker", "User"],
    }),
    getMultipleTimeTrackersByInterval: builder.query<
      any,
      {
        startDate: string;
        endDate: string;
        currentPage: number;
        limitTo: number;
        q?: any;
      }
    >({
      query: (timeTrackerInterval) => ({
        url: "/multiple/time-tracker",
        method: "GET",
        params: {
          startDate: timeTrackerInterval.startDate,
          endDate: timeTrackerInterval.endDate,
          currentPage: timeTrackerInterval.currentPage,
          limitTo: timeTrackerInterval.limitTo,
          q: timeTrackerInterval.q,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getTimeTrackers: builder.query<any, GetTimeTrackersRequest>({
      query: (options) => ({
        url: `/timetracker/users/single-user/${options?.userId}`,
        params: {
          startDate: options?.startDate,
          endDate: options?.endDate,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getTimeTrackersForEmployees: builder.query<
      ActivityResponsePaginated,
      GetEmployeeTimeTrackersRequest
    >({
      query: (options) => ({
        url: "/timetracker/users/all-employees",
        params: options,
      }),
      providesTags: ["Tracker"],
    }),
    getMultipleTimeTrackersByIntervalUser: builder.query<any, any>({
      query: (options) => ({
        url: `/multiple/time-tracker/${options?.userId}`,
        params: {
          startDate: options?.startDate,
          endDate: options?.endDate,
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
        },
      }),
      providesTags: ["Tracker"],
    }),
    getDailyTimeTracker: builder.query<any, { date: string; userId: string }>({
      query: (options) => ({
        url: `/daily-timetracker/user/${options.userId}`,
        params: { date: options.date },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    checkTimeTrackerSubmitted: builder.query<void, CheckTimeTrackerRequest>({
      query: (options) => ({
        url: `/timetracker/check-timetracker/${options.userId}`,
        params: { date: options?.date },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    updateSingleTimeTracker: builder.mutation<any, Partial<TimeTracker>>({
      query: (options) => ({
        url: `/timetracker/update/${options.timeTrackerId}`,
        method: "PATCH",
        body: {
          startTime: options?.startTime,
          endTime: options?.endTime,
          description: options?.description,
          vehicleId: options?.vehicleId,
        },
      }),
      invalidatesTags: ["Tracker", "User"],
    }),
    deleteOneTimeTracker: builder.mutation<TimeTracker, any>({
      query: (timeTrackerId) => ({
        url: `/timetracker/delete/${timeTrackerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tracker", "User"],
    }),
    deleteDailyTimeTracker: builder.mutation<
      any,
      { userId: string; date: string }
    >({
      query: (timeTrackersToDelete) => ({
        url: `/timetracker/delete-by-date/${timeTrackersToDelete.userId}`,
        method: "DELETE",
        params: { date: timeTrackersToDelete.date },
      }),
      invalidatesTags: ["Tracker"],
    }),
    getPaginatedVehicles: builder.query<
      PaginatedResponse<Vehicle>,
      {
        limitTo: number;
        currentPage: number;
        status?: VehicleStatus;
        q?: any;
      }
    >({
      query: (options) => ({
        url: "vehicles",
        params: options,
      }),
      providesTags: ["Vehicle"],
    }),
    getVehicleById: builder.query<Vehicle[], string>({
      query: (id: string) => `/vehicle/${id}`,
      providesTags: ["Vehicle"],
    }),
    createVehicle: builder.mutation<Vehicle, Partial<Vehicle>>({
      query: (vehicle: Partial<Vehicle>) => ({
        url: "/vehicle",
        method: "POST",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    updateVehicle: builder.mutation<Vehicle, Vehicle>({
      query: (vehicle: Vehicle) => ({
        url: `/vehicle/update/${vehicle?.vehicleId}`,
        method: "PUT",
        body: vehicle,
      }),
      invalidatesTags: ["Vehicle"],
    }),
    getAllVehicles: builder.query<any, any>({
      query: () => ({
        url: "/all-vehicles",
        method: "GET",
      }),
      providesTags: ["Vehicle"],
    }),
    getEmployeesHoursInVehicle: builder.query<any, any>({
      query: (options) => ({
        url: `/employees-on-vehicle/${options?.vehicleId}`,
        params: { startDate: options.startDate, endDate: options.endDate },
        method: "GET",
      }),
      providesTags: ["Tracker"],
    }),
    getAllLeaves: builder.query<any, any>({
      query: (options) => ({
        url: `/leaves-paginated`,
        method: "GET",
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          status: options?.status,
          type: options?.type,
        },
      }),
      providesTags: ["Leaves"],
    }),

    getCalendarLeaves: builder.query<any, any>({
      query: (options) => ({
        url: `/calendar-leaves/${options.startDate}/${options.endDate}`,
        method: "GET",
      }),
      providesTags: ["Leaves"],
    }),

    getLeavesDashboard: builder.query<any, any>({
      query: () => ({
        url: `/leaves`,
      }),
      providesTags: ["Leaves"],
    }),

    getLeavesByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/leaves/user/${options.id}`,
        method: "GET",
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          status: options?.status,
          type: options?.type,
        },
      }),

      providesTags: ["Leaves"],
    }),
    sendLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: "/leaves",
        method: "POST",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    updateLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: `/leaves/update/${leaveRequest?.leaveId}`,
        method: "PATCH",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    changeStatusLeaveRequest: builder.mutation<LeaveRequest, any>({
      query: (leaveRequest) => ({
        url: `/leaves/change-status/${leaveRequest?.leaveId}`,
        method: "PATCH",
        body: leaveRequest,
      }),
      invalidatesTags: ["Leaves"],
    }),
    deleteLeaveRequest: builder.mutation({
      query: (leaveRequest) => ({
        url: `/leaves/delete/${leaveRequest}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Leaves"],
    }),
    getHolidays: builder.query<Holiday[], any>({
      query: (options) => ({
        url: `/holidays/interval/`,
        params: { startDate: options?.startDate, endDate: options?.endDate },
        method: "GET",
      }),
      providesTags: ["Holidays"],
    }),
    addHoliday: builder.mutation<any, any>({
      query: (options) => ({
        url: `/holidays`,
        method: "POST",
        body: options,
      }),
      invalidatesTags: ["Holidays"],
    }),
    updateHoliday: builder.mutation<any, any>({
      query: ({ holidayId, name, date }) => ({
        url: `/holiday/update/${holidayId}`,
        method: "PUT",
        body: { name, date },
      }),
      invalidatesTags: ["Holidays"],
    }),
    deleteHoliday: builder.mutation<any, any>({
      query: (holiday) => ({
        url: `/holiday/delete/${holiday}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Holidays"],
    }),

    getUserDashboard: builder.query<any, any>({
      query: (userId) => ({
        url: `/employee-dashboard/${userId}`,
        method: "GET",
      }),
      providesTags: ["UserDashboard"],
    }),
    getUserWeeklyWorkingHours: builder.query<any, any>({
      query: (userId) => ({
        url: `/employee-dashboard-weeks/${userId}`,
        method: "GET",
      }),
      providesTags: ["UserWeeklyHours"],
    }),
    getAdminDashboard: builder.query<any, any>({
      query: () => ({
        url: `/admin-dashboard`,
        method: "GET",
      }),
      providesTags: ["AdminDashboard"],
    }),
    getAllFiles: builder.query<any, any>({
      query: (options) => ({
        url: `/files`,
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          type: options?.type,
        },
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),
    getAllFilesByUserId: builder.query<any, any>({
      query: (options) => ({
        url: `/files/${options.userId}`,
        params: {
          currentPage: options?.currentPage,
          limitTo: options?.limitTo,
          q: options?.q,
          type: options?.type,
        },
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),
    getAllFilesByfileId: builder.query<any, any>({
      query: (options) => ({
        url: `/file/${options.userId}`,
        method: "GET",
      }),
      providesTags: ["FileManager"],
    }),

    downloadFileByUserId: builder.query<any, any>({
      query: (object) => ({
        url: `/download/${object.fileId}`,
        method: "GET",
        responseHandler: async (response) => {
          const file = await response.blob();

          // const { file, data } = response;
          const downloadUrl = file ? URL.createObjectURL(file) : null;

          return { downloadUrl };
        },
      }),
      providesTags: ["FileManager"],
    }),
    deleteFilePondFiles: builder.mutation<any, any>({
      query: (file) => ({
        url: `/files/${file?.fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FileManager"],
    }),
    uploadFile: builder.mutation<any, any>({
      query: ({ userId, type }) => ({
        url: `/upload`,
        method: "POST",
        body: { userId, type },
      }),
      invalidatesTags: ["FileManagerUpload"],
    }),
  }),
});

export const {
  useLazyGetPaginatedUsersQuery,
  useLazyGetAllUsersQuery,
  useLazyGetUserByIdQuery,
  useLazyLoginQuery,
  useLazyRefreshTokenQuery,
  useCreateUserMutation,
  useChangeUserStatusMutation,
  useUpdateUserMutation,
  useChangeUserPasswordMutation,
  useLazyLogoutQuery,
  useAddTimeMutation,
  useCreateSingleTimeTrackerMutation,
  useDeleteOneTimeTrackerMutation,
  useSendLeaveRequestMutation,
  useLazyGetTimeTrackersQuery,
  useLazyGetTimeTrackersForEmployeesQuery,
  useLazyCheckTimeTrackerSubmittedQuery,
  useLazyGetDailyTimeTrackerQuery,
  useUpdateSingleTimeTrackerMutation,
  useDeleteDailyTimeTrackerMutation,
  useLazyGetHolidaysQuery,
  useAddHolidayMutation,
  useUpdateHolidayMutation,
  useDeleteHolidayMutation,
  useLazyGetAllLeavesQuery,
  useLazyGetLeavesDashboardQuery,
  useLazyGetCalendarLeavesQuery,
  useUpdateLeaveRequestMutation,
  useDeleteLeaveRequestMutation,
  useLazyGetLeavesByUserIdQuery,
  useChangeStatusLeaveRequestMutation,
  useUserCompensationMutation,
  useLazyGetMultipleTimeTrackersByIntervalQuery,
  useLazyGetMultipleTimeTrackersByIntervalUserQuery,
  useLazyGetUserDashboardQuery,
  useLazyGetUserWeeklyWorkingHoursQuery,
  useLazyGetAdminDashboardQuery,
  useLazyGetUserLogsQuery,
  useLazyCheckUncompleatedTTByIdQuery,
  useLazyCheckUsersWithUncomletedTTQuery,
  useCreateVehicleMutation,
  useUpdateVehicleMutation,
  useLazyGetAllVehiclesQuery,
  useLazyGetVehicleByIdQuery,
  useLazyGetPaginatedVehiclesQuery,
  useLazyGetEmployeesHoursInVehicleQuery,
  useLazyGetAllFilesQuery,
  useLazyDownloadFileByUserIdQuery,
  useDeleteFilePondFilesMutation,
  useUploadFileMutation,
  useLazyGetAllFilesByUserIdQuery
} = appEndpoints;
