import { useEffect } from "react";
import "./App.css";
import Login from "./features/login/Login";
import { useSelector } from "react-redux";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { store } from "./data/store/store";
import {
  resetAuthState,
  selectLoggedUser,
  selectTokens,
} from "./data/store/authSlice";
import {
  appEndpoints,
  useLazyGetUserByIdQuery,
  useLazyRefreshTokenQuery,
} from "./data/endpoints/app.endpoints";
import { decodeToken } from "react-jwt";
import { DecodedJwt } from "./data/models/decoded-jwt.interface";
import useValidatedRoutes from "./core/hooks/useValidatedRoutes";
import PageNotFound from "./core/Errors/PageNotFound";
import { resetHomeState } from "./data/store/homeSlice";
import { resetEmployeeState } from "./data/store/employeeSlice";
import { resetHolidaysState } from "./data/store/holidaysSlice";

const App = () => {
  const tokens = useSelector(selectTokens);

  const refreshToken =
    JSON.parse(localStorage.getItem("anachita-ems.refresh-token") as string) ??
    tokens?.refreshToken;

  const user = useSelector(selectLoggedUser);

  const [getTokens] = useLazyRefreshTokenQuery();
  const [getUserData] = useLazyGetUserByIdQuery();
  const routes = useValidatedRoutes({ refreshToken });

  useEffect(() => {
    if (refreshToken) {
      getTokens(refreshToken)
        .then((res: any) => {
          if ("data" in res) {
            const decoded = decodeToken<DecodedJwt>(res.data.accessToken);
            if (decoded) {
              getUserData(decoded.sub).then((response: any) => {
                if (response.data) {
                  localStorage.setItem(
                    "anachita-ems.refresh-token",
                    JSON.stringify(res.data?.refreshToken)
                  );
                }
              });
            }
          }
          //  else if ("error" in res) {
          //   store.dispatch(appEndpoints.util.resetApiState());
          //   store.dispatch(resetAuthState());
          //   store.dispatch(resetHomeState());
          //   store.dispatch(resetEmployeeState());
          //   store.dispatch(resetHolidaysState());
          //   localStorage.removeItem("anachita-ems.refresh-token");
          // }
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken]);

  useEffect(() => {
    if (user?.userId) {
      store.dispatch(
        appEndpoints.endpoints.getUserWeeklyWorkingHours.initiate(user?.userId)
      );
      store.dispatch(
        appEndpoints.endpoints.getPaginatedVehicles.initiate({
          currentPage: 1,
          limitTo: 10,
        })
      );
      store.dispatch(appEndpoints.endpoints.getAllVehicles.initiate(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId]);

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/login"
          element={!!refreshToken ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route path="/" element={<Navigate to="/login" />} />
        {routes}
        {!refreshToken && <Route path="/*" element={<PageNotFound />} />}
      </Routes>
    </HashRouter>
  );
};

export default App;
