const RED_COLOR_ACCENT = "#EF6262";
const GRAY_COLOR_PRIMARY = "#dbdbdb";
const GRAY_COLOR_SECONDARY = "#f5f3f3";
const PROJECT_COMPLETED_COLOR = "#22A699";
const PROJECT_PENDING_COLOR = "#F3AA60";
const PROJECT_INPROGRESS_COLOR = "#3E6D9C";
const GRAY_COLOR_LINK = "#383838";
const GRAY_DASHBOARD_BACKGROUND = "#D9D9D9";
const BLUE_COLOR_ACCENT = "#257bfa";
const BLUE_HOVER_COLOR_ACCENT = "#1d61c6";
const GREEN_COLOR_ACCENT = "#22A699";
const GREEN_HOVER_COLOR_ACCENT = "#22A699";
const RED_HOVER_COLOR_ACCENT = "#EF6262";
const STATUS_PENDING_COLOR = "#F3AA60";
const PROGRESSBAR_RED = "#EF6262";
const PROGRESSBAR_COMPLETED = "#4169e1";
const PROGRESSBAR_ORANGE = "#F3AA60";
const PROGRESSBAR_GREEN = "#22A699";

export {
  RED_COLOR_ACCENT,
  GRAY_COLOR_PRIMARY,
  GRAY_COLOR_SECONDARY,
  PROJECT_COMPLETED_COLOR,
  PROJECT_PENDING_COLOR,
  PROJECT_INPROGRESS_COLOR,
  GRAY_COLOR_LINK,
  GRAY_DASHBOARD_BACKGROUND,
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  GREEN_HOVER_COLOR_ACCENT,
  GREEN_COLOR_ACCENT,
  RED_HOVER_COLOR_ACCENT,
  STATUS_PENDING_COLOR,
  PROGRESSBAR_RED,
  PROGRESSBAR_GREEN,
  PROGRESSBAR_ORANGE,
  PROGRESSBAR_COMPLETED,
};
