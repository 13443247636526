/* eslint-disable react-hooks/exhaustive-deps */
import { Box, TableCell, TableRow } from "@mui/material";
import { useLazyGetAdminDashboardQuery } from "../../../../data/endpoints/app.endpoints";
import { useEffect, useMemo } from "react";
import CustomHeader from "../../../../shared/custom/CustomHeader";
import CustomTable from "../../../../shared/custom/CustomTable";
import NoDataComponent from "../../../../shared/NoDataComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";
const Absents = () => {
  const { t } = useTranslation();
  const [adminDashboard, adminDashboardResult] =
    useLazyGetAdminDashboardQuery();

  useEffect(() => {
    adminDashboard(undefined);
  }, []);

  const absents = adminDashboardResult?.data?.allAbsents;

  const absentsTableTitles = [
    "No.",
    `${t("FULL_NAME")}`,
    `${t("ABSENCE.ABSENT_TYPE")}`,
    `${t("END_DATE")}`,
  ];

  const absentsBodyRenderer = useMemo(() => {
    const dataRenderer: JSX.Element[] = [];

    absents?.map((row: any, index: any) => {
      return dataRenderer.push(
        <TableRow
          key={index}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>

          <TableCell component="th" scope="row">
            {row?.user?.firstName + " " + row?.user?.lastName}
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.type}
          </TableCell>
          <TableCell component="th" scope="row">
            {moment(row?.end_date).format("DD MMM YYYY")}
          </TableCell>
        </TableRow>
      );
    });
    return dataRenderer;
  }, [absents]);

  return (
    <Box
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <CustomHeader
          title={t("ABSENCE.TITLE")}
          subtitle={t("ABSENCE.SUBTITLE")}
        />
      </Box>
      <Box
        style={{
          width: "100%",
          height: "90%",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        {absents?.length !== 0 ? (
          <CustomTable
            isPaginated={false}
            mt={2}
            count={absents}
            tableBodyRenderer={absentsBodyRenderer}
            tableTitles={absentsTableTitles}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NoDataComponent title={t("ABSENCE.NO_ABSENT_TODAY")} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Absents;
