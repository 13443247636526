import { useContext } from "react";
import { Formik } from "formik";

import {
  requiredTimeInterval,
  timeBetweenInterval,
  timeBetweenMinutes,
  timeIntervalEndTimeSmaller,
  timeTrackerFieldArrayValidator,
} from "../../utils/regex/formRegex";

import TimeTrackerFormFields from "./TimeTrackerFormFields";
import moment from "moment";
import { RootContext } from "../../context/RootContext";
import { TimeTrackerType } from "../../utils/enums/timetracker-type.enum";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import { selectLoggedUser } from "../../data/store/authSlice";
import { useTranslation } from "react-i18next";

interface TimeTrackerFormikProps {
  userId?: string;
  userDate?: string;
}

const TimeTrackerFormik = ({ userDate, userId }: TimeTrackerFormikProps) => {
  const { setOpenModal } = useContext(RootContext);
  const user = useSelector(selectLoggedUser);
  const selectedUser = useSelector(selectEmployeeById(userId));
  const { t } = useTranslation();
  const initialValues = {
    travelTime: [],
    lunchTime: {
      type: TimeTrackerType.LUNCH_TIME,
      startTime: "",
      endTime: "",
      date: userDate ? userDate : moment().format("YYYY-MM-DD"),
      userId: selectedUser ? selectedUser.userId : user.userId,
    },
    optionalBreak: [],
    worked: {
      type: TimeTrackerType.WORK,
      startTime: "",
      endTime: "",
      vehicleId: "",
      date: userDate ? userDate : moment().format("YYYY-MM-DD"),
      userId: selectedUser ? selectedUser.userId : user.userId,
      description: "",
    },
  };

  const onFormSubmit = () => {
    setOpenModal(true);
  };

  const validate = (values: typeof initialValues) => {
    const errors = {} as any;

    const startTimeInside = timeBetweenInterval(
      moment(values?.lunchTime?.startTime),
      {
        startTime: moment(values?.worked?.startTime),
        endTime: moment(values?.worked?.endTime),
      }
    );

    const endTimeInside = timeBetweenInterval(
      moment(values?.lunchTime?.endTime),
      {
        startTime: moment(values?.worked?.startTime),
        endTime: moment(values?.worked?.endTime),
      }
    );

    if (
      (!startTimeInside || !endTimeInside) &&
      values?.worked?.startTime &&
      values?.worked?.endTime
    ) {
      errors["lunchTime"] = t("TIME_TRACKER.LUNCH_TIME_OUTSIDE");
    }
    timeBetweenMinutes(
      errors,
      "lunchTime",
      values.lunchTime,
      30,
      60,
      t("FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.LUNCH_TIME_INTERVAL"),
      {
        startTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
        endTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
      },
      t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL")
    );

    if (!values?.worked?.vehicleId) {
      errors["worked"] = t("TIME_TRACKER.VEHICLE_NOT_SELECTED");
    }

    requiredTimeInterval(
      errors,
      "lunchTime",
      values.lunchTime,
      t("FORM_VALIDATIONS.TIMES_REQUIRED")
    );
    requiredTimeInterval(
      errors,
      "worked",
      values.worked,
      t("FORM_VALIDATIONS.TIMES_REQUIRED")
    );
    timeTrackerFieldArrayValidator(
      values,
      errors,
      "optionalBreak",
      {
        startTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
        endTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_TIME"
        ),
      },
      t("FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.SELECT_PROJECT"),
      t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL"),
      t("FORM_VALIDATIONS.TIMES_REQUIRED"),
      t("FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.VALUES_BETWEEN_LUNCH"),
      t(
        "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.VALUES_BETWEEN_PREVIOUS_INTERVAL"
      ),
      t("FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.SAME_INTERVALS")
    );

    timeIntervalEndTimeSmaller(
      errors,
      "worked",
      {
        startTime: values.worked.startTime,
        endTime: values.worked.endTime,
      },
      {
        startTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
        endTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_TIME"
        ),
      },
      t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL")
    );

    // timeTrackerFieldArrayValidator(values, errors, "worked");
    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }: any) => {
        return selectedUser ? (
          <TimeTrackerFormFields
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            selectedUser={selectedUser}
            userDate={userDate}
          />
        ) : (
          <TimeTrackerFormFields
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
          />
        );
      }}
    </Formik>
  );
};

export default TimeTrackerFormik;
