/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

interface AdminRoutesProps {
  refreshToken: string;
}

const AdminRoutes = ({ refreshToken }: AdminRoutesProps) => {
  const navigation = useNavigate();

  useEffect(() => {
    if (!refreshToken) {
      navigation("/login");
    }
  }, []);

  return <Outlet />;
};

export default AdminRoutes;
