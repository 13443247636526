import { Box, IconButton, InputBase, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../core/AppStyles";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  setSearchEvent: React.Dispatch<React.SetStateAction<string>>;
  border?: string;
  borderColor?: string;
  setPage?: any;
}

const SearchBar = ({
  setSearchEvent,
  border,
  borderColor,
  setPage,
}: SearchBarProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation()

  const handleSearchBarChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length > 2) {
      setSearchEvent(event.target.value);
      setPage(1);
    } else {
      setSearchEvent("");
    }
  };
  const dynamicStyles = {
    backgroundColor: colors.primary[400],
    width: "100%",
    border: border,
    borderColor: borderColor,
  };

  return (
    <Box display="flex" sx={dynamicStyles} borderRadius="10px">
      <InputBase
        onChange={handleSearchBarChange}
        sx={{ ml: 2, flex: 1 }}
        placeholder={t("SEARCH")}
      />
      <IconButton type="button" sx={{ p: 1 }}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
