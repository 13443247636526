import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface ISearchSelctor {
  datas: any[];
  label: string;
  width?: any;
  value: any;
  onSelectChange: any;
}

const CustomSearchSelector = ({
  datas,
  label,
  width,
  value,
  onSelectChange,
}: ISearchSelctor) => {
  const data: any[] = [];
  datas?.map((employee: any) =>
    data.push({
      id: employee?.userId,
      label: employee?.firstName + " " + employee?.lastName,
    })
  );

  return (
    <Autocomplete
      freeSolo
      id="combo-box-demo"
      value={value}
      options={data}
      onChange={onSelectChange}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.id}>
            {option?.label}
          </li>
        );
      }}
      sx={{ width: { width } }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: `16px !important` },
            }}
            label={label}
            size="small"
          />
        );
      }}
    />
  );
};

export default CustomSearchSelector;
