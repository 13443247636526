/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomTable from "../../shared/custom/CustomTable";
import AllLeaveRequestsTableRow from "./AllLeaveRequestsTableRow";
import { Box, MenuItem, SelectChangeEvent } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { LeaveRequest } from "../../data/models/leave-request.interface";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomLeaveRequestTableMobile from "./CustomLeaveRequestTableMobile";
import { useLazyGetAllLeavesQuery } from "../../data/endpoints/app.endpoints";
import SearchBar from "../../shared/custom/SearchBar";
import CustomSelector from "../../shared/custom/CustomSelector";
import { OutOfOffice } from "../../utils/enums/out-of-office.enum";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import NoDataComponent from "../../shared/NoDataComponent";

const AllLeaveRequests = () => {
  const { t } = useTranslation();

  const AllLeaveRequestsTableTitles = [
    t("FULL_NAME"),
    t("START_DATE"),
    t("END_DATE"),
    t("TYPE"),
    t("LEAVE_REQUESTS.DAYS_REQUIRED"),
    t("DESCRIPTION"),
    t("STATUS"),
    t("ACTION"),
    t("EDIT"),
    t("DELETE"),
  ];

  const params = useParams();

  const [getLeaves, result] = useLazyGetAllLeavesQuery();
  const [status, setStatus] = useState(params.status || "ALL");
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const [type, setType] = useState("ALL");
  const [limit, setLimit] = useState<any>(10);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value);
    setPage(1);
  };

  useEffect(() => {
    if (result?.data?.data?.length === 0 && page !== 1) {
      setPage(page - 1);
    }
  }, [result?.data]);

  useEffect(() => {
    getLeaves({
      currentPage: page,
      limitTo: limit,
      status: status === "ALL" ? "" : status,
      type: type === "ALL" ? "" : type,
      q,
    });
  }, [q, page, status, limit, type]);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [_row, setRow] = useState<any>();

  const leaveRequestsBodyRenderer = useMemo(() => {
    const leaveRequestsDataRenderer: JSX.Element[] = [];
    result?.data?.data.map((row: LeaveRequest, index: number) => {
      return leaveRequestsDataRenderer.push(
        <AllLeaveRequestsTableRow key={index} row={row} />
      );
    });
    return leaveRequestsDataRenderer;
  }, [result]);

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("LEAVE_REQUEST")}
          subtitle={t("LEAVE_REQUESTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              marginTop: "1vh",
            }}
          >
            <SearchBar setPage={setPage} setSearchEvent={setQ} />

            <Box
              sx={{
                width: "100%",
                marginTop: "2vh",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <CustomSelector
                label={t("STATUS")}
                value={status}
                onSelectChange={handleStatusChange}
                size="small"
                width="100%"
              >
                <MenuItem value={"ALL"}>
                  {t("LEAVE_REQUESTS.STATUS.ALL")}
                </MenuItem>
                <MenuItem value={"Pending"}>
                  {t("LEAVE_REQUESTS.STATUS.PENDING")}
                </MenuItem>
                <MenuItem value={"Rejected"}>
                  {t("LEAVE_REQUESTS.STATUS.REJECTED")}
                </MenuItem>
                <MenuItem value={"Approved"}>
                  {t("LEAVE_REQUESTS.STATUS.APPROVED")}
                </MenuItem>
              </CustomSelector>
              <CustomSelector
                label={t("TYPE")}
                value={type}
                onSelectChange={handleTypeChange}
                size="small"
                width="100%"
              >
                <MenuItem value={"ALL"}>{t("STATUSES.ALL")}</MenuItem>
                {Object.values(OutOfOffice).map((value, index: number) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {t(
                        `LEAVE_REQUESTS.OUT_OF_OFFICE.${value
                          ?.replace(" ", "_")
                          .replace("/", "_")
                          .toUpperCase()}`
                      )}
                    </MenuItem>
                  );
                })}
              </CustomSelector>
            </Box>
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("LEAVE_REQUEST")}
          subtitle={t("LEAVE_REQUESTS.SUBTITLE")}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "55%",
              }}
            >
              <SearchBar setSearchEvent={setQ} setPage={setPage} />
            </Box>
            <CustomSelector
              label={t("STATUS")}
              value={status}
              onSelectChange={handleStatusChange}
              size="small"
              width="20%"
            >
              <MenuItem value={"ALL"}>
                {t("LEAVE_REQUESTS.STATUS.ALL")}
              </MenuItem>
              <MenuItem value={"Pending"}>
                {t("LEAVE_REQUESTS.STATUS.PENDING")}
              </MenuItem>
              <MenuItem value={"Rejected"}>
                {t("LEAVE_REQUESTS.STATUS.REJECTED")}
              </MenuItem>
              <MenuItem value={"Approved"}>
                {t("LEAVE_REQUESTS.STATUS.APPROVED")}
              </MenuItem>
            </CustomSelector>
            <CustomSelector
              label={t("TYPE")}
              value={type}
              onSelectChange={handleTypeChange}
              size="small"
              width="20%"
            >
              <MenuItem value={"ALL"}>
                {t("LEAVE_REQUESTS.STATUS.ALL")}
              </MenuItem>
              {Object.values(OutOfOffice).map((value, index: number) => {
                return (
                  <MenuItem key={index} value={value}>
                    {t(
                      `LEAVE_REQUESTS.OUT_OF_OFFICE.${value
                        ?.replace(" ", "_")
                        .replace("/", "_")
                        .toUpperCase()}`
                    )}
                  </MenuItem>
                );
              })}
            </CustomSelector>
          </Box>
        </CustomHeader>
      )}

      {result.isSuccess ? (
        result?.data?.data.length > 0 ? (
          isMobile || isTablet ? (
            <CustomLeaveRequestTableMobile
              column1Name={t("DETAILS")}
              column2Name={t("LEAVES")}
              handleChangePage={handleChangePage}
              mt={2}
              data={result?.data?.data}
              isPaginated={result?.data?.totalRecords > 10 ? true : false}
              row={_row}
              rowsPerPage={limit}
              handleChangeRowsPerPage={handleRowPerPageChange}
              count={result?.data?.totalRecords}
              page={page}
              // mt={2}
            />
          ) : (
            <Box
              mt={3}
              sx={{
                overflowX: "auto",
                scrollBehavior: "smooth",
                overflowY: "scroll",
                maxHeight: "100%",
                minHeight: "20%",
                borderRadius: "4px",
              }}
            >
              <CustomTable
                handleChangePage={handleChangePage}
                isPaginated={result?.data?.totalRecords > 10 ? true : false}
                tableBodyRenderer={leaveRequestsBodyRenderer}
                tableTitles={AllLeaveRequestsTableTitles}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                count={result?.data?.totalRecords}
                page={page}
                style={{
                  borderBottomLeftRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                  borderBottomRightRadius:
                    result?.data?.totalRecords > 9 ? "0px" : "10px",
                }}
                // mt={2}
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <NoDataComponent title={t("LEAVE_REQUESTS.NO_LEAVES")} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default AllLeaveRequests;
