import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import FieldArrayTimePicker from "../../shared/FieldArrayTimePicker";
import CustomButton from "../../shared/custom/CustomButton";
import CustomTextField from "../../shared/custom/CustomTextField";
import {
  useCreateSingleTimeTrackerMutation,
  useDeleteOneTimeTrackerMutation,
  useUpdateSingleTimeTrackerMutation,
} from "../../data/endpoints/app.endpoints";
import { TimeTrackerType } from "../../utils/enums/timetracker-type.enum";
import CustomSelector from "../../shared/custom/CustomSelector";
import { RootContext } from "../../context/RootContext";
import {
  requiredTimeInterval,
  timeBetweenMinutes,
  timeIntervalEndTimeSmaller,
} from "../../utils/regex/formRegex";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
  RED_COLOR_ACCENT,
} from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";

interface TimeIntervalFormikProps {
  colors: any;
  lunchTimeState: any;
  hasTitle?: boolean;
  isOnlyInterval?: boolean;
  isCreate?: boolean;
  index?: number;
  setAdditionalTimeTracker?: any;
  additionalTimeTracker?: any[];
  renderMenuItems?: any;
  refetch?: any;
  title?: string;
  selectedTimeTracker?: any;
}

const TimeIntervalFormik = ({
  colors,
  lunchTimeState,
  hasTitle = false,
  isOnlyInterval = true,
  isCreate = false,
  index,
  setAdditionalTimeTracker,
  additionalTimeTracker = [],
  renderMenuItems,
  title,
  refetch,
  selectedTimeTracker,
}: TimeIntervalFormikProps) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [displayButton, setDisplayButton] = useState(false);

  const { showSnackbar } = useContext(RootContext);

  const [createTimeTracker] = useCreateSingleTimeTrackerMutation();

  const [deleteSingleTimeTracker] = useDeleteOneTimeTrackerMutation();
  const user = useSelector(selectLoggedUser);

  const [updateTimeTracker] = useUpdateSingleTimeTrackerMutation();

  const onDeleteSubmit = async (
    e: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    if (isCreate) {
      setAdditionalTimeTracker(
        additionalTimeTracker.filter((value, i) => i !== index)
      );

      showSnackbar(
        `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS")}`,
        false,
        true
      );
    } else {
      const deleteResult = await deleteSingleTimeTracker(
        lunchTimeState?.timeTrackerId
      );
      if ("data" in deleteResult) {
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS")}`,
          false,
          true
        );
      }
    }
  };

  const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (isCreate) {
      const createResult = await createTimeTracker({
        userId: values?.userId,
        timeTracker: values,
      });

      if ("data" in createResult) {
        showSnackbar(
          `${t(
            "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS_CREATE"
          )}`,
          false,
          true
        );

        setAdditionalTimeTracker([]);
        // const refetchResult = await getDailyTimeTracker({
        //   userId: (createResult as any).data.userId,
        //   date: (createResult as any).data.date,
        // });
        // console.log("refetchResult.data", refetchResult.data);
        // if (refetchResult?.isSuccess) {
        //   setSelectedTimeTracker(refetchResult.data);
        // }
        // if (refetchResult.isSuccess) {
        //   showSnackbar("Time tracker deleted successfully!", false, true);
        //   setSelectedTimeTracker(null);

        //   setSelectedTimeTracker(refetchResult.data);
        // }
        // const newAdditionalTimeTracker = additionalTimeTracker?.filter((_: any, i: number) => {
        //   return i !== index;
        // });
        // setAdditionalTimeTracker(newAdditionalTimeTracker);
      } else {
        showSnackbar(
          `${t(
            "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.ERROR_CREATE"
          )}`,
          true,
          true
        );
      }
    } else {
      updateTimeTracker(values).then((res: any) => {
        if (res.data) {
          showSnackbar(
            `${t(
              "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS_TT_UPDATE"
            )}`,
            false,
            true
          );
        } else {
          showSnackbar(
            `${t(
              "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.ERROR_TT_UPDATE"
            )}`,
            true,
            true
          );
        }
      });
    }
  };

  const isOnlyIntervalValidation = (values: typeof lunchTimeState) => {
    const errors: any = {};
    requiredTimeInterval(
      errors,
      "interval",
      {
        startTime: values.startTime,
        endTime: values.endTime,
      },
      t("FORM_VALIDATIONS.TIMES_REQUIRED")
    );
    timeIntervalEndTimeSmaller(
      errors,
      "interval",
      {
        startTime: values.startTime,
        endTime: values.endTime,
      },
      {
        startTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
        endTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_TIME"
        ),
      },
      t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL")
    );
    hasTitle &&
      timeBetweenMinutes(
        errors,
        "interval",
        {
          startTime: values.startTime,
          endTime: values.endTime,
        },
        30,
        60,
        t("FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.LUNCH_TIME_INTERVAL"),
        {
          startTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
          ),
          endTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
          ),
        },
        t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL")
      );

    return errors;
  };

  const isWorkingTimeValidation = (values: typeof lunchTimeState) => {
    const errors: any = {};

    if (!values.vehicleId) {
      errors["interval"] = `${t("TIME_TRACKER.VALIDATION.VEHICLE_REQUIRED")}`;
    }
    requiredTimeInterval(
      errors,
      "interval",
      {
        startTime: values.startTime,
        endTime: values.endTime,
      },
      t("FORM_VALIDATIONS.TIMES_REQUIRED")
    );
    timeIntervalEndTimeSmaller(
      errors,
      "interval",
      {
        startTime: values.startTime,
        endTime: values.endTime,
      },
      {
        startTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
        endTimeNotValid: t(
          "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_TIME"
        ),
      },
      t("FORM_VALIDATIONS.END_TIME_SMALLER_OR_EQUAL")
    );

    return errors;
  };

  return (
    <Grid item xs={12}>
      {hasTitle ? (
        <Typography variant="h4" color={colors.grey[300]} marginBottom={2}>
          {title ?? t("CREATE_TIME_TRACKER.LUNCH_BREAK")}
        </Typography>
      ) : null}

      <Formik
        initialValues={lunchTimeState}
        onSubmit={onSubmit}
        validate={
          isOnlyInterval ? isOnlyIntervalValidation : isWorkingTimeValidation
        }
      >
        {({
          errors,
          touched,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
        }) => {
          return (
            <Grid
              container
              alignItems="center"
              justifyContent={isMobile || isTablet ? "flex-start" : "center"}
              spacing={4}
              mb={4}
            >
              {isOnlyInterval ? (
                <>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 4.5
                    }
                  >
                    <FieldArrayTimePicker
                      label={t("START_TIME")}
                      name="startTime"
                      errors={errors?.interval}
                      touched={errors?.interval}
                      sx={{ width: "100%" }}
                      disabled={isCreate ? false : !displayButton}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 4.5
                    }
                  >
                    <FieldArrayTimePicker
                      label={t("END_TIME")}
                      name="endTime"
                      errors={errors?.interval}
                      touched={errors?.interval}
                      sx={{ width: "100%" }}
                      disabled={isCreate ? false : !displayButton}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 2.25
                    }
                  >
                    <FieldArrayTimePicker
                      label={t("START_TIME")}
                      name="startTime"
                      errors={errors?.interval}
                      touched={errors?.interval}
                      sx={{ width: "100%" }}
                      disabled={isCreate ? false : !displayButton}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 2.25
                    }
                  >
                    <FieldArrayTimePicker
                      label={t("END_TIME")}
                      name="endTime"
                      errors={errors?.interval}
                      touched={errors?.interval}
                      sx={{ width: "100%" }}
                      disabled={isCreate ? false : !displayButton}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 2.25
                    }
                  >
                    <CustomSelector
                      width="100%"
                      value={values?.vehicleId}
                      onSelectChange={(newValue: any) => {
                        setFieldValue("vehicleId", newValue.target.value, true);
                      }}
                      size="medium"
                      label={t("CREATE_TIME_TRACKER.SELECT_VEHICLE")}
                      errors={errors?.interval}
                      touched={errors?.interval}
                      showError={false}
                      name="vehicleId"
                      disabled={isCreate ? false : !displayButton}
                    >
                      {renderMenuItems}
                    </CustomSelector>
                  </Grid>
                  <Grid
                    item
                    xs={
                      (isMobile || isTablet) && user?.role === "Employee"
                        ? 5
                        : isMobile || isTablet
                        ? 5
                        : 2.25
                    }
                  >
                    <CustomTextField
                      onChange={handleChange}
                      value={values?.description ? values?.description : ""}
                      errors={errors?.description}
                      touched={touched?.description}
                      name="description"
                      label={t("DESCRIPTION")}
                      disabled={isCreate ? false : !displayButton}
                    />
                  </Grid>
                </>
              )}

              {user?.role === "Employee" ? (
                ""
              ) : (
                <Grid item xs={isMobile || isTablet ? 3.5 : 1.5}>
                  {isCreate ? (
                    <CustomButton
                      title={t("CREATE")}
                      width="auto"
                      onClick={(
                        e: React.FormEvent<HTMLFormElement> | undefined
                      ) => {
                        handleSubmit(e);
                      }}
                      color={BLUE_COLOR_ACCENT}
                      colorHover={BLUE_HOVER_COLOR_ACCENT}
                    />
                  ) : displayButton ? (
                    <CustomButton
                      title={t("CONFIRM")}
                      width="auto"
                      onClick={(
                        e: React.FormEvent<HTMLFormElement> | undefined
                      ) => {
                        if (!errors?.interval) {
                          handleSubmit(e);
                          setDisplayButton(!displayButton);
                        }
                      }}
                      color={BLUE_COLOR_ACCENT}
                      colorHover={BLUE_HOVER_COLOR_ACCENT}
                    />
                  ) : (
                    <CustomButton
                      title={t("EDIT")}
                      width="auto"
                      onClick={() => setDisplayButton(!displayButton)}
                      color={BLUE_COLOR_ACCENT}
                      colorHover={BLUE_HOVER_COLOR_ACCENT}
                    />
                  )}
                </Grid>
              )}
              {(user?.role === "Admin" &&
                !isCreate &&
                index === 0 &&
                lunchTimeState?.type === TimeTrackerType.WORK) ||
              hasTitle ? (
                <Grid item xs={1.5} />
              ) : (
                user?.role === "Admin" && (
                  <Grid item xs={isMobile || isTablet ? 3.5 : 1.5}>
                    <CustomButton
                      title={t("DELETE")}
                      width="auto"
                      onClick={onDeleteSubmit}
                      color={BLUE_COLOR_ACCENT}
                      colorHover={BLUE_HOVER_COLOR_ACCENT}
                    />
                  </Grid>
                )
              )}

              {!isCreate || isOnlyInterval ? (
                <Typography sx={{ marginTop: 1, color: RED_COLOR_ACCENT }}>
                  {errors && errors?.interval?.toString()}
                </Typography>
              ) : null}
            </Grid>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default TimeIntervalFormik;
