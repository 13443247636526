import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { useContext, useState } from "react";
import { Delete } from "@mui/icons-material";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { RootContext } from "../../context/RootContext";
import {
  IconEdit,
  PrevIcon,
  NextIcon,
} from "../../shared/custom/CustomEditIcons";
import moment from "moment";
import { useDeleteDailyTimeTrackerMutation } from "../../data/endpoints/app.endpoints";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";

interface ICustomViewTimeTrackerTableMobile {
  openModalManageTimeTracker: any;
  column1Name?: string;
  column2Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const CustomViewTimeTrackerTableMobile = ({
  openModalManageTimeTracker,
  column1Name,
  column2Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
}: ICustomViewTimeTrackerTableMobile) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();
  const { t } = useTranslation();
  const [openDeleteModal, setDeleteModal] = useState(false);
  const {
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);

  const [deleteTimeTracker] = useDeleteDailyTimeTrackerMutation();

  const handleDelete = (id: string, _date: string) => {
    deleteTimeTracker({
      userId: id,
      date: moment(_date).format("YYYY-MM-DD"),
    }).then((res: any) => {
      if (!res.error) {
        setDeleteModal(false);
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS")}`,
          false,
          true
        );
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
  };

  const handleDetailsModal = (id: string, date: Date) => {
    const _rowDetails = data?.data?.filter(
      (info: any) => info?.user?.userId === id && info?.date === date
    );

    const rowProjectData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowProjectData.push({
        data: [
          {
            key: `${t("USER")}`,
            value: info?.user?.firstName + " " + info?.user?.lastName,
          },
          {
            key: `${t("DATE")}`,
            value: moment(info?.date)?.format("DD MMM YYYY"),
          },
          {
            key: `${t("START_TIME")}`,
            value: moment(info?.firstStartTime).format("HH:mm"),
          },
          {
            key: `${t("END_TIME")}`,
            value: moment(info?.lastStartTime).format("HH:mm"),
          },
          {
            key: `${t("HOURS_WORKED")}`,
            value: info?.work_time / 60,
          },
          {
            key: `${t("OVERTIME")}`,
            value:
              (info?.work_time / 60 - info?.user?.workingHours)
                .toFixed(2)
                .replace(/(\.0+|0+)$/, "") || 0,
          },
        ],
        actions: [
          {
            key: (
              <IconEdit
                fill={colors.invert}
                onClick={() => {
                  openModalManageTimeTracker(info?.user, info?.date);
                }}
              />
            ),
          },
        ],
        functions: [
          {
            key: <Delete />,
            func: handleDelete,
            id: info?.user?.userId,
            _date: info?.date,
          },
        ],
      });
    });

    setRowDetails(rowProjectData);
    setHeader(rowProjectData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() =>
                    handleDetailsModal(row?.user?.userId, row?.date)
                  }
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.user?.firstName + " " + row?.user?.lastName}</b>{" "}
                  <br />
                  {moment(row?.date)?.format("DD MMM YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>{t("STATUSES.ALL")}</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
};

export default CustomViewTimeTrackerTableMobile;
