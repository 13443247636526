import { useContext } from "react";
import { useParams } from "react-router-dom";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import { useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../data/endpoints/app.endpoints";
import { UserStatus } from "../../utils/enums/user-status.enum";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import EmployeeFormFields from "./EmployeeFormFields";

import {
  EMAIL_REGEX,
  handleDateValid,
  handleRegexValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { Box, useTheme } from "@mui/material";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { tokens } from "../../core/AppStyles";

export default function UpdateUser() {
  const { id } = useParams();
  const user = useSelector(selectEmployeeById(id));
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const [updateUser] = useUpdateUserMutation();
  const { setOpenModal } = useContext(RootContext);
  const { t } = useTranslation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const initialValues = {
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    role: user?.role,
    hiringDate: user?.hiringDate,
    totalVacations: user?.totalVacations.toFixed(2).replace(/(\.0+|0+)$/, ""),
    workingHours: user?.workingHours,
    status: user?.status,
    userId: id,
    totalOvertimes: user?.totalOvertimes / 60,
  };

  const validate = (values: any) => {
    const errors = {} as any;
    handleRegexValidation(
      values,
      errors,
      "email",
      EMAIL_REGEX,
      t(`FORM_VALIDATIONS.USERS.EMAIL_ERROR_MESSAGE`),
      t(`FORM_VALIDATIONS.REQUIRED`)
    );
    handleRequiredValidation(
      values,
      errors,
      "firstName",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "lastName",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "role",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    if (Number(values.workingHours) <= 0) {
      errors.workingHours = t("FORM_VALIDATIONS.USERS.WORKING_HOURS_POSITIVE");
    } else if (Number(values.workingHours > 16)) {
      errors.workingHours = t("FORM_VALIDATIONS.USERS.TO_MANY_HOURS");
    }
    handleRequiredValidation(
      values,
      errors,
      "workingHours",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    
    handleRequiredValidation(
      values,
      errors,
      "hiringDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  const onSubmitForm = () => {
    setOpenModal(true);
  };
  return (
    <>
      <CustomHeader
        title={t("USERS_ADMIN.EDIT_USER.TITLE")}
        subtitle={t("USERS_ADMIN.EDIT_USER.UPDATE_EXISTING_USER")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "10%",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        sx={{
          width: "auto",
          height: "auto",
          marginTop: isDesktop || isLargeScreen ? 5 : 0,
          backgroundColor: colors.component,
          padding: 3,
          paddingTop: 5,
          borderRadius: 3,
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitForm}
          validate={validate}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: any) => (
            <EmployeeFormFields
              buttonTitle={t("USERS_ADMIN.EDIT_USER.EDIT")}
              handleMutation={updateUser}
              id={id}
              errors={errors}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              touched={touched}
              values={values}
            />
          )}
        </Formik>
      </Box>
    </>
  );
}
