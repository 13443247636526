import { useContext, useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import {
  useDeleteDailyTimeTrackerMutation,
  useLazyGetDailyTimeTrackerQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import {
  selectAllEmployees,
  selectEmployeeById,
} from "../../data/store/employeeSlice";
import moment from "moment";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { MenuItem } from "@mui/material";
import { TimeTrackerType } from "../../utils/enums/timetracker-type.enum";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { tokens } from "../../core/AppStyles";
import TimeIntervalFormik from "./TimeIntervalFormik";
import ManageFieldArray from "./ManageFieldArray";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { TimeTrackerStructure } from "../../data/models/time-tracker-structure.interface";
import AlertDialog from "../../shared/modal/AlertDialog";
import {
  RED_COLOR_ACCENT,
  RED_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { RootContext } from "../../context/RootContext";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { Delete } from "@mui/icons-material";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomSearchSelector from "../../shared/custom/CustomSearchSelector";
import { TimeTracker } from "../../data/models/time-tracker.interface";
import { useTranslation } from "react-i18next";
import { selectAllVehicles } from "../../data/store/vehicleSlice";

const ManageTimeTrackers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { t } = useTranslation();
  const [getDailyTimeTracker, result] = useLazyGetDailyTimeTrackerQuery();
  const { showSnackbar } = useContext(RootContext);
  const [selectedTimeTracker, setSelectedTimeTracker] = useState<
    TimeTrackerStructure | undefined | null
  >(undefined);

  const vehicles = useSelector(selectAllVehicles);

  const [selectedEmployee, setSelectedEmployee] = useState<any>();

  const employeeData = useSelector(selectEmployeeById(selectedEmployee?.id));

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  useEffect(() => {
    if (employeeData) {
      setSelectedTimeTracker(null);
      fetchData().then((res) => {
        if (res?.data && res?.data?.worked) {
          setSelectedTimeTracker(res?.data);
        } else if (res?.data?.worked?.length === 0) {
          setSelectedTimeTracker(null);
        } else {
          setSelectedTimeTracker(null);
        }
      });
    }
  }, [employeeData, selectedDate, result?.data]);

  const handleSelectUserSelector = (event: any, value: any) => {
    setSelectedEmployee(value);
  };

  const [openModal, setOpenModal] = useState(false);

  const [deleteDailyTimeTracker] = useDeleteDailyTimeTrackerMutation();

  const employees = useSelector(selectAllEmployees);

  const renderMenuItems = useMemo(() => {
    const menuItems: JSX.Element[] = [];

    vehicles?.map((vehicle) => {
      menuItems.push(<MenuItem value={vehicle?.vehicleId}>{vehicle?.model} - {vehicle?.plate}</MenuItem>)
    })

    return menuItems;
  }, [vehicles])

  const fetchData = () => {
    return getDailyTimeTracker({
      date: selectedDate,
      userId: employeeData?.userId,
    });
  };

  const handleTimeTrackerDelete = () => {
    deleteDailyTimeTracker({
      userId: employeeData?.userId,
      date: moment(selectedDate).format("YYYY-MM-DD"),
    }).then((res) => {
      if ("data" in res) {
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.SUCCESS")}`,
          false,
          true
        );
        setSelectedTimeTracker(null);
        setOpenModal(false);
      } else {
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.RESPONSE.ERROR")}`,
          true,
          true
        );
      }
    });
  };

  return (
    <BoxContainer>
      <CustomHeader
        title={t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.TITLE")}
        subtitle={t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.SUBTITLE")}
        children={
          <Box style={{ display: "flex", gap: 20 }}>
            {selectedTimeTracker ? (
              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle={
                  isMobile || isTablet ? (
                    <Delete />
                  ) : (
                    `${t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.DELETE_DAY")}`
                  )
                }
                handleSubmitDialog={handleTimeTrackerDelete}
                handleShowAlertDialog={() => setOpenModal(true)}
                color={RED_COLOR_ACCENT}
                colorHover={RED_HOVER_COLOR_ACCENT}
                width="fit-content"
              >
                {t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.MODAL_TEXT")}
              </AlertDialog>
            ) : null}
          </Box>
        }
      />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        mb="3%"
        mt="2%"
      >
        <Box gridColumn="span 2">
          <CustomSearchSelector
            label={t("FILE_MANAGER.FILE_UPLOAD.SEARCH_EMPLOYEE")}
            width="auto"
            value={
              !!employeeData
                ? `${employeeData?.firstName} ${employeeData?.lastName}`
                : ""
            }
            onSelectChange={handleSelectUserSelector}
            datas={employees}
          />
        </Box>
        <Box gridColumn="span 2">
          <CustomDatePicker
            onChange={(value: any) => {
              setSelectedDate(moment(value?.$d).format("YYYY-MM-DD"));
            }}
            fullWidth
            value={selectedDate}
            label={t("DATE")}
            views={["day"]}
            openTo="day"
            shouldDisableDate={true}
          />
        </Box>
      </Box>
      <Grid container>
        {result.isError ? (
          <Typography variant={isMobile ? "h5" : "h3"}>
            Something went wrong
          </Typography>
        ) : selectedTimeTracker ? (
          <>
            <TimeIntervalFormik
              colors={colors}
              lunchTimeState={selectedTimeTracker.lunchTime}
              hasTitle={true}
              selectedTimeTracker={selectedTimeTracker}
            />
            {/* <ManageFieldArray
              type={TimeTrackerType.WORK}
              colors={colors}
              timeTrackerArray={selectedTimeTracker.worked}
              title={t("CREATE_TIME_TRACKER.WORKING_TIME")}
              // renderMenuItems={renderMenuItems}
              refetch={fetchData}
              selectedTimeTracker={selectedTimeTracker}
            /> */}
            <TimeIntervalFormik
              colors={colors}
              isOnlyInterval={false}
              title={t(
                "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.WORKED"
              )}
              lunchTimeState={selectedTimeTracker.worked}
              hasTitle={true}
              renderMenuItems={renderMenuItems}
              selectedTimeTracker={selectedTimeTracker}
            />
            <ManageFieldArray
              type={TimeTrackerType.BREAK_TIME}
              date={selectedTimeTracker?.lunchTime?.date}
              userId={selectedTimeTracker?.lunchTime?.userId}
              colors={colors}
              timeTrackerArray={selectedTimeTracker.optionalBreak}
              title={t("CREATE_TIME_TRACKER.OPTIONAL_BREAK")}
              refetch={fetchData}
              selectedTimeTracker={selectedTimeTracker}
            />
          </>
        ) : result.isLoading ? (
          <LoadingSpinner />
        ) : employeeData ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mt: 10,
              gap: 3,
            }}
          >
            <Typography variant={isMobile ? "h5" : "h3"}>
              {t(
                "TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.NO_TIME_TRACKER_COMPLETED"
              )}
            </Typography>
            <CustomNavigationButton
              navigateTo={`/manage-time-trackers/create-time-tracker/${employeeData?.userId}/${selectedDate}`}
            >
              {t("CREATE")}
            </CustomNavigationButton>
          </Box>
        ) : (
          <Typography variant={isMobile ? "h5" : "h3"}>
            {t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.SELECT_USER")}
          </Typography>
        )}
      </Grid>
    </BoxContainer>
  );
};

export default ManageTimeTrackers;
