import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { tokens } from "../../core/AppStyles";
import { useTheme } from "@mui/material";
import useIsMobile from "../hooks/useIsMobile";
import useIsTablet from "../hooks/useIsTablet";
import useIsLargeScreen from "../hooks/useIsLargeScreen";

interface CustomHeaderProps {
  title: string;
  subtitle?: string;
  children?: any;
  customStyle?: any;
}

const CustomHeader = ({
  title,
  subtitle,
  children,
  customStyle,
}: CustomHeaderProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 1,
        height: "9%",
        alignItems: "center",
        marginBottom: 1,
        ...customStyle,
      }}
    >
      <Box>
        <Typography
          variant={
            isMobile || isTablet
              ? "h4"
              : isTablet
              ? "h3"
              : isLargeScreen
              ? "h1"
              : "h2"
          }
          color={colors.grey[100]}
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Typography
          variant={isMobile || isTablet ? "h6" : isLargeScreen ? "h4" : "h5"}
          sx={{
            mb: "5px",
            fontSize: `${isMobile || isTablet ? "12px" : "auto"}`,
          }}
          color="#007ffc"
        >
          {subtitle}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default CustomHeader;
