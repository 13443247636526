import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { useContext, useState } from "react";
import { Delete, Done, Close, Replay } from "@mui/icons-material";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { RootContext } from "../../context/RootContext";
import {
  useChangeStatusLeaveRequestMutation,
  useDeleteLeaveRequestMutation,
} from "../../data/endpoints/app.endpoints";
import { LeaveStatus } from "../../utils/enums/leave.enum";
import { LeaveRequest } from "../../data/models/leave-request.interface";
import {
  PrevIcon,
  NextIcon,
  IconEdit,
} from "../../shared/custom/CustomEditIcons";
import CustomSelector from "../../shared/custom/CustomSelector";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface ICustomLeaveRequestTableMobile {
  column1Name?: string;
  column2Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  row: LeaveRequest;

  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const CustomLeaveRequestTableMobile = ({
  column1Name,
  column2Name,
  data,
  isPaginated,
  rowsPerPage,
  page,
  count,
  mt,
  handleChangePage,
  handleChangeRowsPerPage,
  row,
}: ICustomLeaveRequestTableMobile) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowDetails, setRowDetails] = useState<any>();
  const [header, setHeader] = useState<string>();

  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const { t } = useTranslation();
  const [updateLeaveRequest, resultUpdate] =
    useChangeStatusLeaveRequestMutation();

  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);

  const [deleteLeaveRequest] = useDeleteLeaveRequestMutation();

  const handleDelete = (leaveId: string) => {
    deleteLeaveRequest(leaveId).then((res: any) => {
      if (!res.error) {
        setDeleteModal(false);
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_DELETED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenModal(false);
  };

  const handleRejectSubmitDialog = (leaveId: string, userId: string) => {
    updateLeaveRequest({
      status: LeaveStatus.REJECTED,
      userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_REJECTED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenRejectModal(false);
  };

  const handleApproveSubmitDialog = (leaveId: string, userId: string) => {
    updateLeaveRequest({
      status: LeaveStatus.APPROVED,
      userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_APPROVED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenApproveModal(false);
  };

  const handleCancelSubmitDialog = (leaveId: string, userId: string) => {
    updateLeaveRequest({
      status: LeaveStatus.PENDING,
      userId,
      leaveId,
    }).then((res: any) => {
      if (!res.error) {
        showSnackbar(`${t("LEAVE_REQUESTS.LEAVES_CANCELED")}`, false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenCancelModal(false);
  };

  const handleDetailsModal = (id: string) => {
    const _rowDetails =
      data && data?.filter((info: any) => info?.leaveId === id);

    const rowProjectData: any[] = [];

    _rowDetails?.map((info: any) => {
      return rowProjectData.push({
        data: [
          {
            key: `${t("NAME")}`,
            value: info?.user?.firstName + " " + info?.user?.lastName,
          },
          {
            key: `${t("STATUS")}`,
            value: info?.status,
          },
          {
            key: `${t("TYPE")}`,
            value: info?.type,
          },
          {
            key: `${t("LEAVE_REQUESTS.DAYS_REQUIRED")}`,
            value: info?.daysRequired,
          },
          {
            key: `${t("START_DATE")}`,
            value: moment(info?.startDate)?.format("DD MMM YYYY"),
          },
          {
            key: `${t("END_DATE")}`,
            value: moment(info?.endDate)?.format("DD MMM YYYY"),
          },
        ],
        actions: [
          info?.status === "Pending"
            ? {
                key: <IconEdit fill={colors.invert} />,
                path: `/leaves/update/${info?.leaveId}`,
              }
            : null,
        ],
        functions: [
          info?.status === "Pending"
            ? {
                key: <Done sx={{ color: colors.greenAccent[500] }} />,
                func: handleApproveSubmitDialog,
                id: info?.leaveId,
                userId: info?.userId,
              }
            : null,
          info?.status === "Pending"
            ? {
                key: <Close sx={{ color: colors.redAccent[500] }} />,
                func: handleRejectSubmitDialog,
                id: info?.leaveId,
                userId: info?.userId,
              }
            : null,
          info?.status === "Pending"
            ? {
                key: <Delete sx={{ color: colors.blueAccent[400] }} />,
                func: handleDelete,
                id: info?.leaveId,
              }
            : null,
          info?.status === "Approved" || info?.status === "Rejected"
            ? {
                key: <Replay sx={{ color: colors.blueAccent[500] }} />,
                func: handleCancelSubmitDialog,
                id: info?.leaveId,
                userId: info?.userId,
              }
            : null,
        ],
      });
    });

    setRowDetails(rowProjectData);
    setHeader(rowProjectData?.[0]?.data?.[0]?.value);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 300, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{column1Name}</TableCell>
              <TableCell align="left">{column2Name}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any, index: number) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  width="50px"
                  onClick={() => handleDetailsModal(row?.leaveId)}
                >
                  <TableDetailsMobileModal
                    dataInfo={rowDetails}
                    header={header}
                  />
                </TableCell>
                <TableCell align="left">
                  <b>{row?.user?.firstName + " " + row?.user?.lastName}</b>{" "}
                  <br />
                  {row?.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          flexDirection="row"
          width="100%"
          justifyContent="center"
          padding={2}
          sx={{ backgroundColor: colors.component }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <p
                style={{
                  color: "gray",
                }}
              >
                {t("ROWS")}:
              </p>
              <CustomSelector
                size="medium"
                value={rowsPerPage}
                onSelectChange={handleChangeRowsPerPage}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={count}>{t("STATUSES.ALL")}</MenuItem>
              </CustomSelector>
            </span>
            <Pagination
              siblingCount={0}
              size="small"
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.primary[400] }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
};

export default CustomLeaveRequestTableMobile;
