import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import TableDetailsMobileModal from "../../shared/custom/CustomTableDetailModalMobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ICustomTableMobile {
  column1Name?: string;
  column2Name?: string;
  data?: any;
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function UserPersonalActivityTableMobile({
  column1Name,
  column2Name,
  data,
  mt,
}: ICustomTableMobile) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const rowData = data;

  const { t } = useTranslation();

  const datas = [
    {
      data: [
        {
          key: `${t("ACTIVITY.TABLE_TITLES.EXPECTED_WORKING", {
            param: "(h)"
          })}`,
          value: rowData?.expectedWorkingHours.toFixed(2).replace(/(\.0+|0+)$/, ""),
        },
        {
          key: `${t("LEAVES", {
            param: "(h)"
          })}`,
          value: rowData?.totalLeavesHours.toFixed(2).replace(/(\.0+|0+)$/, ""),
        },
        {
          key: `${t("WORKED_HOURS", {
            param: "(h)"
          })}`,
          value: rowData?.effectiveWorkedTime.toFixed(2).replace(/(\.0+|0+)$/, ""),
        },
        {
          key: `${t("ACTIVITY.TABLE_TITLES.EQUIVALENT_WORKING", {
            param: "(h)"
          })}`,
          value: rowData?.paidHours.toFixed(2).replace(/(\.0+|0+)$/, ""),
        },
        {
          key: `${t("ACTIVITY.TABLE_TITLES.OVERTIME", {
            param: "(h)"
          })}`,
          value: rowData?.overtime.toFixed(2).replace(/(\.0+|0+)$/, ""),
        },

      ],
    },
  ];
  const handleDetailsModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        backgroundColor: colors.component,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 350, backgroundColor: colors.component }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <TableCell sx={{ width: "20%" }}>{column1Name}</TableCell>
              <TableCell align="left" sx={{ width: "80%" }}>
                {column2Name}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <TableCell
                align="center"
                width="20%"
                onClick={() => handleDetailsModal()}
              >
                <TableDetailsMobileModal dataInfo={datas} header={"Activity"} />
              </TableCell>
              <TableCell align="center" width="70%" sx={{ textAlign: "start" }}>
                <b>
                  {rowData?.paidHours +
                    " h / " +
                    rowData?.expectedWorkingHours +
                    " h"}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
