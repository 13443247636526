import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useLazyGetTimeTrackersForEmployeesQuery } from "../../data/endpoints/app.endpoints";
import { Box, SelectChangeEvent } from "@mui/material";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomDatePicker from "../../shared/CustomDatePicker";
import CustomTable from "../../shared/custom/CustomTable";
import ActivityTableRow from "./ActivityTableRow";
import LoadingSpinner from "../../shared/LoadingSpinner";
import useIsMobile from "../../shared/hooks/useIsMobile";
import CustomActivityTableMobile from "./CustomActivityTableMobile";
import SearchBar from "../../shared/custom/SearchBar";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { CSVLink } from "react-csv";
import NoDataComponent from "../../shared/NoDataComponent";
import { useTranslation } from "react-i18next";
import ActivityLegend from "../dashboard/testDashboard/DashboardComponents/ActivityLegend";
import { QueryStatus } from "@reduxjs/toolkit/query";

const Activity = () => {
  const [getTimeTrackersForEmployees, result] =
    useLazyGetTimeTrackersForEmployeesQuery();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [error, setError] = useState<{ message: string; hasError: boolean }>({
    hasError: false,
    message: "",
  });

  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [limit, setLimit] = useState<any>(10);

  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().format("YYYY-MM-DD")
  );

  const handleRowPerPageChange = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const activityTableTitles = [
    `${t("ACTIVITY.TABLE_TITLES.FULL_NAME")}`,
    `${t("ACTIVITY.TABLE_TITLES.EXPECTED_WORKING", {
      param: "(h)",
    })}`,
    `${t("ACTIVITY.TABLE_TITLES.LEAVES", {
      param: "(h)",
    })}`,
    `${t("ACTIVITY.TABLE_TITLES.WORKED", {
      param: "(h)",
    })}`,
    `${t("ACTIVITY.TABLE_TITLES.EQUIVALENT_WORKING", {
      param: "(h)",
    })}`,
    `${t("ACTIVITY.TABLE_TITLES.OVERTIME", {
      param: "(h)",
    })}`,
  ];

  useEffect(() => {
    const startTimeMoment = moment(intervalStartTime);
    const endTimeMoment = moment(intervalEndTime);
    const endTimeSmaller = endTimeMoment.isBefore(startTimeMoment);

    if (
      !endTimeSmaller &&
      startTimeMoment?.isValid() &&
      endTimeMoment?.isValid()
    ) {
      getTimeTrackersForEmployees({
        startDate: intervalStartTime,
        endDate: intervalEndTime,
        currentPage: page,
        limitTo: limit,
        q,
      });
      setError({
        hasError: false,
        message: "",
      });
    } else {
      setError({
        hasError: true,
        message: t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER"),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalStartTime, intervalEndTime, limit, page, q]);

  const activityBodyRenderer = useMemo(() => {
    if (result?.isError || result?.status !== QueryStatus.fulfilled) {
      return [] as JSX.Element[];
    }
    const dataRenderer: JSX.Element[] = [];

    result?.data?.userData?.map((row: any, index: any) => {
      return dataRenderer.push(<ActivityTableRow key={index} row={row} />);
    });
    return dataRenderer;
  }, [result]);

  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (result?.data) {
      result?.data?.userData?.map((info: any) => {
        return data.push({
          user: info?.firstName + " " + info?.lastName,
          expected: info?.expectedWorkingHours,
          leavesHours: info?.totalLeavesHours,
          worked: info?.effectiveWorkedTime,
          overtime: info?.overtime,
          equivalent: info?.paidHours,
        });
      });
    }
    return data;
  }, [result?.data]);

  const headers = [
    { label: `${t("ACTIVITY.TABLE_TITLES.FULL_NAME")}`, key: "user" },
    {
      label: `${t("ACTIVITY.TABLE_TITLES.EXPECTED_WORKING")} (h)`,
      key: "expected",
    },
    { label: `${t("ACTIVITY.TABLE_TITLES.LEAVES")} (h)`, key: "leavesHours" },
    { label: `${t("ACTIVITY.TABLE_TITLES.WORKED")} (h)`, key: "worked" },
    { label: `${t("ACTIVITY.TABLE_TITLES.OVERTIME")} (h)`, key: "overtime" },
    {
      label: `${t("ACTIVITY.TABLE_TITLES.EQUIVALENT_WORKING")} (h)`,
      key: "equivalent",
    },
  ];

  return (
    <BoxContainer>
      {isMobile || isTablet ? (
        <>
          <CustomHeader
            title={t("ACTIVITY.TITLE")}
            subtitle={t("ACTIVITY.SUBTITLE")}
          />
          <Box
            display="grid"
            gap={2}
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
            }}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            mb="3%"
            mt="2%"
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <SearchBar setSearchEvent={setQ} setPage={setPage} />
            </Box>

            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_TIME")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value) =>
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "")
              }
              value={intervalEndTime}
              label={t("END_TIME")}
              views={["day"]}
              openTo="day"
            />
          </Box>
        </>
      ) : (
        <CustomHeader
          title={t("ACTIVITY.TITLE")}
          subtitle={t("ACTIVITY.SUBTITLE")}
          customStyle={{
            height: "100px",
          }}
        >
          <Box
            display="grid"
            gap="20px"
            sx={{
              width: "80%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
            }}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            <Box
              sx={{
                width: "40%",
              }}
            >
              <SearchBar setSearchEvent={setQ} setPage={setPage} />
            </Box>

            <CustomDatePicker
              onChange={(value) => {
                setIntervalStartTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalStartTime}
              label={t("START_TIME")}
              views={["day"]}
              openTo="day"
            />
            <CustomDatePicker
              onChange={(value) => {
                setIntervalEndTime(value ? value.format("YYYY-MM-DD") : "");
              }}
              value={intervalEndTime}
              label={t("END_TIME")}
              views={["day"]}
              openTo="day"
              error={error?.message}
              touched={true}
            />
          </Box>
        </CustomHeader>
      )}
      {result?.status === QueryStatus.fulfilled ? (
        result?.data?.userData && result?.data?.userData?.length > 0 ? (
          isMobile || isTablet ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "7%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 1,
                  padding: "2% 0",
                }}
              >
                <ActivityLegend />
                <CSVLink
                  data={dataValues}
                  headers={headers}
                  filename={t("ACTIVITY.NAME")}
                  style={{
                    background: "#43b685",
                    color: "white",
                    fontWeight: "500",
                    width: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "end",
                    alignItems: "center",
                    textDecoration: "none",
                    padding: "0.4% 0.8%",
                    borderRadius: "5px",
                    float: "right",
                    marginBottom: "1%",
                  }}
                >
                  {t("EXPORT_TO_EXCEL")}
                </CSVLink>
              </Box>

              <CustomActivityTableMobile
                isPaginated={
                  result?.data?.totalUsers && result?.data?.totalUsers > 10
                    ? true
                    : false
                }
                column1Name={t("DETAILS")}
                column2Name={t("ACTIVITY.NAME")}
                data={result?.data.userData}
                count={result?.data?.totalUsers}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
              />
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  minHeight: "7vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 3,
                }}
              >
                <ActivityLegend />
                <CSVLink
                  data={dataValues}
                  headers={headers}
                  filename={t("ACTIVITY.NAME")}
                  style={{
                    background: "#43b685",
                    color: "white",
                    fontWeight: "500",
                    width: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "end",
                    alignItems: "center",
                    textDecoration: "none",
                    padding: "0.4% 0.8%",
                    borderRadius: "5px",
                    float: "right",
                    marginBottom: "1%",
                  }}
                >
                  {t("EXPORT_TO_EXCEL")}
                </CSVLink>
              </Box>

              <CustomTable
                isPaginated={
                  result?.data?.totalUsers && result?.data?.totalUsers > 10
                    ? true
                    : false
                }
                count={result?.data?.totalUsers}
                handleChangePage={handleChangePage}
                tableBodyRenderer={activityBodyRenderer}
                tableTitles={activityTableTitles}
                page={page}
                rowsPerPage={limit}
                handleChangeRowsPerPage={handleRowPerPageChange}
                style={{
                  borderBottomLeftRadius:
                    result?.data?.totalUsers && result?.data?.totalUsers > 9
                      ? "0px"
                      : "10px",
                  borderBottomRightRadius:
                    result?.data?.totalUsers && result?.data?.totalUsers > 9
                      ? "0px"
                      : "10px",
                }}
              />
            </>
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "50vh" : "75vh",
            }}
          >
            <NoDataComponent title={t("ACTIVITY.NO_ACTIVITY")} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default Activity;

//
