import { Box, colors, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import CustomHeader from "../../shared/custom/CustomHeader";
import ChangePasword from "./ChangePassword";
import ChangeStatus from "./ChangeStatus";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useEffect, useRef } from "react";
import { useLazyGetUserLogsQuery } from "../../data/endpoints/app.endpoints";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import { useMemo } from "react";
import UserLog from "./UserLogger";
import OvertimeCompensationModal from "./OvertimeCompensationModal";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import useIsTablet from "../../shared/hooks/useIsTablet";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import VacanciesCompensationModal from "./VacanciesCompensationModal";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ViewUser = () => {
  const { id } = useParams();
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const [userLog, userLogResults] = useLazyGetUserLogsQuery();
  const { t } = useTranslation();

  const {
    firstName,
    lastName,
    role,
    email,
    hiringDate,
    status,
    endOfContract,
  } = useSelector(selectEmployeeById(id));

  const UserInformations = [
    {
      section: `${t("ROLE")}`,
      value: role,
    },
    {
      section: `${t("EMAIL")}`,
      value: email,
    },
    {
      section: `${t("HIRING_DATE")}`,
      value: moment(hiringDate).format("DD MMM YYYY"),
    },
    {
      section: `${t("STATUS")}`,
      value: status,
    },
    {
      section: `${t("END_CONTRACT")}`,
      value: endOfContract,
    },
  ];
  useEffect(() => {
    userLog(id);
  }, [id]);

  const pdRef = useRef();

  const logData = userLogResults?.data;

  const dataValues = useMemo(() => {
    let data: Object[] = [];
    if (logData) {
      logData?.map((info: any) => {
        data.push({
          Actions: info?.activityType as string,
          Description: info?.description as string,
          ActionBy: info?.actionBy as string,
          Time: (info?.createdAt.split("T")[0] +
            " | " +
            info?.createdAt.split("T")[1].split(".")[0]) as string,
        });
      });
      return data;
    }
  }, [logData]);

  const downloadPdf = () => {
    const headers = [
      `${t("ACTION")}`,
      `${t("DESCRIPTION")}`,
      `${t("MY_PROFILE.PDF_HEADERS.ACTIONBY")}`,
      `${t("MY_PROFILE.PDF_HEADERS.TIME")}`,
    ];
    const doc = new jsPDF({ orientation: "landscape" });

    const tableData = dataValues;

    doc.table(1, 1, tableData as any, headers, { autoSize: true }) as any;
    doc.save(` ${firstName + " " + lastName}: ProfileData.pdf`);
  };

  const isTablet = useIsTablet();

  return (
    <>
      <CustomHeader
        title={t("USERS_ADMIN.VIEW_USER.TITLE")}
        subtitle={t("USERS_ADMIN.VIEW_USER.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: isTablet ? "20%" : "10%",
            display: "flex",
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={-1}
        >
          <KeyboardArrowLeftIcon />

          {isMobile ? null : `${t("GO_BACK")}`}
        </CustomNavigationButton>
      </CustomHeader>
      <Box
        style={{
          width: "100%",
          height: isMobile ? "auto" : "80vh",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "flex-start",
          overflow: "hidden",
          gap: isMobile ? 10 : 30,
          marginTop: isDesktop || isLargeScreen ? "2.5%" : "0%",
        }}
      >
        <Box
          style={{
            width: isMobile ? "100%" : "25%",
            height: isMobile ? "auto" : isLargeScreen ? "80vh" : "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 20,
            overflow: "hidden",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            padding: isMobile ? "5%" : "1%",
            marginTop: isMobile ? "1vh" : "0",
            background: color.component,
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <small>{t("USERS_ADMIN.VIEW_USER.USER_DETAILS")}</small>
            <Box
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                marginTop: "2vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: color.invert,
                color: color.white,
              }}
            >
              <h1>{firstName[0] + lastName[0]}</h1>
            </Box>
            <h3>{firstName + " " + lastName}</h3>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {UserInformations?.map((info: any, index: number) => (
              <li
                key={index}
                style={{
                  width: "100%",
                  height: "4vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "1vh",
                }}
              >
                <b>{info?.section}</b>{" "}
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: info?.value?.length > 10 ? "10px" : "auto",
                  }}
                >
                  {info?.value || "- - "}
                </p>
              </li>
            ))}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              gap: 1,
              overflow: "auto",
            }}
          >
            <ChangePasword id={id} name={firstName} />
            <VacanciesCompensationModal id={id} name={firstName} />
            <OvertimeCompensationModal id={id} name={firstName} />
            <ChangeStatus id={id} name={firstName} />
          </Box>
        </Box>

        <Box
          style={{
            width: "100%",
            height: isMobile ? "auto" : "80vh",
            overflow: "hidden",
            marginTop: isMobile ? "2vh" : "0",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.12)",
            borderRadius: "5px",
            padding: isMobile ? "5%" : "1%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            color: "gray",
            background: color.component,
          }}
        >
          {logData?.length > 0 ? (
            <button
              onClick={downloadPdf}
              style={{
                border: "none",
                fontWeight: 600,
                padding: "1%",
                borderRadius: "5px",
                background: colors.red[50],
                color: colors.red[700],
                alignSelf: "flex-end",
                cursor: "pointer",
              }}
            >
              {t("USERS_ADMIN.VIEW_USER.DOWNLOAD_PDF")}
            </button>
          ) : (
            ""
          )}
          <Box
            ref={pdRef}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              style={{
                width: "92%",
                height: "10%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "1vh",
                marginTop: "1vh",
              }}
            >
              <p
                style={{
                  color: color.invert,
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  fontWeight: 500,
                }}
              >
                {t("USERS_ADMIN.VIEW_USER.USER_LOG_TIMELINE")}
              </p>
            </Box>
            <Box
              style={{
                width: "100%",
                maxHeight: isMobile ? "60vh" : "calc(90% - 2vh)",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                position: "relative",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflowY: "auto",
                  marginBottom: "7vh",
                }}
              >
                {logData
                  ?.slice("")
                  .reverse()
                  .map((log: any, index: number) => {
                    return (
                      <UserLog
                        key={index}
                        activityType={log?.activityType}
                        actionBy={log?.actionBy}
                        createdAt={log?.createdAt}
                        description={log?.description}
                        hours={log?.hours}
                        logDate={log?.logDate}
                      />
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewUser;
