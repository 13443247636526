import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TopBar from "../TopBar";
import { SideNav } from "../SideNav";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import { MobileSideNav } from "../MobileSideNav";
import { useState } from "react";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";

const Sidebar = ({ children }: any) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isLargeScreen = useIsLargeScreen();
  const [collapsed, setCollapsed] = useState({
    bottom: false,
    left: false,
  });
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <CssBaseline />
      {isMobile || isTablet ? (
        <MobileSideNav collapsed={collapsed} setCollapsed={setCollapsed} />
      ) : (
        <SideNav />
      )}
      <Box
        component="main"
        sx={{
          display: "flex",
          pl: 2,
          pr: 2,
          width: "100%",
          height: "auto",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            margin: 0,
            padding: 1,
            width: isLargeScreen ? "1920px" : "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "65px",
              backdropFilter: `${
                isMobile || isTablet
                  ? "saturate(200%) blur(10px)"
                  : "transparent"
              }`,
              position: isMobile ? "sticky" : "static",
              top: "0px",
              display: "flex",
              alignItems: "flex-end",
              marginBottom: "2vh",
              zIndex: 1900,
              borderRadius: "0 0 9px 9px",
            }}
          >
            <TopBar setCollapsed={setCollapsed} />
          </Box>
          <Box
            sx={{
              height: "auto",
              overflow: "auto",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Sidebar;
