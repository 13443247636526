import { useContext } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomDatePicker from "../../shared/CustomDatePicker";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { UserRole } from "../../utils/enums/user-role.enum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useIsMobile from "../../shared/hooks/useIsMobile";

interface EmployeeFormFieldsProps {
  handleSubmit: any;
  values: any;
  touched: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  children?: any;
  id?: string;
  handleMutation: any;
  buttonTitle: string;
  resetForm?: any;
  submitIsLoading?: boolean;
  initialValues?: {
    email: string;
    password: string;
    firstName: string;
    confirmPassword: string;
    lastName: string;
    totalVacations: string;
    workingHours: string;
    role: string;
    hiringDate: Date;
  };
}

const EmployeeFormFields = ({
  handleSubmit,
  values,
  touched,
  handleChange,
  errors,
  setFieldValue,
  children,
  id,
  handleMutation,
  buttonTitle,
  resetForm,
  submitIsLoading,
  initialValues,
}: EmployeeFormFieldsProps) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);
  const navigate = useNavigate();
  const handleSubmitDialog = () => {
    const {
      firstName,
      hiringDate,
      lastName,
      role,
      email,
      status,
      password,
      totalVacations,
      workingHours,
      totalOvertimes,
    } = values;
    if (id)
      handleMutation({
        userId: id,
        email: email.toLowerCase(),
        firstName,
        lastName,
        hiringDate,
        role,
        status,
        totalVacations,
        workingHours,
        totalOvertimes: totalOvertimes * 60,
      }).then((res: any) => {
        if ("data" in res) {
          showSnackbar(
            `${t("TEXT_RESPONSE.EMPLOYEE_UPDATED_SUCCESSFULLY")}`,
            false,
            true
          );
          navigate("/employees");
        } else if (res.error) {
          showSnackbar(res.error.data.error.message, true, true);
        }
        setOpenModal(false);
      });
    else {
      handleMutation({
        email: email.toLowerCase(),
        firstName,
        lastName,
        hiringDate,
        role,
        password,
        totalVacations: totalVacations ? totalVacations : 0,
        totalOvertimes: totalOvertimes * 60,
        workingHours,
      }).then((res: any) => {
        if (!res.error) {
          showSnackbar(
            `${t("TEXT_RESPONSE.EMPLOYEE_CREATED_SUCCESSFULLY")}`,
            false,
            true
          );
          resetForm(initialValues);
        } else if (res.error) {
          showSnackbar(res.error.data.error.message, true, true);
        }
        setOpenModal(false);
      });
    }

    setOpenModal(false);
  };
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            inputProps={{ style: { fontSize: "16px" } }}
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            label={t("FIRST_NAME")}
            id="firstName"
            color="info"
            onChange={handleChange}
            value={values.firstName}
            name="firstName"
            error={!!touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            fullWidth
            variant="outlined"
            inputProps={{ style: { fontSize: "16px" } }}
            type="text"
            label={t("LAST_NAME")}
            size="small"
            id="lastName"
            color="info"
            onChange={handleChange}
            value={values.lastName}
            name="lastName"
            error={!!touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            inputProps={{
              style: { fontSize: "16px" },
              form: {
                autocomplete: "off",
              },
            }}
            id="email"
            label={t("EMAIL_ADDRESS")}
            name="email"
            autoComplete="email"
            size="small"
            value={values.email}
            color="info"
            onChange={handleChange}
            error={!!touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />
        </Grid>
        {children}
        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            size="small"
            label={`${t("USERS_ADMIN.CREATE_USER.TOTAL_VACATIONS")} (${t(
              "OPTIONAL"
            )})`}
            id="totalVacations"
            onChange={handleChange}
            inputProps={{ style: { fontSize: "16px" } }}
            value={values.totalVacations}
            name="totalVacations"
            error={!!touched.totalVacations && !!errors.totalVacations}
            helperText={touched.totalVacations && errors.totalVacations}
            color="info"
          />
        </Grid>

        <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            label={t("USERS_ADMIN.WORKING_HOURS")}
            size="small"
            id="workingHours"
            inputProps={{ style: { fontSize: "16px" } }}
            onChange={handleChange}
            value={values.workingHours}
            name="workingHours"
            error={!!touched.workingHours && !!errors.workingHours}
            helperText={touched.workingHours && errors.workingHours}
            color="info"
          />
        </Grid>

        <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            inputProps={{ style: { fontSize: "16px" } }}
            id="totalOvertimes"
            label={t("USERS_ADMIN.TOTAL_OVERTIME")}
            name="totalOvertimes"
            autoComplete="off"
            size="small"
            value={values.totalOvertimes}
            color="info"
            onChange={handleChange}
            error={!!touched.totalOvertimes && !!errors.totalOvertimes}
            helperText={touched.totalOvertimes && errors.totalOvertimes}
          />
        </Grid>
        <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              position: "relative",
              "& .MuiInputLabel-root": {
                position: "absolute",
                top: "-15%",
                pointerEvents: "none",
              },
              "& .MuiInputLabel-shrink": {
                top: 0,
              },
              "& .Mui-focused.MuiInputLabel-root": {
                top: 0,
              },
            }}
          >
            <InputLabel htmlFor="role" id="role-label" color="info">
              {t("ROLE")}
            </InputLabel>
            <Select
              name="role"
              label={t("ROLE")}
              id="role"
              size="small"
              value={values.role}
              onChange={handleChange}
              error={!!touched.role && !!errors.role}
            >
              {Object?.values(UserRole).map((value, index: number) => (
                <MenuItem key={index} value={value}>
                  {t(`ROLES.${value.toUpperCase()}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography
            sx={{
              color: "#d9534f",
              fontSize: "10px",
              margin: "5px 0px 0px 14px",
            }}
            variant="inherit"
          >
            {errors.role as any}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
          <CustomDatePicker
            onChange={(value) =>
              setFieldValue(
                "hiringDate",
                value ? value.format("YYYY-MM-DD") : "",
                true
              )
            }
            fullWidth={true}
            value={values?.hiringDate}
            error={errors?.hiringDate}
            touched={!!touched?.hiringDate}
            label={t("USERS_ADMIN.HIRING_DATE")}
            name="hiringDate"
            views={["day"]}
            openTo="day"
          />
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <AlertDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          buttonTitle={
            submitIsLoading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              buttonTitle
            )
          }
          width={isMobile ? "100%" : "auto"}
          values={values}
          handleSubmitDialog={handleSubmitDialog}
          color={BLUE_COLOR_ACCENT}
          colorHover={BLUE_HOVER_COLOR_ACCENT}
          mt="20px"
        >
          {id
            ? `${t("USERS_ADMIN.EDIT_USER.UPDATE_USER_MODAL_TEXT")}`
            : `${t("USERS_ADMIN.CREATE_USER.CREATE_USER_MODAL_TEXT")}`}
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default EmployeeFormFields;
