import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tokens } from "../../core/AppStyles";
import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  useTheme,
} from "@mui/material";
import { PrevIcon, NextIcon } from "./CustomEditIcons";
import useIsMobile from "../hooks/useIsMobile";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import CustomSelector from "./CustomSelector";
import { useTranslation } from "react-i18next";

interface CustomTableProps {
  tableTitles: string[];
  tableBodyRenderer: JSX.Element[];
  isPaginated: boolean;
  rowsPerPage?: number;
  page?: number;
  count?: number;
  mt?: number;
  width?: string;
  handleChangePage?: (event: any, newPage: number) => void;
  handleChangeRowsPerPage?: (event: any) => void;
  style?: any;
}

const CustomTable = ({
  tableTitles,
  tableBodyRenderer,
  rowsPerPage,
  page,
  isPaginated,
  handleChangePage,
  handleChangeRowsPerPage,
  count,
  mt,
  width,
  style,
}: CustomTableProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();
  const isLargeScreen = useIsLargeScreen();
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        borderRadius: "20px",
        boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
        marginTop: `${mt}%`,
        width,
      }}
    >
      <TableContainer
        sx={{
          scrollBehavior: "smooth",
          maxHeight: isMobile ? "auto" : "60vh",
          height: "20%",
          borderRadius: "10px",
          scrollbarWidth: "thin",
          ...style,
        }}
      >
        <Table
          aria-label="simple table"
          stickyHeader
          sx={{
            borderRadius: "5px",
            boxShadow: "0 0 20px 10px rgba(0 0 0, 0.4 )",
          }}
        >
          <TableHead
            sx={{
              "& th": {
                background: colors.component,
                color: colors.invert,
                fontWeight: 700,
              },
            }}
          >
            <TableRow
              sx={{
                color: colors.grey[400],
              }}
            >
              {tableTitles.map((title, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sx={{
                    fontSize: isLargeScreen ? "0.9rem" : "auto",
                  }}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              backgroundColor: colors.component,
            }}
          >
            {tableBodyRenderer}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginated ? (
        <Stack
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent={isMobile ? "center" : "right"}
          spacing={1}
          padding={2}
          sx={{
            backgroundColor: colors.component,
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <p
              style={{
                color: "gray",
              }}
            >
              {t("ROWS")}:
            </p>
            <CustomSelector
              size="medium"
              value={rowsPerPage}
              onSelectChange={handleChangeRowsPerPage}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={count}>
                {t("LEAVE_REQUESTS.STATUS.ALL")}
              </MenuItem>
            </CustomSelector>

            <Pagination
              color="secondary"
              shape="rounded"
              count={count && rowsPerPage && Math.ceil(count / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ backgroundColor: colors.timeline }}
                  slots={{ previous: PrevIcon, next: NextIcon }}
                  {...item}
                />
              )}
            />
          </Box>
        </Stack>
      ) : null}
    </Paper>
  );
};

export default CustomTable;
