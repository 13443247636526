import { Field } from "formik";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Popper, useTheme } from "@mui/material";
import moment from "moment-timezone";
import { tokens } from "../core/AppStyles";
import dayjs from "dayjs";

interface FieldArrayTimePickerProps {
  label: string;
  name: string;
  sx?: any;
  touched: any;
  errors: any;
  disabled?: boolean;
}

const FieldArrayTimePicker = ({
  label,
  name,
  sx,
  touched,
  errors,
  disabled,
}: FieldArrayTimePickerProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Field
      name={name}
      children={({ field, form }: any) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            format="HH:mm"

            slots={{
              popper: (props) => (
                <Popper
                  {...props}
                  sx={{
                    "& .MuiPickersLayout-root": {
                      backgroundColor: colors.component,
                    },
                    "& .MuiButton-root": {
                      color: colors.invert,
                    },
                    "& .MuiList-padding.MuiMultiSectionDigitalClockSection-root": {
                      overflow: 'scroll',
                      scrollbarWidth: 'none'
                    },
                    zIndex: 9999
                  }}
                />
              ),
            }}
            label={label}
            value={dayjs(field.value)}
            disableOpenPicker={false}
            disabled={disabled}
            onChange={(newValue) => {
              if (newValue) {
                const timezoneLocal = moment.tz.guess();
                const datetime = moment(newValue.toDate());
                const dateInTimezone = datetime.tz(timezoneLocal);
                form.setFieldValue(
                  field.name,
                  dateInTimezone.format("YYYY-MM-DD HH:mm:ss"),
                  true
                );
              } else if (newValue === null) {
                form.setFieldValue(field.name, "", true);
              }
            }}
            slotProps={{
              textField: {
                color: "info",
                variant: "outlined",
                size: "small",
                inputProps: { style: { fontSize: "16px" } },
                error: !!touched && !!errors,
                sx,
                disabled,
              },
            }}
            ampm={false}
            ampmInClock={false}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default FieldArrayTimePicker;
