import { Popper, useTheme } from "@mui/material";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { tokens } from "../core/AppStyles";
import dayjs from "dayjs";
import CustomCalendarPicker from "./custom/CustomCalendarPicker";

interface CustomDatePickerProps {
  value: any | null;
  onChange?:
    | ((
        value: dayjs.Dayjs | null,
        context: PickerChangeHandlerContext<DateValidationError>
      ) => void)
    | undefined;
  label?: any;
  name?: string;
  error?: any;
  touched?: boolean;
  views?: any;
  inputFormat?: any;
  openTo?: any;
  shouldDisableDate?: boolean;
  fullWidth?: boolean;
}

const CustomDatePicker = ({
  onChange,
  value,
  label,
  name,
  error,
  touched,
  views,
  inputFormat,
  openTo,
  shouldDisableDate,
  fullWidth = false,
}: CustomDatePickerProps) => {
  const theme = useTheme();
  const customColors = tokens(theme.palette.mode);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        format={inputFormat || "DD/MM/YYYY"}
        slots={{
          day: CustomCalendarPicker,
          popper: (props) => (
            <Popper
              {...props}
              placement="top-end"
              sx={{
                "& .MuiCalendarPicker-root": {
                  backgroundColor: customColors.component,
                },
                zIndex: "9999 !important",
              }}
            />
          ),
        }}
        slotProps={{
          textField: {
            color: "info",
            variant: "outlined",
            size: "small",
            inputProps: { style: { fontSize: "16px" } },
            error: touched && !!error,
            helperText: error,
            fullWidth,
            name,
          },
        }}
        value={dayjs(value)}
        onChange={onChange}
        views={views}
        openTo={openTo}
        disableFuture={shouldDisableDate}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
