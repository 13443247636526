import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogProps,
  Box,
  useTheme,
  Slide,
} from "@mui/material";
import {
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
  Ref,
} from "react";
import CustomButton from "../../shared/custom/CustomButton";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import { useChangeUserStatusMutation } from "../../data/endpoints/app.endpoints";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { selectLoggedUser } from "../../data/store/authSlice";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { useTranslation } from "react-i18next";
import { TransitionProps } from "@mui/material/transitions";
import { handleRequiredValidation } from "../../utils/regex/formRegex";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IuserId {
  id: string | undefined;
  name: string;
}

const ChangeStatus = ({ id, name }: IuserId) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const { showSnackbar } = useContext(RootContext);
  const user = useSelector(selectEmployeeById(id));
  const loggedUser = useSelector(selectLoggedUser);
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const [changeUserStatus] = useChangeUserStatusMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const initialValues = {
    id,
    endOfContract: user?.endOfContract ? user.endOfContract : "",
    fullName: loggedUser?.firstName + " " + loggedUser?.lastName,
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof values> = {};

    handleRequiredValidation(
      values,
      errors,
      "endOfContract",
      t("USERS_ADMIN.VIEW_USER.DATE_NOT_SELECTED")
    );

    return errors;
  };

  const onSubmit = ({ id, endOfContract, fullName }: typeof initialValues) => {
    changeUserStatus({
      id,
      endOfContract,
      fullName,
    }).then((res: any) => {
      if (res?.data) {
        showSnackbar(
          `${t("USERS_ADMIN.VIEW_USER.STATUS_CHANGED")}`,
          false,
          true
        );
        setOpen(false);
      }
    });
  };

  return (
    <>
      <CustomButton
        title={t("USERS_ADMIN.VIEW_USER.CHANGE_STATUS")}
        width="100%"
        onClick={handleClickOpen("paper")}
        color={BLUE_COLOR_ACCENT}
        colorHover={BLUE_HOVER_COLOR_ACCENT}
      />

      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ style: { background: colors.component, margin: 0 } }}
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box
              style={{
                width: isMobile ? "80vw" : "100%",
                minHeight: isMobile
                  ? "24vh auto"
                  : isLargeScreen
                  ? "10vh"
                  : "21vh auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3
                style={{
                  color: colors.invert,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  fontSize: isMobile ? "12px" : "auto",
                  marginBottom: isMobile ? "1vh" : "0",
                }}
              >
                <PublishedWithChangesIcon
                  sx={{
                    marginRight: "0.5vh",
                  }}
                />{" "}
                {t("USERS_ADMIN.VIEW_USER.CHANGE_STATUS_WARNING", {
                  name: name,
                })}
              </h3>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginTop: "2vh",
                }}
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validate={validate}
                >
                  {({ values, handleSubmit, setFieldValue, errors }) => (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        width: "85%",
                      }}
                    >
                      <CustomDatePicker
                        onChange={(value) =>
                          setFieldValue(
                            "endOfContract",
                            value ? value.format("YYYY-MM-DD") : "",
                            true
                          )
                        }
                        error={errors?.endOfContract}
                        touched={true}
                        value={values.endOfContract}
                        label={t("END_CONTRACT")}
                        name="endOfContract"
                        views={["day"]}
                        openTo="day"
                      />
                      <CustomButton
                        type="submit"
                        title={t("USERS_ADMIN.VIEW_USER.CONFIRM_CHANGE")}
                        width="100%"
                        margin="4vh 0 0 0"
                        color={BLUE_COLOR_ACCENT}
                        colorHover={BLUE_HOVER_COLOR_ACCENT}
                      />
                    </Box>
                  )}
                </Formik>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangeStatus;
