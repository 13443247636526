/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeader from "../../shared/custom/CustomHeader";
import useIsDesktop from "../../shared/hooks/useIsDesktop";
import { useNavigate } from "react-router";
import {
  useLazyCheckUsersWithUncomletedTTQuery,
  useLazyGetAdminDashboardQuery,
} from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SingleCardDashboard from "./testDashboard/DashboardComponents/SingleCard-Dashboard";
import ModalDashboardLegend from "./testDashboard/DashboardComponents/ModalDashboardLegend";
import LeaveRequestNotifyComponent from "./testDashboard/DashboardComponents/LeaveRequestNotifyComponent";
import { useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { useTranslation } from "react-i18next";
import UsersNotCompletedTimeTracker from "./testDashboard/AdminDahboardComponents/UsersNotCompletedTimeTracker";
import moment from "moment";
import {
  DirectionsCarFilled,
  AirportShuttle,
  Folder,
} from "@mui/icons-material/";

const DashboardAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const isLargeScreen = useIsLargeScreen();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);

  const [adminDashboard, adminDashboardResult] =
    useLazyGetAdminDashboardQuery();

  const { t } = useTranslation();

  useEffect(() => {
    adminDashboard(undefined);
  }, []);
  const attendance = adminDashboardResult?.data?.allAbsents?.length;
  const subtitle: string = `${t("DASHBOARD.SUBTITLE")} ${user?.firstName}!`;

  const { adminDashboardDatas } = useSelector(
    (state: any) => state.adminDashboard
  );

  const handleNavigate = () => {
    navigate("/absents");
  };

  const leavesPending = adminDashboardResult?.data?.pendingLeaves;

  const handleNavigateToEmployess = () => {
    navigate("/employees");
  };

  const navigateToTimeTracker = (e: any) => {
    e.preventDefault();
    navigate("/view-time-trackers");
  };

  const handleNavigateToVehicles = () => {
    navigate("/vehicles");
  };

  const navigateToVehicleInsights = (e: any) => {
    e.preventDefault();
    navigate("/vehicle-insights");
  };

  const navigateToFileManager = () => {
    navigate("/file-manager/upload");
  };

  const [IncompleteTT, resultIncompleteTT] =
    useLazyCheckUsersWithUncomletedTTQuery();

  const firstDayOfMonth = moment().startOf("month").subtract(2, "weeks");
  const endDate = moment().subtract(1, "days");
  const numberOfDays = endDate.diff(firstDayOfMonth, "days");

  useEffect(() => {
    IncompleteTT({
      startDate: firstDayOfMonth.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });
  }, []);

  const UnCompletTimeTrackers = resultIncompleteTT?.data;

  let col = "";
  let backCol = "";
  user?.role == "Admin"
    ? (col = "#EE9932") && (backCol = "#EE9632")
    : (col = "black") && (backCol = "#78D6C6");

  const handleUserRoleKeyTranslation = () => {
    if (user?.role === "Admin") return t("ROLES.ADMIN");
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: `${isMobile ? "100%" : "auto"}`,
        }}
      >
        <CustomHeader
          title={t("DASHBOARD.TITLE")}
          subtitle={`${t("DASHBOARD.SUBTITLE")} ${user?.firstName}`}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "auto",
            width: `${isMobile ? "25%" : "17%"}`,
            gap: isMobile ? "2%" : 0,
          }}
        >
          <p
            style={{
              padding: isMobile ? "3%" : "1%",
              borderRadius: "5px",
              margin: 0,
              color: `${col}`,
              fontSize: `${
                isMobile ? "12px" : isLargeScreen ? "18px" : "auto"
              }`,
              border: `1px solid ${backCol}`,
              width: isMobile ? "100%" : "50%",
              textAlign: "center",
              overflow: 'hidden'
            }}
          >
            {handleUserRoleKeyTranslation()}
          </p>
          {isMobile || isTablet ? "" : <ModalDashboardLegend />}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "100%",
          height: "auto",
          mt: "2%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile || isTablet ? "column" : "row"}`,
            justifyContent: isDesktop ? "flex-start" : "space-between",
            alignItems: isDesktop ? "center" : "center",
            width: "100%",
            gap: isDesktop ? 3 : 0,
            height: `${
              isMobile || isTablet ? "auto" : isLargeScreen ? "30vh" : "25vh"
            }`,
          }}
        >
          <Box
            style={{
              width: `${isMobile || isTablet ? "100%" : "33%"}`,
              height: "100%",
              display: "flex",
              flexDirection: isMobile ? "column" : isTablet ? "row" : "column",
              justifyContent: "space-between",
              alignItems: isTablet ? "center" : "auto",
              padding: "0",
              gap: isMobile ? 4 : 0,
            }}
          >
            <Box
              sx={{
                width: `${isMobile ? "100%" : isTablet ? "30%" : "100%"}`,
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToTimeTracker}
            >
              <AccessTimeIcon
                style={{
                  color: "#0088FE",
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.SEE_COMPLETED_TIME_TRACKERS")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
            <Box
              sx={{
                width: `${isMobile ? "100%" : isTablet ? "30%" : "100%"}`,
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToVehicleInsights}
            >
              <AirportShuttle
                style={{
                  color: "orange",
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.SEE_VEHICLE_INSIGHTS")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
            <Box
              sx={{
                width: `${isMobile ? "100%" : isTablet ? "30%" : "100%"}`,
                height: isTablet ? "75%" : "28%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow: "1px 1px 3px 0.5px rgba(0, 0, 0, 0.08)",
                background: colors.component,
                margin: 0,
                padding: "0 2% 0 2%",
                cursor: "pointer",
              }}
              onClick={navigateToFileManager}
            >
              <Folder
                style={{
                  color: "#00C49F",
                  fontSize: isLargeScreen ? "30px" : "auto",
                }}
              />
              <p
                style={{
                  width: "80%",
                  fontSize: isLargeScreen ? "1.1rem" : "auto",
                }}
              >
                {t("DASHBOARD.ADMIN.FILE_DETAILS")}
              </p>
              <ArrowForwardIosIcon color="inherit" />
            </Box>
          </Box>
          <Box
            sx={{
              width: `${
                isMobile || isTablet ? "100%" : isLargeScreen ? "36%" : "38%"
              }`,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              gap: 1,
              height: `${isMobile ? "auto" : isLargeScreen ? "100%" : "100%"}`,
              // marginTop: `${isMobile ? "2vh" : "0vh"}`,
            }}
          >
            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  height={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  fill="orange"
                  className="bi bi-people-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                </svg>
              }
              number={adminDashboardResult?.data?.totalUsers?.length}
              // smallString={t("STATUSES.ALL")}
              title={t("USERS")}
              clickFunction={handleNavigateToEmployess}
            />

            <SingleCardDashboard
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  height={isLargeScreen ? "35" : isTablet ? "23" : "22"}
                  fill="#00C49F"
                  className="bi bi-person-vcard-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                </svg>
              }
              number={attendance}
              // smallString={t("DASHBOARD.ADMIN.TODAY_ATTENDANCE")}
              title={t("DASHBOARD.ADMIN.SEE_WHO_IS_ABSENT")}
              clickFunction={handleNavigate}
            />
            <SingleCardDashboard
              icon={<DirectionsCarFilled color="secondary" fontSize="large" />}
              number={adminDashboardResult?.data?.totalVehicles}
              // smallString={t("STATUSES.ALL")}
              title={t("DASHBOARD.ADMIN.SEE_ALL_VEHICLES")}
              clickFunction={handleNavigateToVehicles}
            />
          </Box>
          <Box
            sx={{
              width: isLargeScreen
                ? "33%"
                : isMobile || isTablet
                ? "100%"
                : isDesktop
                ? "40%"
                : "auto",
              height: "100%",
              // marginTop: isTablet ? "2vh" : "0",
            }}
          >
            <LeaveRequestNotifyComponent leavesPending={leavesPending} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: `${isMobile ? "column" : "row"}`,
            justifyContent: "space-between",
            alignItems: isDesktop ? "center" : "flex-start",
            width: "100%",
            height: `${isMobile ? "auto" : "100%"}`,
            paddingTop: "2vh",
          }}
        >
          <Box
            sx={{
              width: isMobile || isTablet ? "100%" : "100%",
              height: "auto",
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: isMobile || isTablet ? "" : "flex-start",
              flexWrap: "wrap",
              paddingBottom: "1vh",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "100%",
                height: "auto",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "8px",
                boxShadow: `${colors.shadow} 1px 1px 5px 1px`,
                marginTop: isMobile ? "2vh" : "2vh",
              }}
            >
              <UsersNotCompletedTimeTracker
                data={UnCompletTimeTrackers}
                nrDays={numberOfDays}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardAdmin;
