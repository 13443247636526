import React from "react";
import { Box, Typography } from "@mui/material";
import NoData from "../assets/no-data.png";
import useIsMobile from "./hooks/useIsMobile";

interface NoDataComponentProps {
  title: string;
}

const NoDataComponent = ({ title }: NoDataComponentProps) => {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ textAlign: "center" }}>
      <img
        style={{ opacity: "0.6", marginBottom: "3%" }}
        src={NoData}
        alt="No Data"
        width={isMobile ? "60px" : "100px"}
        height={isMobile ? "60px" : "100px"}
      />
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
};

export default NoDataComponent;
