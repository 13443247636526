import { useContext } from "react";
import { Formik } from "formik";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import {
  LeaveRequestStatus,
  OutOfOffice,
} from "../../utils/enums/out-of-office.enum";
import CustomSelector from "../../shared/custom/CustomSelector";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import { useSendLeaveRequestMutation } from "../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import BoxContainer from "../../shared/BoxContainer";
import {
  handleIntervalDateValidation,
  handleRequiredValidation,
  timeIntervalEndTimeSmaller,
} from "../../utils/regex/formRegex";
import CustomDatePicker from "../../shared/CustomDatePicker";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import moment from "moment";
import { useNavigate } from "react-router";
import { LeaveDays } from "../../utils/enums/leave.enum";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { useTranslation } from "react-i18next";
import { tokens } from "../../core/AppStyles";

const LeaveRequest = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [sendLeaveRequest, result] = useSendLeaveRequestMutation();
  const loggedUser = useSelector(selectLoggedUser);

  const handleSubmitDialog = (values: typeof initialValues, resetForm: any) => {
    sendLeaveRequest({
      ...values,
      type: values?.type,
      interval: values?.interval,
      endDate:
        values?.interval !== LeaveDays.MANY_DAYS
          ? values?.startDate
          : values?.endDate,
      userId: loggedUser?.userId,
    }).then((onfulfilled: any) => {
      if (onfulfilled?.data) {
        resetForm(initialValues);
        showSnackbar(
          `${t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.SUCCESS")}`,
          false,
          true
        );
        navigate("/leave-request");
      } else if (onfulfilled?.error) {
        showSnackbar(
          `${t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.ERROR")},
            ${onfulfilled?.error.data.error.message}`,
          true,
          true
        );

        onfulfilled?.error?.data?.error?.message.includes("email") &&
          navigate("/leave-request");
      }
    });
    setOpenModal(false);
  };
  const initialValues = {
    type: "",
    startDate: "",
    endDate: "",
    description: "",
    status: LeaveRequestStatus.PENDING,
    interval: LeaveDays.ONE_DAY,
  };
  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof initialValues> = {};
    handleRequiredValidation(
      values,
      errors,
      "startDate",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    if (
      !moment(values?.startDate).isValid() &&
      values?.interval !== LeaveDays.MANY_DAYS
    ) {
      errors.startDate = t(
        "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_DATE"
      );
    }

    if (values?.interval === LeaveDays.MANY_DAYS) {
      handleRequiredValidation(values, errors, "endDate", t("FORM_VALIDATIONS.REQUIRED"));
      timeIntervalEndTimeSmaller(
        errors,
        "endDate",
        {
          startTime: values.startDate,
          endTime: values.endDate,
        },
        {
          startTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.START_DATE"
          ),
          endTimeNotValid: t(
            "FORM_VALIDATIONS.TIME_TRACKER_VALIDATIONS.NOT_VALID.END_DATE"
          ),
        },
        t("FORM_VALIDATIONS.END_DATE_SMALLER_OR_EQUAL")
      );

    }

    handleRequiredValidation(
      values,
      errors,
      "type",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    // }


    return errors;
  };
  const onSubmit = () => {
    setOpenModal(true);
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <BoxContainer>
      <CustomHeader
        title={t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.TITLE")}
        subtitle={t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: isTablet ? "20%" : "10%",
            display: "flex",
            alignSelf: "center",
            fontSize: "12px",
            gap: 1,
            ":hover": {
              bgcolor: "#1d61c6",
              color: "white",
            },
          }}
          navigateTo={"/leave-request"}
        >
          <KeyboardArrowLeftIcon />

          {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
          resetForm,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              marginTop: 3,
              backgroundColor: colors.component,
              padding: 3,
              paddingTop: 5,
              borderRadius: 3,
            }}
          >
            <CustomSelector
              label={t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.SELECT_DAYS_OF_LEAVE")}
              value={values?.interval}
              onSelectChange={handleChange}
              width={isMobile ? "100%" : "45%"}
              name="interval"
            >
              {Object?.values(LeaveDays)?.map((value, index: number) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </CustomSelector>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                mt: 3,
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  gridColumn:
                    values?.interval !== LeaveDays.MANY_DAYS
                      ? "span 4"
                      : "span 2",
                }}
              >
                <CustomDatePicker
                  onChange={(value) =>
                    setFieldValue(
                      "startDate",
                      value ? value.format("YYYY-MM-DD") : "",
                      true
                    )
                  }
                  value={values?.startDate}
                  error={errors?.startDate}
                  label={t("START_DATE")}
                  name="startDate"
                  fullWidth
                  touched={!!touched?.startDate}
                  views={["day"]}
                  openTo="day"
                />
              </Grid>
              {values?.interval === LeaveDays.MANY_DAYS ? (
                <Grid item xs={12} sx={{ gridColumn: "span 2" }}>
                  <CustomDatePicker
                    onChange={(value) =>
                      setFieldValue(
                        "endDate",
                        value ? value.format("YYYY-MM-DD") : "",
                        true
                      )
                    }
                    fullWidth
                    value={values?.endDate}
                    error={errors?.endDate}
                    label={t("END_DATE")}
                    name="endDate"
                    touched={!!touched?.endDate}
                    views={["day"]}
                    openTo="day"
                  />
                </Grid>
              ) : null}

              <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                <CustomSelector
                  label={t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.REASON")}
                  name="type"
                  value={values.type}
                  onSelectChange={handleChange}
                  size="medium"
                  width="100%"
                  errors={errors?.type}
                  touched={touched?.type}
                >
                  {Object?.values(
                    values?.interval === LeaveDays.HALF_DAY
                      ? {
                          VACATION: OutOfOffice.VACATION,
                          COMPENSATION: OutOfOffice.COMPENSATION,
                        }
                      : OutOfOffice
                  ).map((value, index: number) => (
                    <MenuItem key={index} value={value}>
                      {t(
                        `LEAVE_REQUESTS.OUT_OF_OFFICE.${value
                          ?.replace(" ", "_")
                          .replace("/", "_")
                          .toUpperCase()}`
                      )}
                    </MenuItem>
                  ))}
                </CustomSelector>
              </Grid>

              <Grid item xs={12} sx={{ gridColumn: "span 4" }}>
                <TextField
                  variant="outlined"
                  inputProps={{ style: { fontSize: "16px" } }}
                  placeholder={t(
                    "LEAVE_REQUESTS.ADD_LEAVE_REQUEST.INSERT_DESCRIPTION"
                  )}
                  multiline
                  size="small"
                  fullWidth
                  rows={3}
                  value={values.description}
                  name="description"
                  onChange={handleChange}
                  color="info"
                />
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle={
                  result?.isLoading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    `${t("SUBMIT")}`
                  )
                }
                width="auto"
                handleSubmitDialog={() => handleSubmitDialog(values, resetForm)}
                color={BLUE_COLOR_ACCENT}
                colorHover={BLUE_HOVER_COLOR_ACCENT}
                mt="20px"
              >
                {t("LEAVE_REQUESTS.ADD_LEAVE_REQUEST.SUBMIT_TEXT")}
              </AlertDialog>
            </Box>
          </Box>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default LeaveRequest;
