import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";

const initialState: {
  dashboardDatas: [];
  weeklyWorkHours: [];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  dashboardDatas: [],
  weeklyWorkHours: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

const selectSelf = (state: RootState) => state.userDashboard;

export const selectDashboardState = createSelector(
  selectSelf,
  (state) => state.dashboardDatas
);

export const dashboardSlice = createSlice({
  name: "userDashboard",
  initialState,
  reducers: {
    resetDashboardState: () => initialState,
    getUserDashboardData: (state, { payload }) => {
      state.dashboardDatas = payload;
    },
    getWeeklyWorkedHours: (state, action) => {
      state.weeklyWorkHours = action?.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getUserDashboard.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.dashboardDatas = payload;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getUserWeeklyWorkingHours.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.weeklyWorkHours = payload;
          state.isSuccess = true;
        }
      ),
});

export const {
  resetDashboardState,
  getUserDashboardData,
  getWeeklyWorkedHours,
} = dashboardSlice.actions;
