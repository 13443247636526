import { useTheme, Dialog, DialogContent } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import PdfViewer from "./PdfViewer";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsDesktop from "../../shared/hooks/useIsDesktop";

interface Props {
  open: boolean;
  handleClose: any;
  fileUrl: string;
  fileName: string;
}

const FilePreviewModal = ({ open, handleClose, fileUrl, fileName }: Props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isPdf = fileName?.endsWith(".pdf");
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          background: colors.component,
          maxWidth: isMobile || isDesktop ? "100%" : "30%",
        },
      }}
    >
      <DialogContent>
        {isPdf ? (
          <PdfViewer pdfUrl={fileUrl} />
        ) : (
          <img src={fileUrl} alt="File Preview" style={{ width: "100%" }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal;
