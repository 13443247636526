import Button from "@mui/material/Button";

interface AlertActionButtonProps {
  variant?: "text" | "contained" | "outlined" | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  title: string;
}

const AlertActionButton = ({
  variant,
  onClick,
  title,
}: AlertActionButtonProps) => {
  return (
    <Button
      fullWidth
      sx={{
        width: "auto",
        fontSize: "90%",
        display: "flex",
        alignItems: "center",
      }}
      color="info"
      variant={variant}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default AlertActionButton;
