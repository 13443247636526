import Tooltip from "@mui/material/Tooltip";

interface ITooltip {
  descriptionText: any;
}

export default function CustomTooltip({ descriptionText }: ITooltip) {
  return (
    <div>
      <Tooltip title={descriptionText}>
        {descriptionText ? <p style={{ margin: 0 }}>{descriptionText?.slice(0, 15) + " ..."}</p> : <p style={{ margin: 0 }}></p>}
      </Tooltip>
    </div>
  );
}
