import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import useIsTablet from "../hooks/useIsTablet";

interface CustomNavigationButtonProps {
  navigateTo: any;
  children: any;
  customStyle?: any;
}
const CustomNavigationButton = ({
  navigateTo,
  children,
  customStyle,
}: CustomNavigationButtonProps) => {
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  return (
    <Button
      sx={{
        backgroundColor: BLUE_COLOR_ACCENT,
        width: isTablet ? "20%" : "15%",
        display: "flex",
        alignSelf: "center",
        fontSize: "12px",
        gap: 1,
        ":hover": {
          bgcolor: "#1d61c6",
          color: "white",
        },
        ...customStyle,
      }}
      onClick={() => navigate(navigateTo)}
      color="error"
      variant="contained"
    >
      {children}
    </Button>
  );
};

export default CustomNavigationButton;
