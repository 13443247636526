import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import { To, useNavigate } from "react-router";
import useIsMobile from "../hooks/useIsMobile";
import useIsTablet from "../hooks/useIsTablet";

interface CustomHeaderProps {
  title: string;
  btnTitle?: any;
  btnNavLink?: To;
  subtitle?: string;
  children?: any;
  customStyle?: any;
}

const CustomHeaderMobile = ({
  title,
  btnTitle,
  btnNavLink,
  subtitle,
  children,
  customStyle,
}: CustomHeaderProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        borderRadius: 1,
        maxHeight: "420px",
        alignItems: "center",
        marginBottom: 1,
        ...customStyle,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "70%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant={isMobile || isTablet ? "h4" : "h3"}
            color={colors.grey[100]}
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "12px" }} color="#007ffc">
            {subtitle}
          </Typography>
        </Box>
        {btnNavLink && (
          <button
            onClick={() => btnNavLink && navigate(btnNavLink)}
            style={{
              width: "18%",
              border: "none",
              height: "40px",
              borderRadius: "8px",
              fontSize: "1.6rem",
              backgroundColor: colors.blueAccent[400],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: colors.black,
              cursor: "pointer",
              boxShadow: "rgba(0, 127, 252, 0.2) 0px 0px 6px 4px",
            }}
          >
            {btnTitle}
          </button>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CustomHeaderMobile;
