import { useEffect, useMemo, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";

import HolidayTableRow from "./HolidayTableRow";
import CustomTable from "../../shared/custom/CustomTable";
import { Box } from "@mui/material";
import LoadingSpinner from "../../shared/LoadingSpinner";
import CustomButton from "../../shared/custom/CustomButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import { useNavigate } from "react-router";
import useIsMobile from "../../shared/hooks/useIsMobile";
import moment from "moment";
import { useLazyGetHolidaysQuery } from "../../data/endpoints/app.endpoints";
import CustomDatePicker from "../../shared/CustomDatePicker";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import { useTranslation } from "react-i18next";
import NoDataComponent from "../../shared/NoDataComponent";

const Holidays = () => {
  const { t } = useTranslation();
  const holidayTableTitles = [t("NAME"), t("DATE")];
  const [date, setDate] = useState<any>(moment().format());
  const isMobile = useIsMobile();
  const [getHolidays, result] = useLazyGetHolidaysQuery();
  const startYear = moment(date).startOf("year").format();
  const endYear = moment(date).endOf("year").format();
  useEffect(
    () => {
      getHolidays({
        startDate: startYear,
        endDate: endYear,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date]
  );

  const navigate = useNavigate();

  const holidayBodyrenderer = useMemo(() => {
    const holidayDataRenderer: JSX.Element[] = [];

    result?.data?.map((row: any, index: number) => {
      return holidayDataRenderer.push(
        <HolidayTableRow key={index} row={row} />
      );
    });
    return holidayDataRenderer;
  }, [result?.data]);
  return (
    <BoxContainer>
      {isMobile ? (
        <CustomHeaderMobile
          title={t("NATIONAL_HOLIDAYS")}
          subtitle={t("HOLIDAYS.SUBTITLE")}
          btnTitle="+"
          btnNavLink="/add-holiday"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              marginTop: "2vh",
            }}
          >
            <CustomDatePicker
              onChange={(value) => {
                setDate(value ? value.format("YYYY-MM-DD") : "");
              }}
              label={t("YEAR")}
              views={["year"]}
              value={date}
              inputFormat="YYYY"
              openTo="year"
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("NATIONAL_HOLIDAYS")}
          subtitle={t("HOLIDAYS.SUBTITLE")}
        >
          <CustomDatePicker
            onChange={(value) => {
              setDate(value ? value.format("YYYY-MM-DD") : "");
            }}
            label={t("YEAR")}
            views={["year"]}
            value={date}
            inputFormat="YYYY"
            openTo="year"
          />
          <CustomButton
            title={`${isMobile ? "+" : t("HOLIDAYS.ADD").toUpperCase()}`}
            width="13.5%"
            color={BLUE_COLOR_ACCENT}
            onClick={() => navigate("/add-holiday")}
            colorHover={BLUE_HOVER_COLOR_ACCENT}
          />
        </CustomHeader>
      )}
      {result.data ? (
        result?.data?.length > 0 ? (
          <Box mt={3}>
            <CustomTable
              isPaginated={false}
              tableBodyRenderer={holidayBodyrenderer}
              tableTitles={holidayTableTitles}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: isMobile ? "50vh" : "75vh",
            }}
          >
            <NoDataComponent title={t("HOLIDAYS.NOT_FOUND")} />
          </Box>
        )
      ) : (
        <LoadingSpinner />
      )}
    </BoxContainer>
  );
};

export default Holidays;
