import Dialog from "@mui/material/Dialog";
import { Box, useTheme } from "@mui/material";
import { Upload } from "@mui/icons-material";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import FileUploadForm from "./UploadFile";
import { useState } from "react";

export interface IFileUploadDialog {
  openDialog?: boolean;
  selectedValue?: string;
  onClose?: (value: string) => void;
  type?: string;
  user?: string;
}

export default function CustomFileUploadDialog({
  type,
  user,
}: IFileUploadDialog) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();

  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          width: isMobile ? "30px" : "100px",
          height: isMobile ? "30px" : "30px",
          border: `1px solid ${colors.invert}`,
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: "1%",
          overlay: "hidden",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <Upload sx={{ color: colors.invert, fontSize: "17px" }} />

        <h4
          style={{
            color: colors.invert,
            fontSize: isMobile ? "13px" : "auto",
          }}
        >
          {isMobile ? "" : "Upload"}
        </h4>
      </Box>

      <Dialog
        maxWidth="md"
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: {
            background: colors.component,
            margin: 0,
            padding: 10,
            width: isMobile ? "86%" : "100%",
          },
        }}
      >
        <FileUploadForm
          isModal={true}
          type={type && type}
          selectedUser={user && user}
        />
      </Dialog>
    </>
  );
}
