export enum UserRole {
  // SUPERADMIN = "Super Admin",
  ADMIN = "Admin",
  EMPLOYEE = "Employee",
  // HR = "HR",
  // ADMINISTRATOR = "Administrator",
  // FINANCE = "Finance",
  // MANAGER = "Manager",
  // SUPERVISOR = "Sopervisor",
  // IT = "IT",
}
