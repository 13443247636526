import { Box, Typography, useTheme } from "@mui/material";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomDatePicker from "../../shared/CustomDatePicker";
import projectImg from "../../assets/projectspng.png";
import { useEffect, useState } from "react";
import moment from "moment";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import useIsTablet from "../../shared/hooks/useIsTablet";
import CustomHeaderMobile from "../../shared/custom/CustomHeaderMobile";
import { useSelector } from "react-redux";
import { useLazyGetEmployeesHoursInVehicleQuery } from "../../data/endpoints/app.endpoints";
import CustomTextField from "../../shared/custom/CustomTextField";
import { selectAllVehicles } from "../../data/store/vehicleSlice";
import { useTranslation } from "react-i18next";
import NoDataComponent from "../../shared/NoDataComponent";
import { handleIntervalDateValidation } from "../../utils/regex/formRegex";

const VehicleInsights = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [dateIntervalError, setDateIntervalError] = useState("");
  const { t } = useTranslation();
  const [EmplyeeHoursInVehicle, EmployeesOnVehicleResults] =
    useLazyGetEmployeesHoursInVehicleQuery();

  const [vehicle, setVehicle] = useState<string>("");
  const [vehicleId, setVehicleId] = useState<string>("");
  const [errors, setErrors] = useState<any>({});

  const [background, setBackground] = useState<string>("white");
  const [color, setColor] = useState<string>("black");

  const [intervalStartTime, setIntervalStartTime] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const validateDate = () => {
    const error = {};
    handleIntervalDateValidation(
      error,
      "endDate",
      {
        startDate: intervalStartTime,
        endDate: intervalEndTime,
      },
      t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER")
    );

    setErrors(error);
    return Object.keys(errors).length === 0;
  };

  const vehicles = useSelector(selectAllVehicles);

  const handleChange = (event: any) => {
    setVehicle(event.target.value);
  };

  const hoursEmployeeWorkedInVehicle =
    EmployeesOnVehicleResults?.data?.hoursWorkedOnProject;

  const filteredData = vehicles?.filter((item: any) => {
    return item?.manufacturer
      ?.toLowerCase()
      .concat(item?.plate?.toLowerCase())
      .includes(vehicle?.toLowerCase());
  });

  const handleClick = (vehicleid: string) => {
    const isMatching = vehicles.some((obj: any) => {
      setVehicleId(vehicleid);
      return obj?.vehicleId === vehicleid;
    });

    if (isMatching) {
      setBackground(colors.cardHover);
      setColor(colors.black);
    } else {
      setBackground("#ffffff");
      setColor(colors.black);
    }
  };

  useEffect(() => {
    const startDateMoment = moment(intervalStartTime);
    const endDateMoment = moment(intervalEndTime);
    const startDate = moment(intervalStartTime).format("YYYY-MM-DD");
    const endDate = moment(intervalEndTime).format("YYYY-MM-DD");
    const endDateSmaller = endDateMoment.isBefore(startDateMoment);

    if (!endDateSmaller) {
      setDateIntervalError("");
      EmplyeeHoursInVehicle({
        vehicleId,
        startDate,
        endDate,
      });
    } else {
      setDateIntervalError(
        t("FORM_VALIDATIONS.DATE_VALIDATION.END_DATE_GREATER")
      );
    }
  }, [vehicleId, intervalStartTime, intervalEndTime]);

  return (
    <Box
      sx={{
        width: "100%",
        // height: "auto",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isMobile || isTablet ? (
        <CustomHeaderMobile
          title={t("VEHICLE.VEHICLE_INSIGHTS.TITLE")}
          subtitle={t("VEHICLE.VEHICLE_INSIGHTS.SUBTITLE")}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "90px",
              marginTop: "2vh",
              justifyContent: "space-between",
            }}
          >
            <CustomDatePicker
              onChange={(value: any) => {
                setIntervalStartTime(moment(value?.$d).format("YYYY-MM-DD"));
              }}
              value={intervalStartTime}
              label={t("VEHICLE.VEHICLE_INSIGHTS.START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value: any) =>
                setIntervalEndTime(moment(value?.$d).format("YYYY-MM-DD"))
              }
              value={intervalEndTime}
              label={t("VEHICLE.VEHICLE_INSIGHTS.END_DATE")}
              views={["day"]}
              openTo="day"
              touched={true}
              error={dateIntervalError}
            />
          </Box>
        </CustomHeaderMobile>
      ) : (
        <CustomHeader
          title={t("VEHICLE.VEHICLE_INSIGHTS.TITLE")}
          subtitle={t("VEHICLE.VEHICLE_INSIGHTS.SUBTITLE")}
        >
          <Box
            sx={{
              width: "40%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomDatePicker
              onChange={(value: any) => {
                setIntervalStartTime(moment(value?.$d).format("YYYY-MM-DD"));
              }}
              value={intervalStartTime}
              label={t("VEHICLE.VEHICLE_INSIGHTS.START_DATE")}
              views={["day"]}
              openTo="day"
            />

            <CustomDatePicker
              onChange={(value: any) =>
                setIntervalEndTime(moment(value?.$d).format("YYYY-MM-DD"))
              }
              value={intervalEndTime}
              label={t("VEHICLE.VEHICLE_INSIGHTS.END_DATE")}
              views={["day"]}
              openTo="day"
              touched={true}
              error={dateIntervalError}
            />
          </Box>
        </CustomHeader>
      )}
      <Box
        sx={{
          width: "100%",
          height: `${isMobile ? "auto" : "75vh"}`,
          padding: "2vh 1vw",
          display: "flex",
          flexDirection: `${isMobile ? "column" : "row"}`,
          justifyContent: "space-around",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: `${isMobile ? "100%" : "40%"}`,
            height: `${isMobile ? "40%" : "100%"}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CustomTextField
            label={t("VEHICLE.VEHICLE_INSIGHTS.SEARCH_PLACEHOLDER")}
            type="text"
            variant="outlined"
            name="Vehicle"
            value={vehicle}
            onChange={handleChange}
          />
          {filteredData?.length != 0 ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: `${isMobile ? "7vh" : "5vh"}`,
                  marginTop: "1vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  background: colors.blueAccent[800],
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                <b style={{ width: "25%" }}>No.</b>
                <b style={{ width: "40%" }}>
                  {t("VEHICLE.VEHICLE_INSIGHTS.VEHICLE_TYPE")}
                </b>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  marginTop: "1vh",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "auto",
                }}
              >
                {filteredData?.map((_vehicle: any, index: number) => (
                  <>
                    <Box
                      style={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: "1vh",
                        background: `${
                          vehicleId === _vehicle.vehicleId
                            ? background
                            : colors.component
                        }`,
                        color: `${
                          vehicleId === _vehicle.vehicleId
                            ? color
                            : colors.invert
                        }`,
                        cursor: "pointer",
                        fontWeight: 500,
                        borderRadius: "5px",
                        boxShadow: "1px 1px 3px 0.5px rgba(0,0,0,0.08)",
                      }}
                      key={index}
                      onClick={() => handleClick(_vehicle?.vehicleId)}
                    >
                      <p
                        style={{
                          width: "10%",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </p>
                      <p
                        style={{
                          width: "60%",
                          textAlign: "center",
                        }}
                      >
                        {_vehicle?.manufacturer + " | " + _vehicle?.plate}
                      </p>
                    </Box>
                  </>
                ))}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "75vh",
              }}
            >
              <NoDataComponent title={t("VEHICLE.NO_VEHICLE_FOUND")} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: `${isMobile ? "100%" : "50%"}`,
            height: `${isMobile ? "50%" : "100%"}`,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            borderRadius: "10px",
            boxShadow: "0px 0px 5px 0.5px rgba(0,0,0,0.08)",
            overflowY: "auto",
            padding: isMobile || isTablet ? "2%" : "0",
            backgroundColor: colors.component,
            marginTop: isMobile || isTablet ? "2vh" : "0",
          }}
        >
          {errors?.endDate ? (
            <p
              style={{
                fontSize: `${isMobile ? "10px" : "auto"}`,
                color: "red",
                background: colors.redAccent[900],
                padding: "1% 2%",
              }}
            >
              {errors?.endDate}
            </p>
          ) : (
            <p
              style={{
                fontSize: `${isMobile ? "10px" : "auto"}`,
              }}
            >
              {t("VEHICLE.VEHICLE_INSIGHTS.TABLE_TITLE")}
            </p>
          )}

          {filteredData?.length !== 0 &&
          hoursEmployeeWorkedInVehicle?.length !== 0 &&
          hoursEmployeeWorkedInVehicle?.length !== undefined ? (
            hoursEmployeeWorkedInVehicle?.map((emp: any, index: string) => (
              <li
                key={index + 1}
                style={{
                  listStyle: "none",
                  width: "90%",
                  height: isMobile || isTablet ? "30px" : "40px",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  borderRadius: "5px",
                  background: colors.cardHover,
                  marginTop: "2vh",
                  color: colors.black,
                }}
              >
                <b>{emp?.employee}</b>
                <b>
                  {emp?.hoursWorked?.totalTimeWorked?.hours +
                    " h :" +
                    " " +
                    (emp?.hoursWorked?.totalTimeWorked?.minutes != undefined
                      ? emp?.hoursWorked?.totalTimeWorked?.minutes + " min"
                      : 0 + " min")}
                </b>
              </li>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <img
                src={projectImg}
                width={isMobile ? 100 : 300}
                style={{
                  opacity: 0.1,
                  marginTop: `${isMobile ? "3vh" : "10vh"}`,
                }}
                alt="projectImg"
              />
              <Typography variant="h6">
                {t("VEHICLE.VEHICLE_INSIGHTS.NO_VEHICLE")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleInsights;
