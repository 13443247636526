import Button from "@mui/material/Button/Button";

interface CustomButtonProps {
  onClick?: any;
  title: string | any;
  type?: "button" | "submit" | "reset" | undefined;
  width?: string;
  color?: string;
  colorHover?: string;
  disabled?: boolean;
  mt?: string;
  margin?: string;
  fontSize?: any;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const CustomButton = ({
  title,
  type,
  onClick,
  width = "10%",
  color,
  colorHover,
  disabled,
  mt,
  margin,
  fontSize,
  onMouseDown,
}: CustomButtonProps) => {
  return (
    <Button
      onMouseDown={onMouseDown}
      disabled={disabled}
      type={type}
      fullWidth
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: color,
        width: width,
        fontSize: title?.length > 14 ? "10px" : fontSize ? fontSize : "90%",
        display: "flex",
        flexDirection: "row-reverse",
        margin: margin,
        cursor: "pointer",
        mt: mt,
        ":hover": {
          bgcolor: colorHover,
          color: "white",
        },
      }}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
