import { Box, colors, useTheme } from "@mui/material";
import { tokens } from "../../core/AppStyles";
import useIsMobile from "../../shared/hooks/useIsMobile";
import moment from "moment";

export interface IUserLog {
  actionBy: string;
  createdAt: string;
  activityType: string;
  logDate?: string;
  description?: string;
  hours?: number;
}

const UserLogger = ({
  actionBy,
  createdAt,
  activityType,
  description,
}: IUserLog) => {
  const theme = useTheme();
  const color = tokens(theme.palette.mode);
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Box
        style={{
          width: isMobile ? "8%" : "5%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "20px",
            height: "20px",
            background: `${
              activityType === "change password"
                ? colors.green[100]
                : activityType === "change status"
                ? colors.red[100]
                : activityType === "overtime compensation"
                ? colors.blue[100]
                : activityType === "vacation compensation"
                ? colors.purple[100]
                : ""
            }`,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "12px",
              height: "12px",
              background: `${
                activityType === "change password"
                  ? colors.green[500]
                  : activityType === "change status"
                  ? colors.red[500]
                  : activityType === "overtime compensation"
                  ? colors.blue[500]
                  : activityType === "vacation compensation"
                  ? colors.purple[500]
                  : ""
              }`,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            width: "2px",
            height: isMobile ? "80%" : "75%",
            background: color.timeline,
            borderRadius: "5px",
          }}
        ></Box>
      </Box>

      {/* content */}

      <Box
        sx={{
          width: "90%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <h3
          style={{
            margin: 2,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            fontSize: isMobile ? "1rem" : "1.1rem",
            fontWeight: "500",
            color: color.log,
            textTransform: "capitalize",
          }}
        >
          {activityType}
          <span
            style={{
              color: "gray",
              fontSize: isMobile ? "10px" : "12px",
            }}
          >
            {moment(createdAt).format("DD-MMM-YYYY | HH:mm")}
          </span>
        </h3>
        <p
          style={{
            margin: 0,
            fontSize: isMobile ? "12px" : "auto",
          }}
        >
          {description}
        </p>

        <p
          style={{
            margin: 0,
            fontSize: isMobile ? "12px" : "auto",
          }}
        >
          By: {actionBy}
        </p>
      </Box>
    </Box>
  );
};

export default UserLogger;
