/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, Button, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useEffect, useState } from "react";
import { ErrorOutline } from "@mui/icons-material";
import moment from "moment";
import { useLazyCheckUncompleatedTTByIdQuery } from "../../../../data/endpoints/app.endpoints";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../../data/store/authSlice";
import { tokens } from "../../../../core/AppStyles";
import { useTranslation } from "react-i18next";

interface IUncompletetTTUser {
  onClose: () => void;
}

const UncompletTimeTrackerUser = ({ onClose }: IUncompletetTTUser) => {
  const [open, setOpen] = useState(false);
  const loggedUser = useSelector(selectLoggedUser);

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const firstDayOfMonth = moment().startOf("month").subtract(2, "weeks");
  const endDate = moment().subtract(1, "days");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [checkUnCompleatedTT, result] = useLazyCheckUncompleatedTTByIdQuery();

  useEffect(() => {
    checkUnCompleatedTT({
      startDate: firstDayOfMonth.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      userId: loggedUser?.userId,
    });
  }, []);
  const data = result?.data?.incompleteDates;

  useEffect(() => {
    if (data?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [data?.length]);

  const numberOfDays = 0;

  const incompleteTTMessage = () => {
    let message = "";
    if (data?.length === 1) {
      message = t("UNCOMPLETED_TIME_TRACKER.ONE_UNCOMPLETED", { numberOfDays: numberOfDays })
    } else if (data?.length > 1) {
      message = t("UNCOMPLETED_TIME_TRACKER.MORE_UNCOMPLETED", { length: data?.length, numberOfDays: numberOfDays });
      message = t("UNCOMPLETED_TIME_TRACKER.ONE_UNCOMPLETED");
    } else if (data?.length > 1) {
      message = t("UNCOMPLETED_TIME_TRACKER.MORE_UNCOMPLETED", {
        length: data?.length,
      });
    }
    return message;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "30vw",
              height: "70px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ErrorOutline color="warning" sx={{ fontSize: "50px" }} />
          </Box>
          <Alert severity="warning">
            {" "}
            <Box>
              {incompleteTTMessage()}

              {data?.map((date: Date, index: number) => {
                return (
                  <li
                    style={{
                      marginTop: "1vh",
                    }}
                    key={index}
                  >
                    {moment(date).format("DD MMM YYYY")}
                  </li>
                );
              })}
            </Box>
          </Alert>
        </DialogContent>
        <DialogActions
          onClick={onClose}
          sx={{
            cursor: "pointer",
            padding: 2,
            color: colors.invert,
            fontWeight: 500,
          }}
        >
          {t("UNCOMPLETED_TIME_TRACKER.REMINDME_TOMORROW").toUpperCase()}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UncompletTimeTrackerUser;
