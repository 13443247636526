import TableRow from "@mui/material/TableRow/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import moment from "moment";
import { Link } from "react-router-dom";
import { tokens } from "../../core/AppStyles";
import { useTheme } from "@mui/material";
import { memo } from "react";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import { Vehicle } from "../../data/models/vehicle.interface";

interface CustomTableRowProps {
  row: Vehicle;
  i: number;
}

const VehicleTableRow = ({ row, i }: CustomTableRowProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {i + 1}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.manufacturer}
      </TableCell>
      <TableCell component="th" scope="row">
        {row?.model}
      </TableCell>
      <TableCell align="left">{row?.plate}</TableCell>
      <TableCell align="left">
        {row?.dateInUse ? moment(row?.dateInUse).format("YYYY-MM-DD") : "-"}
      </TableCell>

      <TableCell align="left">
        {row?.outOfService
          ? moment(row?.outOfService).format("YYYY MMM DD")
          : "-"}
      </TableCell>
      <TableCell align="left">{row?.status}</TableCell>
      {/* <TableCell align="left">
        {row.hiringDate ? moment(row.hiringDate).format("MMM DD YYYY") : "-"}
      </TableCell> */}

      <TableCell align="left">
        {
          <Link
            style={{ color: colors.primary[100] }}
            to={`/edit-vehicle/${row?.vehicleId}`}
          >
            <IconEdit fill={colors.invert} />
          </Link>
        }
      </TableCell>
    </TableRow>
  );
};

export default memo(VehicleTableRow);
