import { useContext, useState } from "react";
import { User } from "../../data/models/user.interface";
import { Box, TableCell, TableRow, useTheme } from "@mui/material";
import moment from "moment";
import { tokens } from "../../core/AppStyles";
import { Delete } from "@mui/icons-material";
import { useDeleteDailyTimeTrackerMutation } from "../../data/endpoints/app.endpoints";
import AlertDialog from "../../shared/modal/AlertDialog";
import { RootContext } from "../../context/RootContext";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import { selectHolidaysState } from "../../data/store/holidaysSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface ViewTimeTrackersTableRowProps {
  employee: User;
  timeTracker: any;
  openModalManageTimeTracker: any;
}

const ViewTimeTrackersTableRow = ({
  openModalManageTimeTracker,
  employee,
  timeTracker,
}: ViewTimeTrackersTableRowProps) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openModal, setOpenModal] = useState(false);
  const { showSnackbar } = useContext(RootContext);
  const [deleteDailyTimeTracker] = useDeleteDailyTimeTrackerMutation();

  const handleModalOpen = () => {
    setOpenModal(!openModal);
  };

  const holidays: any = useSelector(selectHolidaysState);
  const { t } = useTranslation();
  const isOverWorked = timeTracker?.work_time / 60 >= 10;
  const isHoliday = holidays?.holidays?.some((holiday: any) =>
    moment(holiday.date).isSame(timeTracker.date, "day")
  );

  const isWeekend: any =
    moment(timeTracker.date).day() === 6 ||
    moment(timeTracker.date).day() === 0;

  const handleTimeTrackerDelete = () => {
    deleteDailyTimeTracker({
      userId: employee.userId,
      date: moment(timeTracker.date).format("YYYY-MM-DD"),
    }).then((res: any) => {
      if ("data" in res) {
        setOpenModal(false);
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.RESPONSE.SUCCESS")}`,
          false,
          true
        );
      } else {
        setOpenModal(false);
        showSnackbar(
          `${t("TIME_TRACKER.ADMIN.VIEW_TIME_TRACKERS.RESPONSE.ERROR")}`,
          true,
          true
        );
      }
    });
  };

  return (
    <TableRow
      key={employee.userId}
      style={{
        background: isWeekend
          ? colors.weekend
          : isHoliday
          ? colors.holiday
          : isOverWorked
          ? colors.overWorked
          : "transparent",
      }}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {employee.firstName} {employee.lastName}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(timeTracker.date).format("dddd YYYY MMM DD")}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(timeTracker.firstStartTime).format("HH:mm")}
      </TableCell>
      <TableCell component="th" scope="row">
        {moment(timeTracker.lastStartTime).format("HH:mm")}
      </TableCell>
      <TableCell component="th" scope="row">
        {(timeTracker?.work_time / 60).toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {timeTracker?.leaveHours?.toFixed(2).replace(/(\.0+|0+)$/, "")}
      </TableCell>
      <TableCell component="th" scope="row">
        {isWeekend || isHoliday
          ? (timeTracker?.work_time / 60).toFixed(2).replace(/(\.0+|0+)$/, "")
          : timeTracker?.overtime.toFixed(2).replace(/(\.0+|0+)$/, "") || 0}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ alignItems: "center", paddingTop: 0, paddingBottom: 0 }}
      >
        <IconEdit
          fill={colors.invert}
          onClick={() =>
            openModalManageTimeTracker(employee, timeTracker?.date)
          }
        />

        <Box sx={{ width: "40%" }} />
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ alignItems: "center", paddingTop: 0, paddingBottom: 0 }}
      >
        <AlertDialog
          openModal={openModal}
          setOpenModal={setOpenModal}
          buttonTitle=""
          handleSubmitDialog={handleTimeTrackerDelete}
          ButtonComponent={(props: any) => (
            <Delete onClick={handleModalOpen} sx={{ cursor: "pointer" }} />
          )}
          mt="20px"
        >
          {t("TIME_TRACKER.ADMIN.MANAGE_TIME_TRACKERS.MODAL_TEXT")}
        </AlertDialog>
      </TableCell>
    </TableRow>
  );
};

export default ViewTimeTrackersTableRow;
