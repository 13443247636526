import { useContext, useState } from "react";
import BoxContainer from "../../shared/BoxContainer";
import CustomHeader from "../../shared/custom/CustomHeader";
import CustomNavigationButton from "../../shared/custom/CustomNavigationButton";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { RootContext } from "../../context/RootContext";
import { useAddHolidayMutation } from "../../data/endpoints/app.endpoints";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { Formik } from "formik";
import CustomDatePicker from "../../shared/CustomDatePicker";
import AlertDialog from "../../shared/modal/AlertDialog";
import { FieldArray } from "formik";
import { tokens } from "../../core/AppStyles";
import { Box, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { holidaysFieldArrayValidator } from "../../utils/regex/formRegex";
import { useNavigate } from "react-router";
import { t } from "i18next";
import useIsTablet from "../../shared/hooks/useIsTablet";

const AddHolidays = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const [fieldName, setFieldName] = useState("Holidays");
  const {
    setOpenModal,
    showSnackbar,
    state: { openModal },
  } = useContext(RootContext);

  const [addHoliday, result] = useAddHolidayMutation();

  const initialValues = {
    holidays: [{ name: "", date: "" }],
  };
  const validate = (values: typeof initialValues) => {
    const errors = {};
    holidaysFieldArrayValidator(values, errors, "holidays", {
      holidayName: t(
        "FORM_VALIDATIONS.HOLIDAYS_VALIDATIONS.BOTH_FIELDS_REQUIRED"
      ),
      datesNotEqual: t("FORM_VALIDATIONS.HOLIDAYS_VALIDATIONS.DATES_NOT_EQUAL"),
    });
    return errors;
  };
  const onSubmit = () => {
    setOpenModal(true);
  };

  const handleSubmitDialog = (values: typeof initialValues) => {
    const { holidays } = values;

    addHoliday({
      holidays,
    }).then((onfulfilled: any) => {
      if (onfulfilled?.data) {
        showSnackbar(`${t("CREATE_HOLIDAY.SUCCESS")}`, false, true);
        navigate("/holidays");
      } else if (onfulfilled?.error) {
        if (onfulfilled?.error?.data?.error?.message)
          showSnackbar(onfulfilled?.error?.data.error.message, true, true);
        else showSnackbar(`${t("CREATE_HOLIDAY.SUCCESS.ERROR")}`, true, true);
      }
      setOpenModal(false);
    });
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <BoxContainer>
      <CustomHeader
        title={t("CREATE_HOLIDAY.TITLE")}
        subtitle={t("CREATE_HOLIDAY.SUBTITLE")}
      >
        <CustomNavigationButton
          customStyle={{
            backgroundColor: BLUE_COLOR_ACCENT,
            width: "auto",
            display: "flex",
            gap: 1,
            alignSelf: "center",
            fontSize: "12px",
            ":hover": {
              bgcolor: BLUE_HOVER_COLOR_ACCENT,
              color: "white",
            },
          }}
          navigateTo={"/holidays"}
        >
          <KeyboardArrowLeftIcon /> {isMobile ? null : t("GO_BACK")}
        </CustomNavigationButton>
      </CustomHeader>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleSubmit,
          touched,
          setFieldValue,
        }) => (
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              backgroundColor: colors.component,
              padding: 3,
              paddingTop: 3,
              borderRadius: 3,
            }}
          >
            <Box
              display="grid"
              gridTemplateColumns="repeat(1, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FieldArray
                name={"holidays"}
                render={({ remove, push }: any) => (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            p: 1,
                            fontWeight: 600,
                            color: colors.grey[200],
                          }}
                          variant="h5"
                        >
                          {fieldName}
                        </Typography>
                      </Box>
                      {values.holidays.length > 0 ? (
                        <Box
                          sx={{
                            overflowY: isMobile
                              ? "none"
                              : values.holidays.length > 2
                              ? "scroll"
                              : "none",
                            maxHeight: isMobile ? "auto" : "320px",
                            width: "100%",
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {values && values.holidays.length > 0
                            ? values.holidays.map(
                                (holiday: object, index: number) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={1}
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                          inputProps={{
                                            style: { fontSize: "16px" },
                                          }}
                                          variant="outlined"
                                          placeholder={t(
                                            "CREATE_HOLIDAY.HOLIDAY_NAME_PLACEHOLDER"
                                          )}
                                          fullWidth
                                          size="small"
                                          rows={3}
                                          value={values.holidays[index].name}
                                          name={`holidays[${index}].name`}
                                          error={
                                            !!(
                                              touched?.holidays &&
                                              touched?.holidays[index]
                                            ) &&
                                            !!(
                                              errors?.holidays &&
                                              errors?.holidays[index]
                                            )
                                          }
                                          onChange={handleChange}
                                          color="info"
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        marginTop={isMobile || isTablet ? 1 : 0}
                                      >
                                        <CustomDatePicker
                                          views={["day"]}
                                          openTo="day"
                                          onChange={(value) =>
                                            setFieldValue(
                                              `holidays[${index}].date`,
                                              value
                                                ? value.format("YYYY-MM-DD")
                                                : "",
                                              true
                                            )
                                          }
                                          value={values.holidays[index].date}
                                          error={
                                            !!(
                                              touched.holidays &&
                                              touched.holidays[index]
                                            ) &&
                                            !!(
                                              errors.holidays &&
                                              errors.holidays[index]
                                            )
                                          }
                                          touched={
                                            !!(
                                              touched.holidays &&
                                              touched.holidays[index]
                                            )
                                          }
                                          label={t("DATE")}
                                          fullWidth={true}
                                          name={`holidays[${index}].date`}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={2}
                                        style={{ alignSelf: "center" }}
                                      >
                                        {index > 0 ? (
                                          <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            style={{
                                              fontSize: "1rem",
                                              fontWeight: 300,
                                              color: colors.black,
                                              backgroundColor: "#f8683c",
                                              border: "0",
                                              display: "flex",
                                              justifyContent: "center",
                                              padding: "6px 8px",
                                              borderRadius: 3,
                                              cursor: "pointer",
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width={isMobile ? "18" : "25"}
                                              height={isMobile ? "18" : "25"}
                                              fill="white"
                                              className="bi bi-trash-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                            </svg>
                                          </button>
                                        ) : null}
                                      </Grid>
                                      <Typography
                                        color="error"
                                        sx={{
                                          textAlign: "center",
                                          width: "100%",
                                        }}
                                      >
                                        {!!touched?.holidays &&
                                          !!touched?.holidays[index] &&
                                          !!errors?.holidays &&
                                          (errors?.holidays[index] as any)}
                                      </Typography>
                                    </Grid>
                                  );
                                }
                              )
                            : null}
                        </Box>
                      ) : null}
                    </Box>
                    <div
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1rem",
                        color: BLUE_COLOR_ACCENT,
                        borderRadius: "5px",
                        marginLeft: "10px",
                        width: "auto",
                        padding: 0,
                        justifyContent: "left",
                        marginTop: "10px",
                      }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          push({
                            name: "",
                            date: "",
                          })
                        }
                      >
                        {"+ " + t("ADD_MORE")}
                      </span>
                    </div>
                  </>
                )}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <AlertDialog
                openModal={openModal}
                setOpenModal={setOpenModal}
                buttonTitle={
                  result?.isLoading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    `${t("SUBMIT")}`
                  )
                }
                width="auto"
                handleSubmitDialog={() => handleSubmitDialog(values)}
                color={BLUE_COLOR_ACCENT}
                colorHover={BLUE_HOVER_COLOR_ACCENT}
                mt="20px"
              >
                {t("CREATE_HOLIDAY.MODAL_CONFIRM_SUBTITLE")}
              </AlertDialog>
            </Box>
          </Box>
        )}
      </Formik>
    </BoxContainer>
  );
};

export default AddHolidays;
