import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import CustomButton from "../../shared/custom/CustomButton";
import { Box, Typography, useTheme } from "@mui/material";
import CustomTextField from "../../shared/custom/CustomTextField";
import { useContext, useEffect, useState } from "react";
import { useUserCompensationMutation } from "../../data/endpoints/app.endpoints";
import { forwardRef } from "react";
import { RootContext } from "../../context/RootContext";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { selectEmployeeById } from "../../data/store/employeeSlice";
import { tokens } from "../../core/AppStyles";
import { ViewAgenda } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ICompensationUser {
  id: string | undefined;
  name: string;
}
interface Icompesate {
  activityType: string;
  hours: any;
  actionBy: string;
  userId: string | undefined;
}

export default function VacanciesCompensationModal({
  id,
  name,
}: ICompensationUser) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useContext(RootContext);
  const [userCompensation] = useUserCompensationMutation();
  const [error, setError] = useState<string>("");
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const loggedUser = useSelector(selectLoggedUser);
  const user = useSelector(selectEmployeeById(id));

  const [compensation, setCompensation] = useState<Icompesate>({
    activityType: "vacation compensation",
    hours: "",
    actionBy: loggedUser?.firstName + " " + loggedUser?.lastName,
    userId: id,
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeCompensation = (event: any) => {
    setCompensation((prevState) => ({
      ...prevState,
      hours: event.target.value ? Number(event.target.value) : "",
    }));
  };

  useEffect(() => {
    if (compensation.hours === "") {
      setCanSubmit(false);
      setError(`${t("FORM_VALIDATIONS.REQUIRED")}`);
    } else if (compensation.hours === 0) {
      setCanSubmit(false);
      setError(`${t("USERS_ADMIN.VIEW_USER.VALUE_ZERO")}`);
    } else {
      setCanSubmit(true);
      setError("");
    }
  }, [compensation?.hours]);

  const handleSubmit = () => {
    canSubmit &&
      userCompensation(compensation)
        .then((res: any) =>
          showSnackbar(
            `${t("USERS_ADMIN.VIEW_USER.SUBMIT_SUCCESS")}`,
            false,
            true
          )
        )
        .catch(() =>
          showSnackbar(`${t("USERS_ADMIN.VIEW_USER.SUBMIT_ERROR")}`, true, true)
        );
    setCompensation((prevState) => ({
      ...prevState,
      hours: "",
    }));
    setOpen(false);
  };

  return (
    <>
      <CustomButton
        title={t("USERS_ADMIN.VIEW_USER.COMPENSATE")}
        width="100%"
        onClick={handleClickOpen}
        color={BLUE_COLOR_ACCENT}
        colorHover={BLUE_HOVER_COLOR_ACCENT}
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            background: colors.component,
            padding: "0vh 2vw",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                background: colors.greenAccent[900],
                border: `1px solid ${colors.greenAccent[700]}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ViewAgenda sx={{ color: colors.invert }} fontSize="large" />
            </Box>
            <Typography
              variant="h5"
              color={colors.grey[400]}
              sx={{
                fontWeight: 700,
                fontFamily: "sans-serif",
                margin: 0,
                padding: 0,
              }}
              textAlign={"center"}
            >
              {t("USERS_ADMIN.VIEW_USER.COMPENSATE_TEXT", { name: name })}
            </Typography>{" "}
            <p
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              {name} {t("USERS_ADMIN.VIEW_USER.HAS")}{" "}
              <b>
                {" "}
                {user?.totalVacations
                  ?.toFixed(2)
                  .replace(/(\.0+|0+)$/, "")}{" "}
              </b>{" "}
              {t("USERS_ADMIN.VIEW_USER.DAYS_LEFT")}
            </p>
            <CustomTextField
              type="number"
              placeholder="0"
              value={compensation.hours}
              onChange={handleChangeCompensation}
              label={t("USERS_ADMIN.VIEW_USER.DAYS")}
              errors="fffff"
            />
            <small style={{ color: colors.redAccent[400] }}>{error}</small>
            <CustomButton
              title={t("COMPENSATE").toUpperCase()}
              width="100%"
              onClick={handleSubmit}
              color={BLUE_COLOR_ACCENT}
              colorHover={BLUE_HOVER_COLOR_ACCENT}
              disabled={!canSubmit}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
