import { Box, Tooltip, useTheme } from "@mui/material";
import CustomFileManagerHeader from "./CustomFileManagerHeader";
import { Search } from "@mui/icons-material";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { tokens } from "../../core/AppStyles";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { User } from "../../data/models/user.interface";
import EmployeeFolder from "../../assets/userfolder.png";
import CustomFileUploadDialog from "./CustomFileUploadDialogModal";

const UsersFolders = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fileSearch, setFileSearch] = useState<string | undefined>("");
  const navigate = useNavigate();
  const { type } = useParams();

  const handleFileChange = (e: any) => {
    setFileSearch(e?.target?.value);
  };
  const allUsers: User[] = useSelector((state: any) => state?.employee?.data);

  const filteredUsers = useMemo(() => {
    return allUsers?.filter((user: User) =>
      (user.firstName + " " + user?.lastName)
        ?.toLowerCase()
        .includes(fileSearch as string)
    );
  }, [fileSearch]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomFileManagerHeader
        children={
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  width: isMobile ? "150px" : "200px",
                  height: "30px",
                  border: "0.5px solid lightgray",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  gap: 1,
                  padding: "5px",
                  overflow: "hidden",
                }}
              >
                <input
                  type="text"
                  id="fileSearch"
                  style={{
                    border: "none",
                    outline: 0,
                    background: "none",
                    height: "100%",
                    color: colors.invert,
                  }}
                  onChange={handleFileChange}
                  value={fileSearch}
                  placeholder="search ..."
                  autoComplete="off"
                />
                {isMobile ? (
                  ""
                ) : (
                  <Search fontSize="small" sx={{ color: colors.invert }} />
                )}
              </Box>
            </Box>
            <CustomFileUploadDialog type={type} />
          </Box>
        }
      />
      <Box
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: "70vh",
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "flex-start",
          justifyContent: isMobile || isMobile ? "center" : "flex-start",
          flexWrap: "wrap",
          marginTop: "2vh",
          overflowY: "auto",
        }}
      >
        {filteredUsers?.map((employee: User, index: number) => (
          <Box
            sx={{
              width: isMobile ? "90px" : "110px",
              height: isMobile ? "90px" : "110px",
              padding: "1%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              overflow: "hidden",
              cursor: "pointer",
            }}
            key={index}
            onDoubleClick={() => navigate(`${employee?.userId}`)}
          >
            <img
              src={EmployeeFolder}
              alt="folder"
              width={isMobile ? "60px" : "65px"}
            />
            <b
              style={{
                fontSize: "11px",
              }}
            >
              {
                <Tooltip title={employee?.firstName + " " + employee.lastName}>
                  {(employee?.firstName + " " + employee.lastName)?.length > 10
                    ? (((employee?.firstName + " " + employee.lastName)?.slice(
                        0,
                        10
                      ) + "...") as any)
                    : ((employee?.firstName + " " + employee.lastName) as any)}
                </Tooltip>
              }
            </b>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UsersFolders;
