import { useContext, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Key, LogoutOutlined } from "@mui/icons-material";
import { RootContext } from "../../context/RootContext";
import AlertDialog from "../../shared/modal/AlertDialog";
import { useTranslation } from "react-i18next";
import { Box, useTheme } from "@mui/material";
import CustomButton from "../../shared/custom/CustomButton";
import PasswordTestField from "../../shared/PasswordTestField";
import { Formik } from "formik";
import {
  BLUE_COLOR_ACCENT,
  BLUE_HOVER_COLOR_ACCENT,
} from "../../utils/constants/colors";
import {
  PASSWORD_REGEX,
  handleRegexValidation,
  handleRequiredValidation,
} from "../../utils/regex/formRegex";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { useChangeUserPasswordMutation } from "../../data/endpoints/app.endpoints";
import { tokens } from "../AppStyles";

interface OptionsMenuProps {
  anchorEl: any;
  openMenu: any;
  handleClose: any;
  logoutUser: any;
}

const OptionsMenu = ({
  anchorEl,
  openMenu,
  handleClose,
  logoutUser,
}: OptionsMenuProps) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const loggedUser = useSelector(selectLoggedUser);
  const [changePassword] = useChangeUserPasswordMutation();
  const { logoutAndClearState, showSnackbar } = useContext(RootContext);
  const [openLogOutModal, setLogOutModal] = useState(false);
  const [openChangePasswordModal, setChangePasswordModal] = useState(false);
  const { t } = useTranslation();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const togglePassword = () => setVisiblePassword(!visiblePassword);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const toggleConfirmPassword = () =>
    setVisibleConfirmPassword(!visibleConfirmPassword);
  const initialValues = {
    id: loggedUser.userId,
    password: "",
    confirmPassword: "",
    fullName: loggedUser?.firstName + " " + loggedUser?.lastName,
  };

  const onSubmit = ({
    id,
    password,
    confirmPassword,
    fullName,
  }: typeof initialValues) => {
    if (password !== confirmPassword) {
      showSnackbar(`${t("FORM_VALIDATIONS.PASSWORD_NOT_MATCH")}`, true, true);
    } else {
      changePassword({ id, password, fullName }).then((res: any) => {
        if (res?.data) {
          showSnackbar(
            `${t("FORM_VALIDATIONS.PASSWORD_SUCCESS")}`,
            false,
            true
          );
        }
        handleClose();
      });
      setChangePasswordModal(false);
    }
  };

  const validate = (values: typeof initialValues) => {
    const errors = {};

    handleRequiredValidation(
      values,
      errors,
      "password",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRegexValidation(
      values,
      errors,
      "password",
      PASSWORD_REGEX,
      t("FORM_VALIDATIONS.USERS.PASSWORD_ERROR_MESSAGE"),
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "confirmPassword",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRegexValidation(
      values,
      errors,
      "confirmPassword",
      PASSWORD_REGEX,
      t("FORM_VALIDATIONS.USERS.PASSWORD_ERROR_MESSAGE"),
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  const handleModalOpen = () => {
    setLogOutModal(true);
  };

  const handleChangePasswordModalOpen = () => {
    setChangePasswordModal(true);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={openMenu}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          background: colors.component,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            background: colors.component,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <AlertDialog
        dialogTitle={t("CHANGE_PASSWORD")}
        openModal={openChangePasswordModal}
        setOpenModal={setChangePasswordModal}
        buttonTitle=""
        handleSubmitDialog={onSubmit}
        hasAction={false}
        ButtonComponent={(props: any) => (
          <>
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 1,
              }}
              onClick={handleChangePasswordModalOpen}
            >
              <Key />
              {t("CHANGE_PASSWORD")}
            </MenuItem>
          </>
        )}
        mt="20px"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
          >
            {({ values, handleSubmit, handleChange, touched, errors }) => (
              <Box component="form" onSubmit={handleSubmit}>
                <PasswordTestField
                  label={t("USERS_ADMIN.VIEW_USER.NEW_PASSWORD")}
                  variableName="password"
                  value={values.password}
                  handleChange={handleChange}
                  isVisible={visiblePassword}
                  toggleVisibility={togglePassword}
                  hasMargin={true}
                  error={errors.password}
                  touched={touched.password}
                />
                <PasswordTestField
                  label={t("USERS_ADMIN.VIEW_USER.CONFIRM_NEW_PASSWORD")}
                  variableName="confirmPassword"
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  isVisible={visibleConfirmPassword}
                  toggleVisibility={toggleConfirmPassword}
                  error={errors.confirmPassword}
                  hasMargin={true}
                  touched={touched.confirmPassword}
                />
                <CustomButton
                  type="submit"
                  title={t("USERS_ADMIN.VIEW_USER.CONFIRM_CHANGE")}
                  width="100%"
                  margin="4vh 0 0 0"
                  color={BLUE_COLOR_ACCENT}
                  colorHover={BLUE_HOVER_COLOR_ACCENT}
                />
              </Box>
            )}
          </Formik>
        </Box>
      </AlertDialog>

      <AlertDialog
        openModal={openLogOutModal}
        setOpenModal={setLogOutModal}
        buttonTitle=""
        handleSubmitDialog={() => {
          logoutUser(undefined).then(() => {
            logoutAndClearState(navigate, dispatch);
          });
        }}
        ButtonComponent={(props: any) => (
          <MenuItem
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
            onClick={handleModalOpen}
          >
            <LogoutOutlined />
            {t("LOGOUT")}
          </MenuItem>
        )}
        mt="20px"
      >
        {t("LOGOUT_MODAL_TEXT")}
      </AlertDialog>
    </Menu>
  );
};

export default OptionsMenu;
