import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";
import { Holiday } from "../models/holiday.interface";

const initialState: {
  holidays: Holiday[];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  holidays: [],
  isError: false,
  isSuccess: false,
  isLoading: true,
};

const selectSelf = (state: RootState) => state.holidays;

export const selectHolidaysState = createSelector(selectSelf, (state) => state);

export const selectAllHolidays = createSelector(
  selectHolidaysState,
  (state) => state.holidays
);

export const holidaysSlice = createSlice({
  name: "holidays",
  initialState,
  reducers: {
    resetHolidaysState: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        appEndpoints.endpoints.getHolidays.matchFulfilled,
        (state, { payload }) => {
          state.isError = false;
          state.isLoading = false;
          state.holidays = payload;
          state.isSuccess = true;
        }
      )
      .addMatcher(
        appEndpoints.endpoints.getHolidays.matchRejected,
        (state, { payload }) => {
          state.isError = true;
          state.isLoading = false;
          state.holidays = [];
          state.isSuccess = false;
        }
      ),
});

export const { resetHolidaysState } = holidaysSlice.actions;
