import {
  DialogContent,
  Dialog,
  DialogContentText,
  DialogProps,
  Box,
  TextField,
  useTheme,
} from "@mui/material";
import { useState, useRef, useEffect, useContext } from "react";
import CustomButton from "../../shared/custom/CustomButton";
import { Formik } from "formik";
import { RootContext } from "../../context/RootContext";
import { useUpdateHolidayMutation } from "../../data/endpoints/app.endpoints";
import CustomDatePicker from "../../shared/CustomDatePicker";
import { IconEdit } from "../../shared/custom/CustomEditIcons";
import useIsLargeScreen from "../../shared/hooks/useIsLargeScreen";
import { BLUE_COLOR_ACCENT } from "../../utils/constants/colors";
import useIsMobile from "../../shared/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { tokens } from "../../core/AppStyles";
import { handleRequiredValidation } from "../../utils/regex/formRegex";

interface IHoliday {
  holidayId?: string | undefined;
  name?: string;
  date?: string;
}

const UpdateHoliday = ({ holidayId, name, date }: IHoliday) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const { showSnackbar } = useContext(RootContext);
  const [updateHoliday] = useUpdateHolidayMutation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  });

  const initialValues = {
    holidayId,
    name,
    date,
  };

  const validate = (values: typeof initialValues) => {
    const errors = {};

    handleRequiredValidation(
      values,
      errors,
      "name",
      t("FORM_VALIDATIONS.REQUIRED")
    );
    handleRequiredValidation(
      values,
      errors,
      "date",
      t("FORM_VALIDATIONS.REQUIRED")
    );

    return errors;
  };

  const onSubmit = ({ holidayId, name, date }: typeof initialValues) => {
    updateHoliday({ holidayId, name, date }).then((res: any) => {
      if (res?.data) {
        showSnackbar(`${t("HOLIDAYS.SUCCESS_UPDATE")}`, false, true);
      } else {
        showSnackbar(`${t("HOLIDAYS.ERROR_UPDATE")}`, true, true);
      }
      setOpen(false);
    });
  };
  const isMobile = useIsMobile();
  const isLargeScreen = useIsLargeScreen();

  return (
    <>
      <IconEdit
        fill={colors.invert}
        onClick={handleClickOpen("paper")}
        sx={{ cursor: "pointer" }}
      />
      <Dialog
        PaperProps={{ style: { background: colors.component } }}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          margin: 0,
        }}
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box
              style={{
                width: isLargeScreen ? "15vw" : isMobile ? "70vw" : "22vw",
                minHeight: "35vh auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <h3
                style={{
                  color: colors.invert,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("HOLIDAYS.MODAL_UPDATE")}
              </h3>
              <Box
                sx={{
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validate={validate}
                >
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      sx={{
                        marginTop: 2,
                        maxHeight: "320px",
                        width: "100%",
                        gap: 3,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <TextField
                        label={t("NAME")}
                        value={values.name}
                        inputProps={{ style: { fontSize: "16px" } }}
                        name="name"
                        size="small"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        error={!!touched?.name && !!errors?.name}
                        helperText={touched?.name && errors?.name}
                      />
                      <CustomDatePicker
                        onChange={(value: any) =>
                          setFieldValue(
                            "date",
                            value ? value.format("YYYY-MM-DD") : "",
                            true
                          )
                        }
                        value={values.date}
                        label={t("DATE")}
                        name="date"
                        views={["day"]}
                        openTo="day"
                      />
                      <CustomButton
                        type="submit"
                        title={t("CONFIRM_UPDATE")}
                        width="100%"
                        margin="4vh 0 0 0"
                        color={BLUE_COLOR_ACCENT}
                      />
                    </Box>
                  )}
                </Formik>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateHoliday;
