import { useContext } from "react";
import { IconButton, useTheme } from "@mui/material";
import { RootContext } from "../context/RootContext";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";

const LightDarkModeToggle = () => {
  const theme = useTheme();
  const { colorMode } = useContext(RootContext);

  return (
    <>
      <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === "dark" ? (
          <LightModeOutlined sx={{ color: "grey" }} />
        ) : (
          <DarkModeOutlined sx={{ color: "grey" }} />
        )}
      </IconButton>
    </>
  );
};

export default LightDarkModeToggle;
