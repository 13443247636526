import { createSelector, createSlice } from "@reduxjs/toolkit";
import { appEndpoints } from "../endpoints/app.endpoints";
import { RootState } from "./store";

const initialState: {
  adminDashboardDatas: [];
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
} = {
  adminDashboardDatas: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
};

const selectSelf = (state: RootState) => state.adminDashboard;

export const selectDashboardState = createSelector(
  selectSelf,
  (state) => state.adminDashboardDatas
);

export const admindashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    resetDashboardState: () => initialState,
    getAdminDashboardData: (state, { payload }) => {
      state.adminDashboardDatas = payload;
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      appEndpoints.endpoints.getAdminDashboard.matchFulfilled,
      (state, { payload }) => {
        state.isError = false;
        state.isLoading = false;
        state.adminDashboardDatas = payload;
        state.isSuccess = true;
      }
    ),
});

export const { resetDashboardState, getAdminDashboardData } =
  admindashboardSlice.actions;
