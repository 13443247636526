import { memo, useContext, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { Holiday } from "../../data/models/holiday.interface";
import moment from "moment";
import AlertDialog from "../../shared/modal/AlertDialog";
import { Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../data/store/authSlice";
import { RootContext } from "../../context/RootContext";
import { useDeleteHolidayMutation } from "../../data/endpoints/app.endpoints";
import UpdateHoliday from "./UpdateHoliday";
import { t } from "i18next";

interface HolidayTableRowProps {
  row: Holiday;
}

const HolidayTableRow = ({ row }: HolidayTableRowProps) => {
  const [openDeleteModal, setDeleteModal] = useState(false);
  const user = useSelector(selectLoggedUser);

  const [deleteHoliday] = useDeleteHolidayMutation();
  const {
    showSnackbar,
    setOpenModal,
    state: { openModal },
  } = useContext(RootContext);

  const handleHolidayDelete = () => {
    deleteHoliday(row?.holidayId).then((res: any) => {
      if (!res.error) {
        setDeleteModal(false);
        showSnackbar(t("HOLIDAYS.DELETE_HOLIDAY"), false, true);
      } else if (res.error) {
        showSnackbar(res.error.data.error.message, true, true);
      }
    });
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setDeleteModal(true);
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        alignItems: "center",
      }}
    >
      <TableCell component="th" scope="row">
        {row?.name}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {moment(row?.date).format("MMM-DD-YYYY")}
        {user?.role === "Admin" && (
          <div style={{ display: "flex", gap: "15px" }}>
            <UpdateHoliday
              holidayId={row.holidayId}
              name={row.name}
              date={row.date}
            />
            <AlertDialog
              openModal={openDeleteModal}
              setOpenModal={setDeleteModal}
              buttonTitle=""
              handleSubmitDialog={handleHolidayDelete}
              ButtonComponent={(props: any) => (
                <Delete onClick={handleModalOpen} sx={{ cursor: "pointer" }} />
              )}
              mt="20px"
            >
              {t("HOLIDAYS.MODAL_CONFIRM_SUBTITLE")}
            </AlertDialog>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(HolidayTableRow);
