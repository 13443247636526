import { Box } from "@mui/material";
import CustomButton from "../../shared/custom/CustomButton";
import { Error } from "@mui/icons-material/";

export default function ErrorPage(props: any) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Error fontSize="large" color="action" sx={{ mb: "10px" }} />
      <Box
        style={{
          marginBottom: "20px",
          fontSize: "23px",
          fontFamily: "sans-serif",
          color: "gray",
        }}
      >
        Oops! Something went wrong.
      </Box>
      {props.resetErrorBoundary && (
        <Box>
          <CustomButton
            width="100%"
            onClick={props.resetErrorBoundary}
            title="Go To Home"
          />
        </Box>
      )}
    </Box>
  );
}
