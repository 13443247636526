import { Box } from "@mui/material";

interface ICustomFMActionButton{
    icon: any,
    title?:string,
    onAction?: ()=>void,
}
const CustomFMActionButton = ({icon, title, onAction } : ICustomFMActionButton) => {
  return (
    <Box
      sx={{
        width: "auto",
        height: "30px",
        border: "0.5px solid lightgray",
        borderRadius: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'pointer',
        gap: 1,
        padding:'5px'
      }}
      onClick={onAction}
    >
        {icon}
        {title}
    </Box>
  );
};

export default CustomFMActionButton;
